import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../report/reportReducer';
import { RootState } from '../reducers';
import * as reportAction from '../report/reportAction';

import { generateReport, generateDownloadReport, requestReportData, requestDeleteReport, executeReport, generateCashierReport, generateActivityReport } from '../../services/report';
// import { Report } from '../../shared/@types/report';

const useReportState = (): UseState =>
  useSelector((rootState: RootState) => rootState.report);

export const useReports = () => {
  const { report } = useReportState();
  const data = { report }
  return data;
};

export const useGetReports = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string) => {
    const data = await requestReportData(access_token);
    dispatch(reportAction.getAllReport(data));
  }, [dispatch]);
};

export const useGenerateReport = () => {
  return useCallback(
    async (access_token: string, idProject: string, reportType: string, initialDate: string, finalDate: string) => {
      const createdReport = await generateReport(access_token, idProject, reportType, initialDate, finalDate);
      return createdReport;
    },
    [],
  );
};

export const useGenerateReportDownload = () => {
  return useCallback(
    async (access_token: string, idReport: string) => {
      const createdReport = await generateDownloadReport(access_token, idReport);
      return createdReport;
    },
    [],
  );
};


export const useGenerateCashierReport = () => {
  return useCallback(
    async (access_token: string, idCashier: string, initialDate: string, finalDate: string) => {
      const createdReport = await generateCashierReport(access_token, idCashier, initialDate, finalDate );
      return createdReport;
    },
    [],
  );
};

export const useGenerateActivityReport = () => {
  return useCallback(
    async (access_token: string, idActivity: string) => {
      const createdReport = await generateActivityReport(access_token, idActivity);
      return createdReport;
    },
    [],
  );
};

export const useExecuteProjectReport = () => {
  return useCallback(
    async (access_token: string, idReport: string) => {
      const createdReport = await executeReport(access_token, idReport);
      return createdReport;
    },
    [],
  );
};

export const useDeleteReport = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteReport(access_token, id);
    return data;
  }, [])
};


