import { File, getFileData } from '../../shared/@types/file';
import { requestService } from '../../utils/requestService';

export const requestFilesData = async (
  access_token: string, id: string, origin: string
): Promise<getFileData> => {
  const getLowerCaseString = origin.toLowerCase();

  const url = `${process.env.REACT_APP_ADPASE_BACK_URL}/${getLowerCaseString}/file?id${origin}=${id}&per_page=500`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postFile = async (access_token: string, file: File, origin: string): Promise<getFileData> => {
  const getLowerCaseString = origin.toLowerCase();
    const url = `${process.env.REACT_APP_ADPASE_BACK_URL}/${getLowerCaseString}/file`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, file, headers, 'POST', false);
    return response;
};

export const putFile = async (access_token: string, file: File, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_BACK_URL}/file/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, file, headers, 'PUT', false);
    return response;
};

export const requestFileData = async (
  access_token: string, 
  id: string,
): Promise<File> => {
  const url = `${process.env.REACT_APP_ADPASE_BACK_URL}/${origin}/file?id${origin}=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteFile = async (
  access_token: string, 
  id: string,
  origin: string,
): Promise<any> => {
  const getLowerCaseString = origin.toLowerCase();
  let url = `${process.env.REACT_APP_ADPASE_BACK_URL}/${getLowerCaseString}/file/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}