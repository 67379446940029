import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";
import { Participant } from "../../../shared/@types/participant";

interface Props {
  participants: Participant[] | undefined;
  handleCurrentItem: (id: string | undefined) => void;
  updateAllowed: boolean;
  deleteAllowed: boolean;
}

const ParticipantList = ({
  participants = [],
  handleCurrentItem,
  updateAllowed,
  deleteAllowed,
}: Props) => {
  if (participants.length === 0)
    return <h4>Não há participantes cadastradas.</h4>;

  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>Nome</div>
          <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
        </li>

        {participants.map((item) => (
          <li
            className={styles.tableRow}
            key={item.idPerson}
            data-testid="listitem"
          >
            <div className={`${styles.col} ${styles.col1}`} data-label="Id">
              {item.idPerson}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
            <a
                  href={`/participante/${item.idPerson}`}
                >{item.name}</a>
            </div>
            <div className={`${styles.col} ${styles.col4}`} data-label="Ações">
              {updateAllowed && (
                <a
                  href={`/participante/${item.idPerson}`}
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                </a>
              )}
              {deleteAllowed && (
                <button
                  className="btn btn-danger"
                  onClick={() => handleCurrentItem(item.idPerson)}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ParticipantList;
