import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUser } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

interface Props {
  activityParticipants: any;
  handleModalDelete: (id: string) => void;
  deleteCheckinAllowed: boolean;
}

const Index = ({
  activityParticipants = [],
  handleModalDelete,
  deleteCheckinAllowed,
}: Props) => {
  return (
    <div className={styles.dams} style={{ marginTop: '20px' }}>
      <div className={styles.damsHeader}>
        <div>
          <h4>Participantes da atividade</h4>
        </div>
      </div>
      <div className="participants--list">
        <div className={styles.container}>
          <ul className={styles.responsiveTable}>
            <li className={styles.tableHeader}>
              <div className={`${styles.col} ${styles.col1}`}>Participante</div>
              <div className={`${styles.col} ${styles.col1}`}>Idade</div>
              <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
            </li>

            {activityParticipants && activityParticipants.length === 0 && (
              <h5>Sem participantes cadastrados</h5>
            )}

            {activityParticipants.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <li className={styles.tableRow} key={item.idDam}>
                    <div
                      className={`${styles.col} ${styles.col1}`}
                      data-label="Participante"
                    >
                      {item.name}
                    </div>
                    <div
                      className={`${styles.col} ${styles.col2}`}
                      data-label="Idade"
                    >
                      {item.age}
                    </div>
                    <div
                      className={`${styles.col} ${styles.col4}`}
                      data-label="Ações"
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`/participante/${item.idPerson}`}
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon={faUser} size="1x" />
                      </a>
                      {deleteCheckinAllowed && (
                        <button
                          className="btn btn-danger"
                          onClick={() => handleModalDelete(item.idCheckin)}
                        >
                          <FontAwesomeIcon icon={faTrash} size="1x" />
                        </button>
                      )}
                    </div>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;
