import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from '../reducers';
import * as loadingAction from '../loading/loadingAction';

const useLoadingState = () =>
  useSelector((rootState: RootState) => rootState.loadingState);

export const useShowLoading = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(loadingAction.showLoading());
  }, [dispatch]);
};

export const useHideLoading = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(loadingAction.hideLoading());
  }, [dispatch]);
};

export const useLoading = () => {
  const { isLoading } = useLoadingState();
  return isLoading;
};
