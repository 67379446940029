import { Contract, getContractData } from '../../shared/@types/contract';
import { requestService } from '../../utils/requestService';

export const requestContractsData = async (
  access_token: string, page: number, offset: number
): Promise<getContractData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/contract?page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postContract = async (access_token: string, contract: Contract): Promise<getContractData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/contract`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, contract, headers, 'POST', false);
    return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/contract?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putContract = async (access_token: string, contract: Contract, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/contract/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, contract, headers, 'PUT', false);
    return response;
};

export const requestContractData = async (
  access_token: string, 
  id: string,
): Promise<Contract> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/contract?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteContract = async (
  access_token: string, 
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/contract/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

