import { getActivityData, Activity } from '../../shared/@types/activity';

export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ALL_ACTIVITIES = 'GET_ALL_ACTIVITIES';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_ACTIVITIES_SEARCH = 'GET_ACTIVITIES_SEARCH';

export const getActivity = (activity: Activity) => ({
  type: GET_ACTIVITY,
  payload: {
    activity,
  },
});

export interface GetActivity {
  type: typeof GET_ACTIVITY;
  payload: { activity: Activity };
}

export const getAllActivities = (activities: getActivityData) => ({
  type: GET_ALL_ACTIVITIES,
  payload: {
    data: activities.data,
    pagination: activities.pagination,
  },
});

export interface GetAllActivities {
  type: typeof GET_ALL_ACTIVITIES;
  payload: {
    data: getActivityData;
    pagination: Partial<getActivityData>
  };
}

export const getActivitiesSearch = (activities: getActivityData) => ({
  type: GET_ACTIVITIES_SEARCH,
  payload: {
    data: activities.data,
    pagination: activities.pagination,
  },
});

export interface GetActivitiesSearch {
  type: typeof GET_ACTIVITIES_SEARCH;
  payload: {
    data: getActivityData;
    pagination: Partial<getActivityData>
  };
}



export const updateActivity = (activity: Activity) => ({
  type: UPDATE_ACTIVITY,
  payload: {
    activity,
  },
});

export interface UpdateActivity {
  type: typeof UPDATE_ACTIVITY;
  payload: {
    activity: Activity;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
