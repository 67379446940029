import { Rubric, getRubricData } from '../../shared/@types/rubric';
import { PaginationProps } from '../../shared/@types/rubric';
import * as rubricAction from './rubricAction';

export interface State {
  rubrics?: getRubricData;
  rubric?: Rubric;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  rubric_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  rubrics: undefined,
  queryParam: undefined,
  rubric: undefined,
  loading: true,
};

export type Actions =
  | rubricAction.GetAllRubrics
  | rubricAction.GetRubricsSearch
  | rubricAction.GetRubric
  | rubricAction.SetQueryParam
  | rubricAction.UpdateRubric;

export const rubricReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case rubricAction.GET_ALL_RUBRICS: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        rubrics: data,
        rubric_pagination: pagination,
      };
    }
    case rubricAction.GET_RUBRICS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        rubrics: data,
        rubric_pagination: pagination,
      };
    }
    case rubricAction.GET_RUBRIC: {
      const { rubric } = action.payload;
      return {
        ...state,
        rubric,
        loading: false,
      };
    }
    case rubricAction.UPDATE_RUBRIC: {
      const { rubric } = action.payload;
      return {
        ...state,
        rubric,
      };
    }
    case rubricAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
