import {  getStateData, PaginationProps } from '../../shared/@types/state';
import * as stateAction from './stateAction';

export interface State {
  states?: getStateData;
  loading: boolean;
  errorMessage?: string;
  state_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  states: undefined,
  loading: true,
};

export type Actions =
  | stateAction.GetAllStates

export const stateReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case stateAction.GET_ALL_STATES: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        states: data,
        state_pagination: pagination,
      };
    }
    default:
      return state;
  }
};
