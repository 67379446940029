import { User, getUserData, UserLogin, UserUpdatePassword } from '../../shared/@types/user';
import { requestService } from '../../utils/requestService';

export const requestUsersData = async (
  access_token: string, page: number, offset = 0,
): Promise<getUserData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/users?page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const requestPermissions = async (
  access_token: string, page: number, offset = 0,
): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/users/permissions?page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};


export const requestUserData = async (
  access_token: string,
  id: string,
  offset = 0,
): Promise<getUserData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/users?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};


export const postUser = async (access_token: string, user: User): Promise<User> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/users`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, user, headers, 'POST', false);
  return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<getUserData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/users?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putUser = async (access_token: string, user: User, id: string): Promise<User> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/users/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, user, headers, 'PUT', false);
  return response;
};

export const putPassword = async (access_token: string, user: UserUpdatePassword): Promise<User> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/auth/update-password`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, user, headers, 'PUT', false);
  return response;
}

export const resetPassword = async (access_token: string, id: string): Promise<User> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/auth/reset-password/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'PUT', false);
  return response;
}

export const loginUser = async (user: Partial<User>): Promise<UserLogin> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/auth/login`;
  const headers = { 'content-type': 'application/json' };
  const response = await requestService(url, user, headers, 'POST', false);
  return response;
};

export const requestDeleteUser = async (
  access_token: string, id: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/users/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}
