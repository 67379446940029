import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

interface DamProps {
  idDam: string;
  name: string;
}

interface Props {
  dams: DamProps[] | undefined;
  handleCurrentItem: (id: string) => void;
  updateAllowed: boolean;
  deleteAllowed: boolean;
}

const DamList = ({
  dams = [],
  handleCurrentItem,
  updateAllowed,
  deleteAllowed,
}: Props) => {
  if (dams.length === 0) return <h4>Não há barragens cadastradas.</h4>;

  return (
    <div className={styles.container} data-testid="dam-list">
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>Nome</div>
          <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
        </li>

        {dams.map((item) => (
          <li
            className={styles.tableRow}
            key={item.idDam}
            data-testid="listitem"
          >
            <div className={`${styles.col} ${styles.col1}`} data-label="Id">
              {item.idDam}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
              <a href={`/barragem/${item.idDam}`}>{item.name}</a>
            </div>
            <div className={`${styles.col} ${styles.col4}`} data-label="Ações">
              {updateAllowed && (
                <a href={`/barragem/${item.idDam}`} className="btn btn-primary">
                  <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                </a>
              )}
              {deleteAllowed && (
                <button
                  className="btn btn-danger"
                  onClick={() => handleCurrentItem(item.idDam)}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DamList;
