import { getCityData } from '../../shared/@types/city';
import { requestService } from '../../utils/requestService';

export const requestCitiesData = async (
  access_token: string, offset: number, page: number, idState: string
): Promise<getCityData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/locations/city?per_page=500&idState=${idState}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};
