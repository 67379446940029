import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";

import Admin from "../../shared/components/Admin";
import { SubRubric } from "../../shared/@types/subrubric";
import { useRubrics, useGetRubrics } from "../../store/hooks/rubricHooks";
import {
  usePostSubRubric,
  usePutSubRubric,
  useGetSubRubric,
} from "../../store/hooks/subRubricHooks";
import styles from "./styles.module.scss";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import { REQUIRED } from "../../utils/formUtils";

const subrubricSchema = yup.object().shape({
  idLedger: yup.string().required(REQUIRED),
  name: yup.string().required(REQUIRED),
});

interface ParamTypes {
  id: string;
}

const SubRubricManagement = () => {
  const { access_token } = useSelector((state: stateProps) => state.auth);
  const { id } = useParams<ParamTypes>();
  const getCurrentItem = useGetCurrentItem();
  const postSubRubric = usePostSubRubric();
  const putSubRubric = usePutSubRubric();
  const getSubRubric = useGetSubRubric();
  const getRubrics = useGetRubrics();
  const rubricsData = useRubrics();

  const [redirect, setRedirect] = useState(false);

  const handleSubmit = (values: SubRubric) => {
    let result: any;

    if (id) {
      result = putSubRubric(access_token, values, id);
    } else {
      result = postSubRubric(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando subrubrica...",
      success: () => {
        setRedirect(true);
        return "SubRubrica cadastrada!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      idLedger: "",
    },
    validationSchema: subrubricSchema,
    onSubmit: (values: SubRubric) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getRubrics(access_token, 1, true);

    getCurrentItem("subrubrics");
    const getInfo = async () => {
      if (id) {
        const result: any = await getSubRubric(access_token, id);
        if (result) {
          setFieldValue("name", result.name);
          setFieldValue("idLedger", result.idLedger);
        }
      }
    };

    getInfo();
  }, [
    id,
    getSubRubric,
    setFieldValue,
    access_token,
    getCurrentItem,
    getRubrics,
  ]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/subrubricas`,
        }}
      />
    );
  }

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar SubRubrica" : "Cadastrar SubRubrica"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={6}>
              <Form.Label htmlFor="rubric">Rubrica</Form.Label>
              <Select
                placeholder="Selecione uma opção"
                noOptionsMessage={() => "Não encontrado"}
                name="idLedger"
                onChange={(option: any) =>
                  setFieldValue("idLedger", option.value ? option.value : "")
                }
                value={
                  rubricsData &&
                  rubricsData.rubrics &&
                  rubricsData.rubrics
                    .map((rubric: any) => ({
                      label: rubric.name,
                      value: rubric.idLedger,
                    }))
                    .find((option: any) => option.value === values.idLedger)
                }
                options={
                  rubricsData &&
                  rubricsData.rubrics &&
                  rubricsData.rubrics.map((rubric: any) => ({
                    label: rubric.name,
                    value: rubric.idLedger,
                  }))
                }
              />
              {touched.idLedger && errors.idLedger && (
                <Form.Text className={styles.textError}>
                  {errors.idLedger}
                </Form.Text>
              )}
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <Form.Text className={styles.textError}>
                    {errors.name}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={12} className="button-save">
              <Form.Group>
                <Button type="submit">SALVAR</Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    </Admin>
  );
};

export default SubRubricManagement;
