import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../contract/contractReducer';
import { RootState } from '../reducers';
import * as contractAction from '../contract/contractAction';
import { postContract, postSearch, putContract, requestContractsData, requestContractData, requestDeleteContract } from '../../services/contracts';
import { Contract } from '../../shared/@types/contract';

const useContractState = (): UseState =>
  useSelector((rootState: RootState) => rootState.contracts);

export const useContracts = () => {
  const { contracts, contract_pagination } = useContractState();
  const data = { contracts, contract_pagination }
  return data;
};

export const useGetContracts = () => {
  const dispatch = useDispatch();

  return useCallback(async (access_token: string, page: number) => {
    const data = await requestContractsData(access_token, page, 0);
    dispatch(contractAction.getAllContracts(data));
  }, [dispatch]);
};

export const usePostContract = () => {
  return useCallback(
    async (access_token: string, formContractBody: Contract) => {
      const createdContract = await postContract(access_token, formContractBody);
      return createdContract;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(contractAction.getContractsSearch(searchResult));
    },
    [dispatch],
  );
};

export const useGetContract = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestContractData(access_token, id);
    return data;
  }, []);
};

export const usePutContract = () => {
  return useCallback(
    async (access_token: string, formContractBody: Contract, id: string) => {
      const createdContract = await putContract(access_token, formContractBody, id);
      return createdContract;
    },
    [],
  );
};

export const useDeleteContract = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteContract(access_token, id);
    return data;
  }, [])
};