import { useEffect, useState } from "react";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";

import Admin from "../../shared/components/Admin";
import { Project } from "../../shared/@types/project";
import {
  usePostProject,
  usePutProject,
  useGetProject,
} from "../../store/hooks/projectHooks";
import styles from "../styles.module.scss";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import { useSelector } from "react-redux";
import { stateProps } from "../../shared/@types/general";
import { REQUIRED } from "../../utils/formUtils";
import {
  useGetReleasesByProject,
  // useReleases,
} from "../../store/hooks/releaseHooks";
import { usePermission } from "../../store/hooks/permissionHooks";

const projectSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
  description: yup.string(),
  initialDate: yup.string().required(REQUIRED),
  finalDate: yup.string(),
  sponsor: yup.string().required(REQUIRED),
});

interface ParamTypes {
  id: string;
}

const ProjectManagement = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const { id } = useParams<ParamTypes>();
  const postProject = usePostProject();
  const putProject = usePutProject();
  const getProject = useGetProject();
  const getReleasesByidProject = useGetReleasesByProject();

  const [redirect, setRedirect] = useState(false);

  const [saveAllowed, setSaveAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "project_index_post",
      "project_index_put",
      "project_index_get_one",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (id && access.includes("project_index_get_one")) setSaveAllowed(true);
      if (!id && access.includes("project_index_post")) setSaveAllowed(true);
      if (
        id &&
        access.includes("project_index_get_one") &&
        access.includes("project_index_put")
      )
        setSaveAllowed(true);
      if (
        id &&
        access.includes("project_index_get_one") &&
        !access.includes("project_index_put")
      )
        setSaveAllowed(false);

      if (!access.includes("project_index_get_one") && id) {
        toast.error("Você não tem acesso para visualizar/editar um projeto.");
        setRedirect(true);
      }
      if (!access.includes("project_index_post") && !id) {
        toast.error("Você não tem acesso para cadastrar um projeto.");
        setRedirect(true);
      }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (values: Project) => {
    let result: any;

    if (id) {
      result = putProject(access_token, values, id);
    } else {
      result = postProject(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando projeto...",
      success: () => {
        setRedirect(true);
        return "Projeto cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      initialDate: "",
      finalDate: "",
      sponsor: "",
    },
    validationSchema: projectSchema,
    onSubmit: (values: Project) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getReleasesByidProject(access_token, id);
    getCurrentItem("projects");
    const getInfo = async () => {
      if (id) {
        const result: any = await getProject(access_token, id);
        if (result) {
          setFieldValue("name", result.name);
          setFieldValue("description", result.description);
          setFieldValue("initialDate", result.initialDate);
          setFieldValue("finalDate", result.finalDate);
          setFieldValue("sponsor", result.sponsor);
        }
      }
    };

    getInfo();
  }, [
    id,
    getProject,
    setFieldValue,
    getCurrentItem,
    access_token,
    getReleasesByidProject,
  ]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/projetos`,
        }}
      />
    );
  }

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar Projeto" : "Cadastrar Projeto"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <Form.Text className={styles.textError}>
                    {errors.name}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  id="description"
                  name="description"
                  as="textarea"
                  rows={3}
                  onChange={handleChange}
                  value={values.description}
                />
                {touched.description && errors.description && (
                  <Form.Text className={styles.textError}>
                    {errors.description}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data inicial</Form.Label>
                <Form.Control
                  id="initialDate"
                  name="initialDate"
                  type="date"
                  onChange={handleChange}
                  value={values.initialDate}
                />
                {touched.initialDate && errors.initialDate && (
                  <Form.Text className={styles.textError}>
                    {errors.initialDate}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data final</Form.Label>
                <Form.Control
                  id="finalDate"
                  name="finalDate"
                  type="date"
                  onChange={handleChange}
                  value={values.finalDate}
                />

                {touched.finalDate && errors.finalDate && (
                  <Form.Text className={styles.textError}>
                    {errors.finalDate}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Col}>
                <Form.Label>Financiador</Form.Label>
                <Form.Control
                  id="sponsor"
                  name="sponsor"
                  type="text"
                  onChange={handleChange}
                  value={values.sponsor}
                />
                {touched.sponsor && errors.sponsor && (
                  <Form.Text className={styles.textError}>
                    {errors.sponsor}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            {saveAllowed && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit">SALVAR</Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
      <hr style={{ marginTop: 30, marginBottom: 50 }} />
    </Admin>
  );
};

export default ProjectManagement;
