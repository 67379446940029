import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Container, Spinner, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import Pagination from "react-js-pagination";

import Admin from "../../shared/components/Admin";
import {
  useHideLoading,
  useLoading,
  useShowLoading,
} from "../../store/hooks/loadingHooks";
import {
  useGetActivities,
  useActivities,
  useDeleteActivity,
  useLockActivity,
  usePostSearch,
} from "../../store/hooks/activityHooks";
import ActivityList from "./List/ActivityList";
import ModalDelete from "../../shared/components/ModalDelete";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import Search from "../../shared/components/Search";

import styles from "../styles.module.scss";
import ModalLock from "../../shared/components/ModalLock";
import { Activity } from "../../shared/@types/activity";
import { usePermission } from "../../store/hooks/permissionHooks";

const Index = () => {
  const { access_token, user_data } = useSelector((state: stateProps) => state.auth);
  const getCurrentItem = useGetCurrentItem();
  const getActivities = useGetActivities();
  const activitiesData = useActivities();
  const deleteActivity = useDeleteActivity();
  const lockActivity = useLockActivity();
  const postSearch = usePostSearch();
  const isLoading = useLoading();
  const showLoading = useShowLoading();
  const hideLoading = useHideLoading();

  const [redirect, setRedirect] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalLock, setModalLock] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const [activePage, setActivePage] = useState(1);

  const [redirectHome, setRedirectHome] = useState(false);
  const [updateAllowed, setUpdateAllowed] = useState(false);
  const [deleteAllowed, setDeleteAllowed] = useState(false);

  useEffect(() => {
    console.log("user_data.scopes", user_data.scopes)
    if (!user_data.scopes) return setRedirect(true)
    const scopes = ['activity_index_get', 'activity_index_put', 'activity_index_delete']
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (!access.includes('activity_index_get')) {
        toast.error("Você não tem acesso a lista de atividades.");
        setRedirectHome(true)
      }
      if (access.includes('activity_index_put')) setUpdateAllowed(true)
      if (access.includes('activity_index_delete'))  setDeleteAllowed(true) 
    }

    VerifyAccess();
    //eslint-disable-next-line
  },[])

  const handleSubmit = (values: any) => {
    // if (values.search === '') return;
    const result = postSearch(access_token, values.search, 1);

    toast.promise(result, {
      loading: "Buscando atividade...",
      success: (result: any) => {
        return "Atividades encontradas!";
      },
      error: (error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setRedirect(true);
          return error.response.data.error;
        }
        return error.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values } = formik;

  useEffect(() => {
    getCurrentItem("activities");
    const fetchActivities = async () => {
      try {
        let result: any = getActivities(access_token, 1);
        toast.promise(result, {
          loading: "Carregando Atividades...",
          success: () => {
            return "Lista de atividades carregada!";
          },
          error: (error) => {
            if (
              error &&
              error.response &&
              error.response.status &&
              (error.response.status === 401 || error.response.status === 403)
            ) {
              setRedirect(true);
              return error.response.data.error;
            }
            return error.response.data.error;
          },
        });
      } catch (error: any) {
        if (
          error &&
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setRedirect(true);
          return error.response.data.error;
        }
      } finally {
        hideLoading();
      }
    };

    fetchActivities();
  }, [getActivities, access_token, showLoading, hideLoading, getCurrentItem]);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleCurrentItem = (id: string) => {
    setModal(!modal);
    setCurrentId(id);
  };

  const handleModalLock = () => {
    setModalLock(!modalLock);
  };

  const handleCurrentItemLock = (id: string) => {
    setModalLock(!modalLock);
    setCurrentId(id);
  }

  const handleDelete = () => {
    const result = deleteActivity(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo atividade...",
      success: () => {
        getActivities(access_token, 1);
        setModal(!modal);
        return "Atividade excluída.";
      },
      error: (error) => error.message,
    });
  };

  const handleActivityLock = () => {
    if (!activitiesData.activities) return;


    const getActivityById = activitiesData.activities.length > 0 ? activitiesData.activities.filter((item: any) => item.idActivity === currentId) : null;
   
    if (getActivityById.length > 0) {

      const {
        finalDate,
        idActivity,
        idProject,
        initialDate,
        lock,
        model,
        record,
        subject,
        totalFemale,
        totalMale,
        totalOthers,
        typeActivity,
        typeActivityOther,
      } = getActivityById[0];

      const objectLock: Activity = {
        finalDate,
        idActivity,
        idProject,
        initialDate,
        lock,
        model,
        record,
        subject,
        totalFemale,
        totalMale,
        totalOthers,
        typeActivity,
        typeActivityOther,
      };

      objectLock.lock = true;

      const result = lockActivity(access_token, objectLock, currentId);

      toast.promise(result, {
        loading: "Bloqueando atividade...",
        success: () => {
          setCurrentId("");
          getActivities(access_token, 1);
          setModalLock(!modalLock);
          return "Atividade bloqueada.";
        },
        error: (err) => {
          if (
            err &&
            err.response &&
            err.response.status &&
            (err.response.status === 401 || err.response.status === 403 || err.response.status === 422)
          ) {
            setCurrentId("");
            getActivities(access_token, 1);
            setModalLock(!modalLock);
            return err.response.data.error;
          }
        },
      });
    }


    
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);

    if (values.search && values.search !== "") {
      postSearch(access_token, values.search, pageNumber);
    } else {
      getActivities(access_token, pageNumber);
    }
  };

  if (redirectHome) {
    return (
      <Redirect
        to={{
          pathname: `/home`,
        }}
      />
    );
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
        }}
      />
    );
  }

  return (
    <Admin>
      {isLoading && (
        <Container>
          <Spinner animation="border" variant="primary" />
        </Container>
      )}
      {modal && (
        <ModalDelete
          handleModal={handleModal}
          handleDelete={handleDelete}
          modal={modal}
        />
      )}
      {modalLock && (
        <ModalLock
          handleModalLock={handleModalLock}
          handleActivityLock={handleActivityLock}
          modalLock={modalLock}
        />
      )}
      <div className={styles.headerInfo}>
        <h2>Atividades</h2>
        <a
          href={`/adicionar-atividade`}
          className="btn btn-primary default-button"
        >
          Adicionar
        </a>
      </div>
      <hr className={styles.hr} />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Search
            handleChange={handleChange}
            values={values}
            placeholder="Digite o nome da atividade..."
          />
        </Form>
      </FormikProvider>
      <ActivityList
        activities={activitiesData.activities}
        handleCurrentItem={handleCurrentItem}
        handleCurrentItemLock={handleCurrentItemLock}
        updateAllowed={updateAllowed}
        deleteAllowed={deleteAllowed}
      />
      <div className="pagination">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            activitiesData &&
            activitiesData.activity_pagination &&
            activitiesData.activity_pagination.total_content
              ? activitiesData.activity_pagination.total_content
              : 0
          }
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </Admin>
  );
};

export default Index;
