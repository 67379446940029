import { combineReducers } from 'redux';
import { loadingReducer } from './loading/loadingReducer';
import { userReducer } from './user/userReducer';
import { projectReducer } from './project/projectReducer';
import { rubricReducer } from './rubric/rubricReducer';
import { subrubricReducer } from './subrubric/subrubricReducer';
import { cashierReducer } from './cashier/cashierReducer';
import { activityReducer } from './activity/activityReducer';
import { providerReducer } from './provider/providerReducer';
import { sidebarReducer } from './sidebar/sidebarReducer';
import { contractReducer } from './contract/contractReducer';
import { releaseReducer } from './release/releaseReducer';
import { releaseItemsReducer } from './releaseItems/releaseItemsReducer';
import { fileReducer } from './file/fileReducer';
import { reportReducer } from './report/reportReducer';
import { damReducer } from './dam/damReducer';
import { participantReducer } from './participant/participantReducer';
import { cityReducer } from './city/cityReducer';
import { stateReducer } from './state/stateReducer';

const reducers = combineReducers({
  loadingState: loadingReducer,
  auth: userReducer,
  projects: projectReducer,
  rubrics: rubricReducer,
  subrubrics: subrubricReducer,
  cashiers: cashierReducer,
  activities: activityReducer,
  providers: providerReducer,
  sidebarState: sidebarReducer,
  contracts: contractReducer,
  releases: releaseReducer,
  releaseItems: releaseItemsReducer,
  files: fileReducer,
  report: reportReducer,
  dams: damReducer,
  participants: participantReducer,
  cities: cityReducer,
  states: stateReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
