import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";
import { formatDate, formatter } from "../../../utils/formUtils";

interface ReleaseProps {
  idRelease: string;
  generalDescription: string;
  releaseDate: string;
  value: number;
}

interface Props {
  releases?: ReleaseProps[] | undefined;
  handleCurrentItem: (id: string) => void;
  updateAllowed: boolean;
  deleteAllowed: boolean;
}

const ReleaseList = ({
  releases = [],
  handleCurrentItem,
  updateAllowed,
  deleteAllowed,
}: Props) => {
  if (releases.length === 0) return <h4>Não há lançamentos cadastrados.</h4>;
  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>descrição</div>
          <div className={`${styles.col} ${styles.col1}`}>Data</div>
          <div className={`${styles.col} ${styles.col2}`}>Valor</div>
          <div className={`${styles.col} ${styles.col5}`}>AÇÕES</div>
        </li>

        {releases.map((item, index) => {
          let newValue = formatter.format(item.value);
          return (
          <li className={styles.tableRow} key={index}>
            <div className={`${styles.col} ${styles.col1}`} data-label="Id">
              {item.idRelease}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
              <a href={`/lancamento/${item.idRelease}`}>
                {" "}
                {item.generalDescription}
              </a>
            </div>
            <div className={`${styles.col} ${styles.col1}`} data-label="Data">
              {formatDate(item.releaseDate)}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Valor">
              {newValue}
            </div>
            <div className={`${styles.col} ${styles.col5}`} data-label="Ações">
              {updateAllowed && (
                <a
                  href={`/lancamento/${item.idRelease}`}
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                </a>
              )}
              {deleteAllowed && (
                <button
                  className="btn btn-danger"
                  onClick={() => handleCurrentItem(item.idRelease)}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>
              )}
            </div>
          </li>
        )})}
      </ul>
    </div>
  );
};

export default ReleaseList;
