import { Dam, getDamData } from '../../shared/@types/dam';
import { requestService } from '../../utils/requestService';

export const requestDamsData = async (
  access_token: string, offset: number, page: number, full_list: boolean
): Promise<getDamData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/dam?page=${page}`;

  if(full_list === true){
    url = url + '&per_page=500';
  }

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postDam = async (access_token: string, dam: Dam): Promise<getDamData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/dam`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, dam, headers, 'POST', false);
    return response;
};

export const postDamCityBound = async (access_token: string, values: string): Promise<getDamData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/dam-range`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, values, headers, 'POST', false);
  return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/dam?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putDam = async (access_token: string, dam: Dam, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/dam/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, dam, headers, 'PUT', false);
    return response;
};

export const requestDamData = async (
  access_token: string,
  id: string,
): Promise<Dam> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/dam?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDamRangesData = async (
  access_token: string,
  id: string,
): Promise<Dam> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/dam-range?idDam=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteDam = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/dam/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

export const requestDeleteDamRange = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/dam-range/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}
