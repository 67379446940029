import { Dam, getDamData, PaginationProps } from '../../shared/@types/dam';
import * as damAction from './damAction';

export interface State {
  dams?: getDamData;
  dam?: Dam;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  dam_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  dams: undefined,
  queryParam: undefined,
  dam: undefined,
  loading: true,
};

export type Actions =
  | damAction.GetAllDams
  | damAction.GetDamsSearch
  | damAction.GetDam
  | damAction.SetQueryParam
  | damAction.UpdateDam;

export const damReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case damAction.GET_ALL_DAMS: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        dams: data,
        dam_pagination: pagination,
      };
    }
    case damAction.GET_DAMS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        dams: data,
        dams_pagination: pagination,
      };
    }
    case damAction.GET_DAM: {
      const { dam } = action.payload;
      return {
        ...state,
        dam,
        loading: false,
      };
    }
    case damAction.UPDATE_DAM: {
      const { dam } = action.payload;
      return {
        ...state,
        dam,
      };
    }
    case damAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
