import { Project, getProjectData, PaginationProps } from '../../shared/@types/project';
import * as projectAction from './projectAction';

export interface State {
  projects?: getProjectData;
  project?: Project;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  project_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  projects: undefined,
  queryParam: undefined,
  project: undefined,
  loading: true,
};

export type Actions =
  | projectAction.GetAllProjects
  | projectAction.GetProjectsSearch
  | projectAction.GetProject
  | projectAction.SetQueryParam
  | projectAction.UpdateProject;

export const projectReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case projectAction.GET_ALL_PROJECT: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        projects: data,
        project_pagination: pagination
      };
    }
    case projectAction.GET_PROJECTS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        projects: data,
        project_pagination: pagination
      };
    }
    case projectAction.GET_PROJECT: {
      const { project } = action.payload;
      return {
        ...state,
        project,
        loading: false,
      };
    }
    case projectAction.UPDATE_PROJECT: {
      const { project } = action.payload;
      return {
        ...state,
        project,
      };
    }
    case projectAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
