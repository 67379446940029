import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../dam/damReducer';
import { RootState } from '../reducers';
import * as damAction from '../dam/damAction';
import { putDam, postDam, postDamCityBound, requestDamsData, requestDamData, requestDamRangesData, requestDeleteDamRange ,requestDeleteDam, postSearch } from '../../services/dams';
import { Dam } from '../../shared/@types/dam';

const useDamState = (): UseState =>
  useSelector((rootState: RootState) => rootState.dams);

export const useDams = () => {
  const { dams, dam_pagination } = useDamState();
  const data = { dams, dam_pagination }
  return data;
};

export const useGetDams = () => {
  const dispatch = useDispatch();
  
  return useCallback(async (access_token: string, page: number, full_list?: boolean) => {
    const data = await requestDamsData(access_token, 0, page, full_list);
    dispatch(damAction.getAllDams(data));
  }, [dispatch]);
};

export const useGetDamRanges = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDamRangesData(access_token, id);
    return data;
  }, []);
};

export const useGetDam = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDamData(access_token, id);
    return data;
  }, []);
};

export const useDeleteDam = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteDam(access_token, id);
    return data;
  }, [])
};

export const useDeleteDamRange = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteDamRange(access_token, id);
    return data;
  }, [])
};

export const usePostDam = () => {
  return useCallback(
    async (access_token: string, formDamBody: Dam) => {
      const createdDam = await postDam(access_token, formDamBody);
      return createdDam;
    },
    [],
  );
};

export const usePostDamCityBound = () => {
  return useCallback(
    async (access_token: string, values: string) => {
      const createdDam = await postDamCityBound(access_token, values);
      return createdDam;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(damAction.getDamsSearch(searchResult));
    },
    [dispatch],
  );
};


export const usePutDam = () => {
  return useCallback(
    async (access_token: string, formDamBody: Dam, id: string) => {
      const createdDam = await putDam(access_token, formDamBody, id);
      return createdDam;
    },
    [],
  );
};
