import {  getCityData, PaginationProps } from '../../shared/@types/city';
import * as cityAction from './cityAction';

export interface State {
  cities?: getCityData;
  loading: boolean;
  errorMessage?: string;
  city_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  cities: undefined,
  loading: true,
};

export type Actions =
  | cityAction.GetAllCities

export const cityReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case cityAction.GET_ALL_CITIES: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        cities: data,
        city_pagination: pagination,
      };
    }
    default:
      return state;
  }
};
