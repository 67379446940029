import { getReportData } from '../../shared/@types/report';
import { requestService } from '../../utils/requestService';

export const requestReportData = async (
  access_token: string
): Promise<getReportData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/report?per_page=500`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const generateReport = async (access_token: string, idProject: string, reportType: string,  initialDate: string, finalDate: string): Promise<getReportData> => {
    let url = `${process.env.REACT_APP_ADPASE_URL}/report/${reportType}-pdf?idProject=${idProject}`;

    if (initialDate && initialDate !== "") url = url + `&initialDate=${initialDate}`;
    if (finalDate && finalDate !== "") url = url + `&finalDate=${finalDate}`;

    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, {}, headers, 'GET', false);
    return response;
};


export const generateDownloadReport = async (access_token: string, idReport: string): Promise<getReportData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/report/download/${idReport}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const generateCashierReport = async (access_token: string, idCashier: string, initialDate: string, finalDate: string): Promise<getReportData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/report/cashier-pdf?idCashier=${idCashier}`;

  if (initialDate && initialDate !== "") url = url + `&initialDate=${initialDate}`;
  if (finalDate && finalDate !== "") url = url + `&finalDate=${finalDate}`;

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const generateActivityReport = async (access_token: string, idActivity: string): Promise<getReportData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/report/activity-pdf?idActivity=${idActivity}`;

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const executeReport = async (access_token: string, idReport: string): Promise<getReportData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/report/executor/${idReport}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteReport = async (
  access_token: string, 
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/report/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}



