import { Cashier, getCashierData, PaginationProps } from '../../shared/@types/cashier';
import * as cashierAction from './cashierAction';

export interface State {
  cashiers?: getCashierData;
  cashier?: Cashier;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  cashier_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  cashiers: undefined,
  queryParam: undefined,
  cashier: undefined,
  loading: true,
};

export type Actions =
  | cashierAction.GetAllCashiers
  | cashierAction.GetCashiersSearch
  | cashierAction.GetCashier
  | cashierAction.SetQueryParam
  | cashierAction.UpdateCashier;

export const cashierReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case cashierAction.GET_ALL_CASHIERS: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        cashiers: data,
        cashier_pagination: pagination,
      };
    }
    case cashierAction.GET_CASHIERS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        cashiers: data,
        cashier_pagination: pagination,
      };
    }
    case cashierAction.GET_CASHIER: {
      const { cashier } = action.payload;
      return {
        ...state,
        cashier,
        loading: false,
      };
    }
    case cashierAction.UPDATE_CASHIER: {
      const { cashier } = action.payload;
      return {
        ...state,
        cashier,
      };
    }
    case cashierAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
