import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";

import {
    usePostDamCityBound
  } from "../../../store/hooks/damHooks";
import City from "../../../shared/components/City";
import { REQUIRED } from "../../../utils/formUtils";
import { stateProps } from "../../../shared/@types/general";
// import { Dam } from "../../../shared/@types/dam";

interface CityFormProps {
    idCity?: string;
    idDam?: string;
}

interface DamProps {
    idCurrentDam: string;
    getCurrentDamRanges: () => void;
}

const citySchema = yup.object().shape({
    idCity: yup.number().required(REQUIRED),
  });
  

const CityForm = ({idCurrentDam, getCurrentDamRanges} : DamProps) => {
    const { access_token } = useSelector((state: stateProps) => state.auth);
    const postDamCityBound = usePostDamCityBound();

    const handleSubmit = (values: CityFormProps) => {
        let  result = postDamCityBound(access_token, values);
    
        toast.promise(result, {
          loading: "Salvando cidade atingida por barragem...",
          success: () => {
            getCurrentDamRanges();
            return "Cidade cadastrada!";
          },
          error: (err) => {
            if (
              err &&
              err.response &&
              err.response.status &&
              (err.response.status === 401 || err.response.status === 403)
            ) {
              return err.response.data.error;
            }
          },
        });
      };

      const formik = useFormik({
        initialValues: {
          idCity: "",
          idDam: idCurrentDam,
        },
        validationSchema: citySchema,
        onSubmit: (values: CityFormProps) => {
          handleSubmit(values);
        },
      });
    
      const { 
        // handleChange, ,  errors, touched,
        setFieldValue } = formik;

    const getIdCity = (idCity: string) => {
        setFieldValue("idCity", idCity);
    }

    return  <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} >
        <Row>
            <Col md={12}>
                <h4>Adicionar cidade atingida pela barragem</h4>
            </Col>
        </Row>
        <Row>
            <City getIdCity={getIdCity} />
        </Row>
        <Row>
            <Col md={12} className="button-save">
              <Form.Group>
                <Button type="submit">SALVAR</Button>
              </Form.Group>
            </Col>
        </Row>
        </Form>
      </FormikProvider>
}

export default CityForm;