import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../activity/activityReducer';
import { RootState } from '../reducers';
import * as activityAction from '../activity/activityAction';
import { putActivity, postActivity, requestActivitiesData, requestActivityData, requestDeleteActivity, postSearch, lockActivity, requestDeleteActivityParticipant, requestActivityParticipantsData, postActivityParticipant } from '../../services/activities';
import { Activity, ActivityParticipant } from '../../shared/@types/activity';

const useActivityState = (): UseState =>
  useSelector((rootState: RootState) => rootState.activities);

export const useActivities = () => {
  const { activities, activity_pagination } = useActivityState();
  const data = { activities, activity_pagination }
  return data;
};

export const useGetActivities = () => {
  const dispatch = useDispatch();
  
  return useCallback(async (access_token: string, page: number, full_list?: boolean) => {
    const data = await requestActivitiesData(access_token, 0, page, full_list);
    dispatch(activityAction.getAllActivities(data));
  }, [dispatch]);
};

export const useGetActivity = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestActivityData(access_token, id);
    return data;
  }, []);
};

export const useGetActivityParticipants = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestActivityParticipantsData(access_token, id);
    return data;
  }, []);
};


export const useDeleteActivity = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteActivity(access_token, id);
    return data;
  }, [])
};

export const useDeleteActivityParticipant = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteActivityParticipant(access_token, id);
    return data;
  }, [])
};


export const usePostActivity = () => {
  return useCallback(
    async (access_token: string, formActivityBody: Activity) => {
      const createdActivity = await postActivity(access_token, formActivityBody);
      return createdActivity;
    },
    [],
  );
};

export const usePostActivityParticipant = () => {
  return useCallback(
    async (access_token: string, formActivityBody: ActivityParticipant) => {
      const createdActivity = await postActivityParticipant(access_token, formActivityBody);
      return createdActivity;
    },
    [],
  );
};


export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(activityAction.getActivitiesSearch(searchResult));
    },
    [dispatch],
  );
};



export const useLockActivity = () => {
  return useCallback(
    async (access_token: string, formActivityBody: Activity, id: string) => {
      const createdActivity = await lockActivity(access_token, formActivityBody, id);
      return createdActivity;
    },
    [],
  );
};


export const usePutActivity = () => {
  return useCallback(
    async (access_token: string, formActivityBody: Activity, id: string) => {
      const createdActivity = await putActivity(access_token, formActivityBody, id);
      return createdActivity;
    },
    [],
  );
};
