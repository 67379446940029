import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../file/fileReducer';
import { RootState } from '../reducers';
import * as fileAction from '../file/fileAction';
import { postFile, putFile, requestFilesData, requestFileData, requestDeleteFile } from '../../services/files';
import { File } from '../../shared/@types/file';

const useFileState = (): UseState =>
  useSelector((rootState: RootState) => rootState.files);

export const useFiles = () => {
  const { files } = useFileState();
  const data = { files }
  return data;
};

export const useGetFiles = () => {
  const dispatch = useDispatch();

  return useCallback(async (access_token: string, id: string, origin: string) => {
    const data = await requestFilesData(access_token, id, origin);
    dispatch(fileAction.getAllFiles(data));
  }, [dispatch]);
};

export const usePostFile = () => {
  return useCallback(
    async (access_token: string, formFileBody: File, origin: string) => {
      const createdFile = await postFile(access_token, formFileBody, origin);
      return createdFile;
    },
    [],
  );
};

export const useGetFile = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestFileData(access_token, id);
    return data;
  }, []);
};

export const usePutFile = () => {
  return useCallback(
    async (access_token: string, formFileBody: File, id: string) => {
      const createdFile = await putFile(access_token, formFileBody, id);
      return createdFile;
    },
    [],
  );
};

export const useDeleteFile = () => {
  return useCallback(async (access_token: string, id: string, origin: string) => {
    const data = await requestDeleteFile(access_token, id, origin);
    return data;
  }, [])
};