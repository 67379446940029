import { Release, getReleaseData, PaginationProps } from '../../shared/@types/release';
import * as releaseAction from './releaseAction';

export interface State {
  releases?: getReleaseData;
  release?: Release;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  release_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  releases: undefined,
  queryParam: undefined,
  project: undefined,
  loading: true,
};

export type Actions =
  | releaseAction.GetAllReleases
  | releaseAction.GetReleasesSearch
  | releaseAction.GetRelease
  | releaseAction.SetQueryParam
  | releaseAction.UpdateRelease;

export const releaseReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case releaseAction.GET_ALL_RELEASE: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        releases: data,
        release_pagination: pagination
      };
    }
    case releaseAction.GET_RELEASES_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        releases: data,
        releases_pagination: pagination
      };
    }
    case releaseAction.GET_RELEASE: {
      const { release } = action.payload;
      return {
        ...state,
        release,
        loading: false,
      };
    }
    case releaseAction.UPDATE_RELEASE: {
      const { release } = action.payload;
      return {
        ...state,
        release,
      };
    }
    case releaseAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
