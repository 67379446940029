import { getFileData, File } from '../../shared/@types/file';

export const GET_FILE = 'GET_FILE';
export const GET_ALL_FILE = 'GET_ALL_FILE';
export const UPDATE_FILE = 'UPDATE_FILE';

export const getFile = (file: File) => ({
  type: GET_FILE,
  payload: {
    file,
  },
});

export interface GetFile {
  type: typeof GET_FILE;
  payload: { file: File };
}

export const getAllFiles = (files: getFileData) => ({
  type: GET_ALL_FILE,
  payload: {
    data: files.data,
    pagination: files.pagination,
  },
});

export interface GetAllFiles {
  type: typeof GET_ALL_FILE;
  payload: {
    data: getFileData;
    pagination: Partial<getFileData>
  };
}

export const updateFile = (file: File) => ({
  type: UPDATE_FILE,
  payload: {
    file,
  },
});

export interface UpdateFile {
  type: typeof UPDATE_FILE;
  payload: {
    file: File;
  };
}
