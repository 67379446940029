import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPlus,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

import styles from "./styles.module.scss";
import { formatter } from "../../../utils/formUtils";

interface Props {
  releaseItems: any;
  handleModalDelete: (id: string) => void;
  handleCurrentItem: (id: string) => void;
  handleShowModal: () => void;
  saveReleaseItemAllowed: boolean;
  updateReleaseItemAllowed: boolean;
  getReleaseItemAllowed: boolean;
  getOneReleaseItemAllowed: boolean;
  deleteReleaseItemAllowed: boolean;
}

const Index = ({
  releaseItems = [],
  handleModalDelete,
  handleShowModal,
  handleCurrentItem,
  saveReleaseItemAllowed,
  updateReleaseItemAllowed,
  getReleaseItemAllowed,
  getOneReleaseItemAllowed,
  deleteReleaseItemAllowed,
}: Props) => {
  return (
    <div className={styles.releaseItems}>
      <div className={styles.releaseItemsHeader}>
        <div>
          <h4>Itens de lançamento</h4>
        </div>
        <div>
          {/* {saveReleaseItemAllowed &&  */}
            <Button onClick={handleShowModal}>
              <FontAwesomeIcon icon={faPlus} size="1x" />
            </Button>
          {/* // } */}
        </div>
      </div>
      <div className="releaseItems--list">
        <div className={styles.container}>
          <ul className={styles.responsiveTable}>
            <li className={styles.tableHeader}>
              <div className={`${styles.col} ${styles.col1}`}>Rúbrica - 1</div>
              <div className={`${styles.col} ${styles.col2}`}>Rúbrica - 2</div>
              <div className={`${styles.col} ${styles.col2}`}>Rúbrica - 3</div>
              <div className={`${styles.col} ${styles.col3}`}>Valor</div>
              <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
            </li>

            {releaseItems && releaseItems.length === 0 && (
              <h5>Sem itens de lançamento cadastrados</h5>
            )}

            {getReleaseItemAllowed &&
              releaseItems.map((item: any, index: number) => {
                let newValue = formatter.format(item.value);
                return (
                  <div key={index}>
                    <div style={{ paddingLeft: 30, paddingTop: 5 }}>
                      {item.description}
                    </div>
                    <li className={styles.tableRow} key={item.idItemsRelease}>
                      <div
                        className={`${styles.col} ${styles.col1}`}
                        data-label="Rubrica"
                      >
                        {item.L1LedgerName}
                      </div>
                      <div
                        className={`${styles.col} ${styles.col2}`}
                        data-label="L2"
                      >
                        {item.L2Name}
                      </div>
                      <div
                        className={`${styles.col} ${styles.col2}`}
                        data-label="L3"
                      >
                        {item.L3Name}
                      </div>
                      <div
                        className={`${styles.col} ${styles.col2}`}
                        data-label="Valor"
                      >
                        {newValue}
                      </div>
                      <div
                        className={`${styles.col} ${styles.col4}`}
                        data-label="Ações"
                      >
                        <>
                          {updateReleaseItemAllowed && (
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                handleCurrentItem(item.idItemsRelease)
                              }
                            >
                              <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                            </button>
                          )}
                          {deleteReleaseItemAllowed && (
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                handleModalDelete(item.idItemsRelease)
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} size="1x" />
                            </button>
                          )}
                        </>
                      </div>
                      {/* )} */}
                    </li>
                  </div>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;
