import { getCityData } from '../../shared/@types/city';

export const GET_ALL_CITIES = 'GET_ALL_CITIES';

export const getAllCities = (cities: getCityData) => ({
  type: GET_ALL_CITIES,
  payload: {
    data: cities.data,
    pagination: cities.pagination,
  },
});

export interface GetAllCities {
  type: typeof GET_ALL_CITIES;
  payload: {
    data: getCityData;
    pagination: Partial<getCityData>
  };
}
