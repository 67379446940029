import { Participant, ParticipantDam, getParticipantData } from '../../shared/@types/participant';
import { requestService } from '../../utils/requestService';

export const requestParticipantsData = async (
  access_token: string, offset: number, page: number
): Promise<getParticipantData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/person?page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postParticipant = async (access_token: string, participant: Participant): Promise<getParticipantData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/person`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, participant, headers, 'POST', false);
    return response;
};

export const postParticipantDam = async (access_token: string, participant: ParticipantDam): Promise<getParticipantData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/person-dam`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, participant, headers, 'POST', false);
  return response;
};


export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/person?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putParticipant = async (access_token: string, participant: Participant, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/person/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, participant, headers, 'PUT', false);
    return response;
};

export const requestParticipantData = async (
  access_token: string,
  id: string,
): Promise<Participant> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/person?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestParticipantDamsData = async (
  access_token: string,
  id: string,
): Promise<Participant> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/person-dam?idPerson=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};


export const requestDeleteParticipant = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/person/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

export const requestDeleteParticipantDam = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/person-dam/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}


