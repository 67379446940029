import { SubRubric, getSubRubricData } from '../../shared/@types/subrubric';
import { requestService } from '../../utils/requestService';

export const requestSubRubricsData = async (
  access_token: string, offset: number, page: number
): Promise<getSubRubricData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/sub-ledger?page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const requestSubRubricsDataByRubric = async (
  access_token: string, idRubric: string
): Promise<getSubRubricData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/sub-ledger?per_page=500&idLedger=${idRubric}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};


export const postSubRubric = async (access_token: string, subrubric: SubRubric): Promise<getSubRubricData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/sub-ledger`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, subrubric, headers, 'POST', false);
    return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/sub-ledger?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putSubRubric = async (access_token: string, subrubric: SubRubric, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/sub-ledger/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, subrubric, headers, 'PUT', false);
    return response;
};

export const requestSubRubricData = async (
  access_token: string,
  id: string,
): Promise<SubRubric> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/sub-ledger?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteSubRubric = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/sub-ledger/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

