import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

interface RubricProps {
  rootName: string;
  idLedger: string;
  ledgerName: string;
}

interface Props {
  rubrics: RubricProps[] | undefined;
  handleCurrentItem: (id: string) => void;
  updateAllowed: boolean;
  deleteAllowed: boolean;
}

const RubricList = ({
  rubrics = [],
  handleCurrentItem,
  updateAllowed,
  deleteAllowed,
}: Props) => {
  if (rubrics.length === 0) return <h4>Não há rubricas cadastradas.</h4>;
  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>Nome</div>
          <div className={`${styles.col} ${styles.col3}`}>Nível acima</div>
          <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
        </li>

        {rubrics.map((item, index) => (
          <li className={styles.tableRow} key={index}>
            <div className={`${styles.col} ${styles.col1}`} data-label="Id">
              {item.idLedger}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
              <a href={`/rubrica/${item.idLedger}`}>{item.ledgerName}</a>
            </div>
            <div
              className={`${styles.col} ${styles.col3}`}
              data-label="Nível acima"
            >
              {item.rootName ? item.rootName : "-"}
            </div>
            <div className={`${styles.col} ${styles.col4}`} data-label="Ações">
              {updateAllowed && (
                <a
                  href={`/rubrica/${item.idLedger}`}
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                </a>
              )}
              {deleteAllowed && (
                <button
                  className="btn btn-danger"
                  onClick={() => handleCurrentItem(item.idLedger)}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RubricList;
