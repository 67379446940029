import React from "react";
import styles from "./styles.module.scss";

interface Props {
  handleDelete: () => void;
  handleModal: () => void;
  modal: boolean;
}

export default function Index({ handleDelete, handleModal, modal }: Props) {
  return (
    <>
      <div className={`${styles.modalMask}`}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <h1>Tem certeza que deseja excluir este item?</h1>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.btnContainer}>
              <button
                className={styles.globalGreyBtn}
                onClick={() => handleModal()}
              >
                Cancelar
              </button>
            </div>
            <div className={styles.btnContainer}>
              <button
                className={styles.globalBtn}
                onClick={() => handleDelete()}
              >
                Sim
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
