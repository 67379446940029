import { ReleaseItem, getReleaseItemsData } from '../../shared/@types/releaseItems';
import { requestService } from '../../utils/requestService';

export const requestReleaseItemsData = async (
  access_token: string, offset: number, page: number, full_list?: boolean
): Promise<getReleaseItemsData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/items-release?page=${page}`;

  if(full_list === true){
    url = url + '&per_page=500';
  }

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const requestReleaseItemsDataByRelease = async (
  access_token: string, offset: number, idRelease: string
): Promise<getReleaseItemsData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/items-release?idRelease=${idRelease}&per_page=500`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postReleaseItem = async (access_token: string, releaseItem: ReleaseItem): Promise<getReleaseItemData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/items-release`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, releaseItem, headers, 'POST', false);
    return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/items-release?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putReleaseItem = async (access_token: string, releaseItem: ReleaseItem, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/items-release/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, releaseItem, headers, 'PUT', false);
    return response;
};

export const requestReleaseItemData = async (
  access_token: string, 
  id: string,
): Promise<ReleaseItem> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/items-release?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteReleaseItem = async (
  access_token: string, 
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/items-release/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

