import { getReleaseItemsData, ReleaseItem } from '../../shared/@types/releaseItems';

export const GET_RELEASE_ITEM = 'GET_RELEASE_ITEM';
export const GET_ALL_RELEASE_ITEMS = 'GET_ALL_RELEASE_ITEMS';
export const UPDATE_RELEASE_ITEM = 'UPDATE_RELEASE_ITEM';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_RELEASE_ITEMS_SEARCH = 'GET_RELEASE_ITEMS_SEARCH';

export const getReleaseItem = (releaseItem: ReleaseItem) => ({
  type: GET_RELEASE_ITEM,
  payload: {
    releaseItem,
  },
});

export interface GetReleaseItem {
  type: typeof GET_RELEASE_ITEM;
  payload: { releaseItem: ReleaseItem };
}

export const getAllReleaseItems = (releaseItems: getReleaseItemsData) => ({
  type: GET_ALL_RELEASE_ITEMS,
  payload: {
    data: releaseItems.data,
    pagination: releaseItems.pagination,
  },
});

export interface GetAllReleaseItems {
  type: typeof GET_ALL_RELEASE_ITEMS;
  payload: {
    data: getReleaseItemsData;
    pagination: Partial<getReleaseItemsData>
  };
}

export const getReleaseItemsSearch = (releaseItems: getReleaseItemsData) => ({
  type: GET_RELEASE_ITEMS_SEARCH,
  payload: {
    data: releaseItems.data,
    pagination: releaseItems.pagination,
  },
});

export interface GetReleaseItemsSearch {
  type: typeof GET_RELEASE_ITEMS_SEARCH;
  payload: {
    data: getReleaseItemsData;
    pagination: Partial<getReleaseItemsData>
  };
}

export const updateReleaseItem = (releaseItem: ReleaseItem) => ({
  type: UPDATE_RELEASE_ITEM,
  payload: {
    releaseItem,
  },
});

export interface UpdateReleaseItem {
  type: typeof UPDATE_RELEASE_ITEM;
  payload: {
    releaseItem: ReleaseItem;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
