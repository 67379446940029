import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../release/releaseReducer';
import { RootState } from '../reducers';
import * as releaseAction from '../release/releaseAction';
import { putRelease, postRelease, requestReleasesData, requestReleasesDataByProject, requestReleaseData, requestDeleteRelease, postSearch } from '../../services/releases';
import { Release } from '../../shared/@types/release';

const useReleaseState = (): UseState =>
  useSelector((rootState: RootState) => rootState.releases);

export const useReleases = () => {
  const { releases, release_pagination } = useReleaseState();
  const data = { releases, release_pagination }
  return data;
};

export const useGetReleases = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, page: number, full_list?: boolean) => {
    const data = await requestReleasesData(access_token, 0, page, full_list);
    dispatch(releaseAction.getAllReleases(data));
  }, [dispatch]);
};

export const useGetReleasesByProject = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestReleasesDataByProject(access_token, 0, id);
    dispatch(releaseAction.getAllReleases(data));
  }, [dispatch]);
};

export const useGetRelease = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestReleaseData(access_token, id);
    return data;
  }, []);
};

export const useDeleteRelease = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteRelease(access_token, id);
    return data;
  }, [])
};

export const usePostRelease = () => {
  return useCallback(
    async (access_token: string, formReleaseBody: Release) => {
      const createdRelease = await postRelease(access_token, formReleaseBody);
      return createdRelease;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, values: any,  page: number) => {
      const searchResult = await postSearch(access_token, values, page);
      dispatch(releaseAction.getReleasesSearch(searchResult));
    },
    [dispatch],
  );
};

export const usePutRelease = () => {
  return useCallback(
    async (access_token: string, formReleaseBody: Release, id: string) => {
      const createdRelease = await putRelease(access_token, formReleaseBody, id);
      return createdRelease;
    },
    [],
  );
};
