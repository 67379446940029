import { getReportData } from '../../shared/@types/report';

export const GET_ALL_REPORT = 'GET_ALL_REPORT';



export const getAllReport = (report: getReportData) => ({
  type: GET_ALL_REPORT,
  payload: {
    data: report.data,
    pagination: report.pagination,
  },
});

export interface GetAllReport {
  type: typeof GET_ALL_REPORT;
  payload: {
    data: getReportData;
    pagination: Partial<getReportData>
  };
}




