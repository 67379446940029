import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../subrubric/subrubricReducer';
import { RootState } from '../reducers';
import * as subrubricAction from '../subrubric/subrubricAction';
import { putSubRubric, postSubRubric, requestSubRubricsData, requestSubRubricsDataByRubric, requestSubRubricData, requestDeleteSubRubric, postSearch } from '../../services/subrubrics';
import { SubRubric } from '../../shared/@types/subrubric';

const useSubRubricState = (): UseState =>
  useSelector((rootState: RootState) => rootState.subrubrics);

export const useSubRubrics = () => {
  const { subrubrics, subrubric_pagination } = useSubRubricState();
  const data = { subrubrics, subrubric_pagination }
  return data;
};

export const useGetSubRubrics = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, page: number) => {
    const data = await requestSubRubricsData(access_token, 0 , page);
    dispatch(subrubricAction.getAllSubRubrics(data));
  }, [dispatch]);
};

export const useGetSubRubricsByRubric = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, idRubric: string) => {
    const data = await requestSubRubricsDataByRubric(access_token, idRubric);
    dispatch(subrubricAction.getAllSubRubrics(data));
  }, [dispatch]);
};

export const useGetSubRubric = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestSubRubricData(access_token, id);
    return data;
  }, []);
};

export const useDeleteSubRubric = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteSubRubric(access_token, id);
    return data;
  }, [])
};

export const usePostSubRubric = () => {
  return useCallback(
    async (access_token: string, formSubRubricBody: SubRubric) => {
      const createdSubRubric = await postSubRubric(access_token, formSubRubricBody);
      return createdSubRubric;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(subrubricAction.getSubRubricsSearch(searchResult));
    },
    [dispatch],
  );
};


export const usePutSubRubric = () => {
  return useCallback(
    async (access_token: string, formRubricBody: SubRubric, id: string) => {
      const createdSubRubric = await putSubRubric(access_token, formRubricBody, id);
      return createdSubRubric;
    },
    [],
  );
};
