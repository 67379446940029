import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";

import {
    usePostParticipantDam
  } from "../../../store/hooks/participantHooks";
import {
    useGetDams,
    useDams,
} from "../../../store/hooks/damHooks";
import { REQUIRED } from "../../../utils/formUtils";
import { stateProps } from "../../../shared/@types/general";
import { useEffect } from "react";
import { Dam } from "../../../shared/@types/dam";

interface ParticipantFormProps {
    idPerson?: string;
    idDam?: string;
}

interface ParticipantProps {
    idCurrentParticipant: string;
    getCurrentParticipantDams: () => void;
}

const participantDamSchema = yup.object().shape({
    idDam: yup.string().required(REQUIRED),
  });
  

const DamForm = ({idCurrentParticipant, getCurrentParticipantDams} : ParticipantProps) => {
    const { access_token } = useSelector((state: stateProps) => state.auth);
    const postParticipantDam = usePostParticipantDam();

    const getDams = useGetDams();
    const damsData = useDams();

    const handleSubmit = (values: ParticipantFormProps) => {
        let  result = postParticipantDam(access_token, values);
    
        toast.promise(result, {
          loading: "Salvando barragem vinculada ao participante...",
          success: () => {
            getCurrentParticipantDams();
            return "Barragem cadastrada!";
          },
          error: (err) => {
            if (
              err &&
              err.response &&
              err.response.status &&
              (err.response.status === 401 || err.response.status === 403)
            ) {
              return err.response.data.error;
            }
          },
        });
      };

      const formik = useFormik({
        initialValues: {
          idDam: "",
          idPerson: idCurrentParticipant,
        },
        validationSchema: participantDamSchema,
        onSubmit: (values: ParticipantFormProps) => {
          handleSubmit(values);
        },
      });
    
      const { 
        // handleChange,
        errors, 
        touched,
        values,
        setFieldValue } = formik;

    // const getIdDam = (idDam: string) => {
    //     setFieldValue("idDam", idDam);
    // }

    useEffect(() => {
        getDams(access_token, 1);
    },[getDams, access_token])


    return  <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} >
        <Row>
            <Col md={12}>
                <h4>Adicionar barragem que atinge o participante</h4>
            </Col>
        </Row>
        <Row>
            {damsData.dams && Array.isArray(damsData.dams) && (
              <Col md={6}>
                <Form.Label htmlFor="idDam">Barragem</Form.Label>
                <Select
                    placeholder="Selecione uma barragem"
                    noOptionsMessage={() => "Não encontrado"}
                    name="idDam"
                    onChange={(option: any) =>
                    setFieldValue(
                        "idDam",
                        option.value ? option.value : ""
                    )
                    }
                    value={
                    damsData &&
                    damsData.dams &&
                    damsData.dams
                        .map((dam: Dam) => ({
                            label: dam.name,
                            value: dam.idDam,
                        }))
                        .find(
                        (option: OptionValue) =>
                            option.value === values.idDam
                        )
                    }
                    options={
                        damsData &&
                        damsData.dams &&
                        damsData.dams.map((dam: Dam) => ({
                        label: dam.name,
                        value: dam.idDam,
                    }))
                    }
                />
                {touched.idDam && errors.idDam && (
                    <Form.Text>
                        {errors.idDam}
                    </Form.Text>
                )}
                </Col>
            )}
        </Row>
        <Row>
            <Col md={12} className="button-save">
              <Form.Group>
                <Button type="submit">SALVAR</Button>
              </Form.Group>
            </Col>
        </Row>
        </Form>
      </FormikProvider>
}

export default DamForm;