import { getDamData, Dam } from '../../shared/@types/dam';

export const GET_DAM = 'GET_DAM';
export const GET_ALL_DAMS = 'GET_ALL_DAMS';
export const UPDATE_DAM = 'UPDATE_DAM';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_DAMS_SEARCH = 'GET_DAMS_SEARCH';

export const getDam = (dam: Dam) => ({
  type: GET_DAM,
  payload: {
    dam,
  },
});

export interface GetDam {
  type: typeof GET_DAM;
  payload: { dam: Dam };
}

export const getAllDams = (dams: getDamData) => ({
  type: GET_ALL_DAMS,
  payload: {
    data: dams.data,
    pagination: dams.pagination,
  },
});

export interface GetAllDams {
  type: typeof GET_ALL_DAMS;
  payload: {
    data: getDamData;
    pagination: Partial<getDamData>
  };
}

export const getDamsSearch = (dams: getDamData) => ({
  type: GET_DAMS_SEARCH,
  payload: {
    data: dams.data,
    pagination: dams.pagination,
  },
});

export interface GetDamsSearch {
  type: typeof GET_DAMS_SEARCH;
  payload: {
    data: getDamData;
    pagination: Partial<getDamData>
  };
}



export const updateDam = (dam: Dam) => ({
  type: UPDATE_DAM,
  payload: {
    dam,
  },
});

export interface UpdateDam {
  type: typeof UPDATE_DAM;
  payload: {
    dam: Dam;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
