import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Container, Spinner, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import Pagination from "react-js-pagination";

import Admin from "../../shared/components/Admin";
import {
  useHideLoading,
  useLoading,
  useShowLoading,
} from "../../store/hooks/loadingHooks";
import {
  useDeleteSubRubric,
  useGetSubRubrics,
  useSubRubrics,
  usePostSearch,
} from "../../store/hooks/subRubricHooks";
import SubRubricList from "./List/SubRubricList";
import ModalDelete from "../../shared/components/ModalDelete";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import Search from "../../shared/components/Search";

import styles from "../styles.module.scss";

const Index = () => {
  const { access_token } = useSelector((state: stateProps) => state.auth);
  const getCurrentItem = useGetCurrentItem();
  const getSubRubrics = useGetSubRubrics();
  const subrubricsData = useSubRubrics();
  const deleteSubRubric = useDeleteSubRubric();
  const postSearch = usePostSearch();
  const isLoading = useLoading();
  const showLoading = useShowLoading();
  const hideLoading = useHideLoading();

  const [redirect, setRedirect] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const [activePage, setActivePage] = useState(1);

  const handleSubmit = (values: any) => {
    // if (values.search === '') return;

    const result = postSearch(access_token, values.search, 1);

    toast.promise(result, {
      loading: "Buscando SubRubrica...",
      success: (result: any) => {
        return "SubRubricas encontradas!";
      },
      error: (error) => error.message,
    });
  };

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values } = formik;

  useEffect(() => {
    showLoading();

    getCurrentItem("subrubrics");
    const fetchSubRubrics = async () => {
      try {
        let result: any = getSubRubrics(access_token, 1);
        toast.promise(result, {
          loading: "Carregando SubRubricas...",
          success: () => {
            return "Lista de subrubricas carregada!";
          },
          error: (error) => {
            if (
              error &&
              error.response &&
              error.response.status &&
              (error.response.status === 401 || error.response.status === 403)
            ) {
              setRedirect(true);
              return error.response.data.error;
            }
            return error.response.data.error;
          },
        });
      } catch (error: any) {
        if (
          error &&
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setRedirect(true);
          return error.response.data.error;
        }
      } finally {
        hideLoading();
      }
    };

    fetchSubRubrics();
  }, [getSubRubrics, access_token, showLoading, hideLoading, getCurrentItem]);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleCurrentItem = (id: string) => {
    setModal(!modal);
    setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteSubRubric(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo SubRubrica...",
      success: () => {
        getSubRubrics(access_token, 1);
        setModal(!modal);
        return "SubRubrica excluída.";
      },
      error: (error) => error.message,
    });
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);

    if (values.search && values.search !== "") {
      postSearch(access_token, values.search, pageNumber);
    } else {
      getSubRubrics(access_token, pageNumber);
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
        }}
      />
    );
  }

  return (
    <Admin>
      {isLoading && (
        <Container>
          <Spinner animation="border" variant="primary" />
        </Container>
      )}
      {modal && (
        <ModalDelete
          handleModal={handleModal}
          handleDelete={handleDelete}
          modal={modal}
        />
      )}

      <div className={styles.headerInfo}>
        <h2>SubRubricas</h2>
        <a
          href={`/adicionar-subrubrica`}
          className="btn btn-primary default-button"
        >
          Adicionar
        </a>
      </div>
      <hr className={styles.hr} />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Search
            handleChange={handleChange}
            values={values}
            placeholder="Digite o nome da SubRubrica..."
          />
        </Form>
      </FormikProvider>
      <SubRubricList
        subrubrics={subrubricsData.subrubrics}
        handleCurrentItem={handleCurrentItem}
      />
      <div className="pagination">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            subrubricsData &&
            subrubricsData.subrubric_pagination &&
            subrubricsData.subrubric_pagination.total_content
              ? subrubricsData.subrubric_pagination.total_content
              : 0
          }
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </Admin>
  );
};

export default Index;
