import { getStateData } from '../../shared/@types/state';
import { requestService } from '../../utils/requestService';

export const requestStatesData = async (
  access_token: string, offset: number, page?: number
): Promise<getStateData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/locations/state?per_page=500`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};
