import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import toast from "react-hot-toast";

import Admin from "../../shared/components/Admin";
// import File from "../../shared/components/File";
import ReleaseItems from "./releaseItem";
import { Release } from "../../shared/@types/release";
import {
  usePostRelease,
  usePutRelease,
  useGetRelease,
} from "../../store/hooks/releaseHooks";
import styles from "../styles.module.scss";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import { REQUIRED } from "../../utils/formUtils";
import {
  documentTypes,
  paymentMethods,
  releaseTypes,
} from "../../shared/constants";
import { useGetProjects, useProjects } from "../../store/hooks/projectHooks";
import { useGetCashiers, useCashiers } from "../../store/hooks/cashierHooks";
import { useGetProviders, useProviders } from "../../store/hooks/providerHooks";
import {
  useGetReleaseItemsByRelease,
  useReleaseItems,
  useDeleteReleaseItem,
} from "../../store/hooks/releaseItemsHooks";
import ModalManager from "../../shared/components/ModalManager";
import ModalReleaseItem from "../../shared/components/ModalReleaseItem";
import ModalDelete from "../../shared/components/ModalDelete";
import { usePermission } from "../../store/hooks/permissionHooks";

const releaseSchema = yup.object().shape({
  generalDescription: yup.string().required(REQUIRED),
  idBound: yup.string().required(REQUIRED),
  releaseType: yup.string().required(REQUIRED),
  releaseDate: yup.string().required(REQUIRED),
  observation: yup.string(),
  idCashier: yup.string().required(REQUIRED),
  documentNumber: yup.string(),
  documentType: yup.string(),
  idProvider: yup.string(),
  paymentNumber: yup.string(),
  paymentMethod: yup.string(),
});

// interface ParamTypes {
//   id: string;
// }

const ReleaseCreate = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
//   const { id } = useParams<ParamTypes>();
  const postRelease = usePostRelease();
  const putRelease = usePutRelease();
  const getRelease = useGetRelease();

  const getProjects = useGetProjects();
  const projectsData = useProjects();
  const getCashiers = useGetCashiers();
  const cashiersData = useCashiers();
  const getProviders = useGetProviders();
  const providersData = useProviders();

  const getReleaseItems = useGetReleaseItemsByRelease();
  const releaseItemsData = useReleaseItems();
  const deleteReleaseItem = useDeleteReleaseItem();

  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const [idReleaseCreate, setIdReleaseCreate] = useState("")

  const [saveAllowed, setSaveAllowed] = useState(false);
  const [saveReleaseItemAllowed, setSaveReleaseItemAllowed] = useState(false);
  const [updateReleaseItemAllowed, setUpdateReleaseItemAllowed] =
    useState(false);
  const [getReleaseItemAllowed, setGetReleaseItemAllowed] = useState(false);
  const [getOneReleaseItemAllowed, setGetOneReleaseItemAllowed] =
    useState(false);
  const [deleteReleaseItemAllowed, setDeleteReleaseItemAllowed] =
    useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "release_index_post",
      "release_index_put",
      "release_index_get_one",
      "itemsrelease_index_post",
      "itemsrelease_index_put",
      "itemsrelease_index_get",
      "itemsrelease_index_get_one",
      "itemsrelease_index_delete",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (access.includes("itemsrelease_index_post"))
        setSaveReleaseItemAllowed(true);

      if (access.includes("itemsrelease_index_put"))
        setUpdateReleaseItemAllowed(true);

      if (access.includes("itemsrelease_index_get"))
        setGetReleaseItemAllowed(true);

      if (access.includes("itemsrelease_index_get_one"))
        setGetOneReleaseItemAllowed(true);

      if (access.includes("itemsrelease_index_delete"))
        setDeleteReleaseItemAllowed(true);

      if (access.includes("release_index_get_one")) setSaveAllowed(true);
    //   if (!id && access.includes("release_index_post")) setSaveAllowed(true);
    //   if (
    //     id &&
    //     access.includes("release_index_get_one") &&
    //     access.includes("release_index_put")
    //   )
    //     setSaveAllowed(true);
    //   if (
    //     id &&
    //     access.includes("release_index_get_one") &&
    //     !access.includes("release_index_put")
    //   )
    //     setSaveAllowed(false);

    //   if (!access.includes("release_index_get_one") && id) {
    //     toast.error(
    //       "Você não tem acesso para visualizar/editar um lançamento."
    //     );
    //     setRedirect(true);
    //   }
    //   if (!access.includes("release_index_post") && !id) {
    //     toast.error("Você não tem acesso para cadastrar um lançamento.");
    //     setRedirect(true);
    //   }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (values: Release) => {
    let result: any;

    if (idReleaseCreate) {
      result = putRelease(access_token, values, idReleaseCreate);
    } else {
      result = postRelease(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando lançamento...",
      success: (res: any) => {
        if (res.idRelease) {
          toast.success("Lançamento cadastrado!");
          setIdReleaseCreate(res.idRelease)
        }

        return "Lançamento cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      generalDescription: "",
      idBound: "",
      releaseType: "",
      releaseDate: "",
      documentDate: "",
      observation: "",
      idCashier: "",
    },
    validationSchema: releaseSchema,
    onSubmit: (values: Release) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    if (idReleaseCreate && idReleaseCreate !== "") {
        getReleaseItems(access_token, idReleaseCreate);
    }
  },[idReleaseCreate, getReleaseItems, access_token])

  useEffect(() => {
    getProjects(access_token, 1, true);
    getCashiers(access_token, 1, true);
    getProviders(access_token, 1, true);
    getCurrentItem("releases");
  }, [
    getRelease,
    setFieldValue,
    access_token,
    getCurrentItem,
    getProjects,
    getCashiers,
    getProviders,
    getReleaseItems,
  ]);

  const getReleaseTypeName = (type: string) => {
    const result = releaseTypes.filter((item) => item.value === type);
    if (result.length > 0) {
      return `Dados ${result[0].label}`;
    }
  };

  const handleCurrentItem = (id: string) => {
    setShowModal(!showModal);
    setCurrentId(id);
  };

  const handleShowModal = (success?: boolean) => {
    if (success === true) getReleaseItems(access_token, idReleaseCreate);
    setShowModal(!showModal);
    if (!showModal === false) {
      setCurrentId("");
    }
  };

  const handleModalDelete = (id?: string) => {
    setModalDelete(!modalDelete);
    if (id) setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteReleaseItem(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo item de lançamento...",
      success: () => {
        getReleaseItems(access_token, idReleaseCreate);
        setModalDelete(!modalDelete);
        setCurrentId("");
        return "Item de lançamento excluído.";
      },
      error: (error) => error.message,
    });
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/lancamentos`,
        }}
      />
    );
  }

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{"Cadastrar Lançamento"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Descrição </Form.Label>
                <Form.Control
                  id="generalDescription"
                  name="generalDescription"
                  type="text"
                  onChange={handleChange}
                  value={values.generalDescription}
                />
                {touched.generalDescription && errors.generalDescription && (
                  <Form.Text className={styles.textError}>
                    {errors.generalDescription}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="idBound">Projeto</Form.Label>
              <Select
                placeholder="Selecione um projeto"
                noOptionsMessage={() => "Não encontrado"}
                name="idBound"
                onChange={(option: any) =>
                  setFieldValue("idBound", option.value ? option.value : "")
                }
                value={
                  projectsData &&
                  projectsData.projects &&
                  projectsData.projects
                    .map((project: any) => ({
                      label: project.name,
                      value: project.idProject,
                    }))
                    .find((option: any) => option.value === values.idBound)
                }
                options={
                  projectsData &&
                  projectsData.projects &&
                  projectsData.projects.map((project: any) => ({
                    label: project.name,
                    value: project.idProject,
                  }))
                }
              />
              {touched.idProject && errors.idProject && (
                <Form.Text className={styles.textError}>
                  {errors.idProject}
                </Form.Text>
              )}
            </Col>

            <Col md={6}>
              <Form.Label htmlFor="releaseType">Tipo</Form.Label>
              <Select
                placeholder="Selecione um tipo"
                noOptionsMessage={() => "Não encontrado"}
                name="releaseType"
                onChange={(option: any) =>
                  setFieldValue("releaseType", option.value ? option.value : "")
                }
                value={
                  releaseTypes &&
                  releaseTypes
                    .map((type: any) => ({
                      label: type.label,
                      value: type.value,
                    }))
                    .find((option: any) => option.value === values.releaseType)
                }
                options={
                  releaseTypes &&
                  releaseTypes.map((type: any) => ({
                    label: type.label,
                    value: type.value,
                  }))
                }
              />
              {touched.releaseType && errors.releaseType && (
                <Form.Text className={styles.textError}>
                  {errors.releaseType}
                </Form.Text>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="idCashier">Caixa</Form.Label>

              <Select
                placeholder="Selecione um caixa"
                noOptionsMessage={() => "Não encontrado"}
                name="idCashier"
                onChange={(option: any) =>
                  setFieldValue("idCashier", option.value ? option.value : "")
                }
                value={
                  cashiersData &&
                  cashiersData.cashiers &&
                  cashiersData.cashiers
                    .map((cashier: any) => ({
                      label: cashier.name,
                      value: cashier.idCashier,
                    }))
                    .find((option: any) => option.value === values.idCashier)
                }
                options={
                  cashiersData &&
                  cashiersData.cashiers &&
                  cashiersData.cashiers.map((cashier: any) => ({
                    label: cashier.name,
                    value: cashier.idCashier,
                  }))
                }
              />
              {touched.idCashier && errors.idCashier && (
                <Form.Text className={styles.textError}>
                  {errors.idCashier}
                </Form.Text>
              )}
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data</Form.Label>
                <Form.Control
                  id="releaseDate"
                  name="releaseDate"
                  type="date"
                  onChange={handleChange}
                  value={values.releaseDate}
                />
                {touched.releaseDate && errors.releaseDate && (
                  <Form.Text className={styles.textError}>
                    {errors.releaseDate}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data do documento</Form.Label>
                <Form.Control
                  id="documentDate"
                  name="documentDate"
                  type="date"
                  onChange={handleChange}
                  value={values.documentDate}
                />
                {touched.documentDate && errors.documentDate && (
                  <Form.Text className={styles.textError}>
                    {errors.documentDate}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Observações</Form.Label>
                <Form.Control
                  id="observation"
                  name="observation"
                  as="textarea"
                  rows={3}
                  onChange={handleChange}
                  value={values.observation}
                />
                {touched.observation && errors.observation && (
                  <Form.Text className={styles.textError}>
                    {errors.observation}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            {values.releaseType &&
              values.releaseType !== "" &&
              values.releaseType !== "FORECAST" && (
                <>
                  <Col md={12} style={{ marginTop: 20 }}>
                    <h5>
                      {values.releaseType &&
                        getReleaseTypeName(values.releaseType)}
                    </h5>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Número do documento </Form.Label>
                      <Form.Control
                        id="documentNumber"
                        name="documentNumber"
                        type="text"
                        onChange={handleChange}
                        value={values.documentNumber}
                      />
                      {touched.documentNumber && errors.documentNumber && (
                        <Form.Text className={styles.textError}>
                          {errors.documentNumber}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  {values.releaseType === "EXECUTION" && (
                    <>
                      <Col md={6}>
                        <Form.Label htmlFor="documentType">
                          Tipo do documento
                        </Form.Label>
                        <Select
                          placeholder="Selecione um tipo"
                          noOptionsMessage={() => "Não encontrado"}
                          name="documentType"
                          onChange={(option: any) =>
                            setFieldValue(
                              "documentType",
                              option.value ? option.value : ""
                            )
                          }
                          value={
                            documentTypes &&
                            documentTypes
                              .map((type: any) => ({
                                label: type.label,
                                value: type.value,
                              }))
                              .find(
                                (option: any) =>
                                  option.value === values.documentType
                              )
                          }
                          options={
                            documentTypes &&
                            documentTypes.map((type: any) => ({
                              label: type.label,
                              value: type.value,
                            }))
                          }
                        />
                        {touched.documentType && errors.documentType && (
                          <Form.Text className={styles.textError}>
                            {errors.documentType}
                          </Form.Text>
                        )}
                      </Col>
                      <Col md={6}>
                        <Form.Label htmlFor="idProvider">Fornecedor</Form.Label>

                        <Select
                          placeholder="Selecione um fornecedor"
                          noOptionsMessage={() => "Não encontrado"}
                          name="idProvider"
                          onChange={(option: any) =>
                            setFieldValue(
                              "idProvider",
                              option.value ? option.value : ""
                            )
                          }
                          value={
                            providersData &&
                            providersData.providers &&
                            providersData.providers
                              .map((provider: any) => ({
                                label: provider.name,
                                value: provider.idProvider,
                              }))
                              .find(
                                (option: any) =>
                                  option.value === values.idProvider
                              )
                          }
                          options={
                            providersData &&
                            providersData.providers &&
                            providersData.providers.map((provider: any) => ({
                              label: provider.name,
                              value: provider.idProvider,
                            }))
                          }
                        />
                        {touched.idProvider && errors.idProvider && (
                          <Form.Text className={styles.textError}>
                            {errors.idProvider}
                          </Form.Text>
                        )}
                      </Col>
                      <Col md={6}>
                        <Form.Label htmlFor="paymentMethod">
                          Forma de pagamento
                        </Form.Label>
                        <Select
                          placeholder="Selecione uma forma"
                          noOptionsMessage={() => "Não encontrado"}
                          name="paymentMethod"
                          onChange={(option: any) =>
                            setFieldValue(
                              "paymentMethod",
                              option.value ? option.value : ""
                            )
                          }
                          value={
                            paymentMethods &&
                            paymentMethods
                              .map((method: any) => ({
                                label: method.label,
                                value: method.value,
                              }))
                              .find(
                                (option: any) =>
                                  option.value === values.paymentMethod
                              )
                          }
                          options={
                            paymentMethods &&
                            paymentMethods.map((method: any) => ({
                              label: method.label,
                              value: method.value,
                            }))
                          }
                        />
                        {touched.paymentMethod && errors.paymentMethod && (
                          <Form.Text className={styles.textError}>
                            {errors.paymentMethod}
                          </Form.Text>
                        )}
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Número do pagamento </Form.Label>
                          <Form.Control
                            id="paymentNumber"
                            name="paymentNumber"
                            type="text"
                            onChange={handleChange}
                            value={values.paymentNumber}
                          />
                          {touched.paymentNumber && errors.paymentNumber && (
                            <Form.Text className={styles.textError}>
                              {errors.paymentNumber}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </>
              )}
            {saveAllowed && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit">SALVAR</Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
      <hr style={{ marginTop: 30, marginBottom: 50 }} />

      {idReleaseCreate && (
        <>
          <ReleaseItems
            releaseItems={releaseItemsData.releaseItems}
            handleShowModal={handleShowModal}
            handleCurrentItem={handleCurrentItem}
            handleModalDelete={handleModalDelete}
            saveReleaseItemAllowed={saveReleaseItemAllowed}
            updateReleaseItemAllowed={updateReleaseItemAllowed}
            getReleaseItemAllowed={getReleaseItemAllowed}
            getOneReleaseItemAllowed={getOneReleaseItemAllowed}
            deleteReleaseItemAllowed={deleteReleaseItemAllowed}
          />
          {/* <File origin="Release" id={id} /> */}
        </>
      )}
      {showModal && getOneReleaseItemAllowed && (
        <ModalManager showModal={showModal}>
          <ModalReleaseItem
            handleShowModal={handleShowModal}
            idRelease={idReleaseCreate}
            currentIdReleaseItem={currentId}
          />
        </ModalManager>
      )}
      {modalDelete && (
        <ModalDelete
          handleModal={handleModalDelete}
          handleDelete={handleDelete}
          modal={modalDelete}
        />
      )}
    </Admin>
  );
};

export default ReleaseCreate;
