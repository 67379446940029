import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../city/cityReducer';
import { RootState } from '../reducers';
import * as cityAction from '../city/cityAction';
import { requestCitiesData } from '../../services/cities';
// import { Dam } from '../../shared/@types/dam';

const useCityState = (): UseState =>
  useSelector((rootState: RootState) => rootState.cities);

export const useCities = () => {
  const { cities, city_pagination } = useCityState();
  const data = { cities, city_pagination }
  return data;
};

export const useGetCities = () => {
  const dispatch = useDispatch();
  
  return useCallback(async (access_token: string, page: number, state: string) => {
    const data = await requestCitiesData(access_token, 0, page, state);
    dispatch(cityAction.getAllCities(data));
  }, [dispatch]);
};

