import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";

import Admin from "../../shared/components/Admin";
import { Participant } from "../../shared/@types/participant";
import {
  usePostParticipant,
  usePutParticipant,
  useGetParticipant,
  useGetParticipantDams,
  useDeleteParticipantDam,
} from "../../store/hooks/participantHooks";
import { useGetDams } from "../../store/hooks/damHooks";
import styles from "../styles.module.scss";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";

import { REQUIRED } from "../../utils/formUtils";
import City from "../../shared/components/City";
import DamForm from "./Form/DamForm";
import ParticipantDamList from "./participantDamList";
import {
  genders,
  ethnicities,
  documentTypesPerson,
} from "../../shared/constants";
import ModalDelete from "../../shared/components/ModalDelete";
import { usePermission } from "../../store/hooks/permissionHooks";

const participantSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
  birthday: yup.string().required(REQUIRED),
});

interface ParamTypes {
  id: string;
}

const ParticipantManagement = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const { id } = useParams<ParamTypes>();
  const postParticipant = usePostParticipant();
  const putParticipant = usePutParticipant();
  const getParticipant = useGetParticipant();
  const deleteParticipantDam = useDeleteParticipantDam();
  const getParticipantDams = useGetParticipantDams();
  const getDams = useGetDams();

  const [redirect, setRedirect] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const [idCity, setIdCity] = useState("");
  const [idState, setIdState] = useState("");
  const [participantDams, setParticipantDams] = useState<any>([]);

  const [saveAllowed, setSaveAllowed] = useState(false);
  const [savePersonDamAllowed, setSavePersonDamAllowed] = useState(false);
  const [getPersonDamAllowed, setGetPersonDamAllowed] = useState(false);
  const [deletePersonDamAllowed, setDeletePersonDamAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "person_index_post",
      "person_index_put",
      "person_index_get_one",
      "persondam_index_post",
      "persondam_index_get",
      "persondam_index_delete",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (id && access.includes("persondam_index_post"))
        setSavePersonDamAllowed(true);
      if (id && access.includes("persondam_index_get"))
        setGetPersonDamAllowed(true);
      if (id && access.includes("persondam_index_delete"))
        setDeletePersonDamAllowed(true);

      if (id && access.includes("person_index_get_one")) setSaveAllowed(true);
      if (!id && access.includes("person_index_post")) setSaveAllowed(true);
      if (
        id &&
        access.includes("person_index_get_one") &&
        access.includes("person_index_put")
      )
        setSaveAllowed(true);
      if (
        id &&
        access.includes("person_index_get_one") &&
        !access.includes("person_index_put")
      )
        setSaveAllowed(false);

      if (!access.includes("person_index_get_one") && id) {
        toast.error(
          "Você não tem acesso para visualizar/editar um participante."
        );
        setRedirect(true);
      }
      if (!access.includes("person_index_post") && !id) {
        toast.error("Você não tem acesso para cadastrar um participante.");
        setRedirect(true);
      }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const getCurrentParticipantDams = async () => {
    const resultParticipanDams: any = await getParticipantDams(
      access_token,
      id
    );
    if (resultParticipanDams) {
      setParticipantDams(resultParticipanDams.data);
    }
  };

  const handleSubmit = (values: Participant) => {
    let result: any;

    if (id) {
      result = putParticipant(access_token, values, id);
    } else {
      result = postParticipant(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando participante...",
      success: () => {
        setRedirect(true);
        return "Participante cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      birthday: "",
      gender: "",
      otherGender: "",
      ethnicity: "",
      otherEthnicity: "",
      documentType: "",
      documentNumber: "",
      idCity: "",
    },
    validationSchema: participantSchema,
    onSubmit: (values: Participant) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getDams(access_token, 1, true);
    getCurrentParticipantDams();

    getCurrentItem("participants");
    const getInfo = async () => {
      if (id) {
        getParticipantDams(access_token, id);

        const result: any = await getParticipant(access_token, id);
        if (result) {
          setFieldValue("name", result.name);
          setFieldValue("birthday", result.birthday);
          setFieldValue("gender", result.gender);
          setFieldValue("otherGender", result.otherGender);
          setFieldValue("ethnicity", result.ethnicity);
          setFieldValue("otherEthnicity", result.otherEthnicity);
          setFieldValue("documentType", result.documentType);
          setFieldValue("documentNumber", result.documentNumber);
          setIdCity(result.idCity);
          setIdState(result.idState);
        }
      }
    };

    getInfo();
    // eslint-disable-next-line
  }, [
    id,
    getParticipant,
    setFieldValue,
    access_token,
    getCurrentItem,
    getDams,
    getParticipantDams,
  ]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/participantes`,
        }}
      />
    );
  }

  const getIdCity = (id: string) => {
    setFieldValue("idCity", id);
  };

  const handleModalDelete = (id?: string) => {
    setModalDelete(!modalDelete);
    if (id) setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteParticipantDam(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo barragem...",
      success: () => {
        getCurrentParticipantDams();
        setModalDelete(!modalDelete);
        setCurrentId("");
        return "Item de lançamento barragem.";
      },
      error: (error) => error.message,
    });
  };

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar Participante" : "Cadastrar Participante"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <Form.Text className={styles.textError}>
                    {errors.name}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data de nascimento</Form.Label>
                <Form.Control
                  id="birthday"
                  name="birthday"
                  type="date"
                  onChange={handleChange}
                  value={values.birthday}
                />
                {touched.birthday && errors.birthday && (
                  <Form.Text className={styles.textError}>
                    {errors.birthday}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Label htmlFor="gender">Genero</Form.Label>
              <Form.Select
                name="gender"
                id="gender"
                value={values.gender}
                onChange={handleChange}
                style={{ margin: 0 }}
              >
                <option value="">Escolha sua opção</option>
                {genders.map((item, index) => (
                  <option key={index} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Form.Select>
              {touched.gender && errors.gender && (
                <Form.Text className={styles.textError}>
                  {errors.gender}
                </Form.Text>
              )}
            </Col>

            {values.gender === "OTHER" && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Outro genero</Form.Label>
                  <Form.Control
                    id="otherGender"
                    name="otherGender"
                    type="text"
                    onChange={handleChange}
                    value={values.otherGender}
                  />
                  {touched.otherGender && errors.otherGender && (
                    <Form.Text className={styles.textError}>
                      {errors.otherGender}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            )}

            <Col md={6}>
              <Form.Label htmlFor="ethnicity">Etnia</Form.Label>
              <Form.Select
                name="ethnicity"
                id="ethnicity"
                value={values.ethnicity}
                onChange={handleChange}
                style={{ margin: 0 }}
              >
                <option value="">Escolha sua opção</option>
                {ethnicities.map((item, index) => (
                  <option key={index} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Form.Select>
              {touched.ethnicity && errors.ethnicity && (
                <Form.Text className={styles.textError}>
                  {errors.ethnicity}
                </Form.Text>
              )}
            </Col>

            {values.ethnicity === "OTHER" && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Outra Etnia</Form.Label>
                  <Form.Control
                    id="otherEthnicity"
                    name="otherEthnicity"
                    type="text"
                    onChange={handleChange}
                    value={values.otherEthnicity}
                  />
                  {touched.otherEthnicity && errors.otherEthnicity && (
                    <Form.Text className={styles.textError}>
                      {errors.otherEthnicity}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            )}

            <Col md={6}>
              <Form.Label htmlFor="documentType">Tipo de documento</Form.Label>
              <Form.Select
                name="documentType"
                id="documentType"
                value={values.documentType}
                onChange={handleChange}
                style={{ margin: 0 }}
              >
                <option value="">Escolha sua opção</option>
                {documentTypesPerson.map((item, index) => (
                  <option key={index} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Form.Select>
              {touched.documentType && errors.documentType && (
                <Form.Text className={styles.textError}>
                  {errors.documentType}
                </Form.Text>
              )}
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Número do documento</Form.Label>
                <Form.Control
                  id="documentNumber"
                  name="documentNumber"
                  type="text"
                  onChange={handleChange}
                  value={values.documentNumber}
                />
                {touched.documentNumber && errors.documentNumber && (
                  <Form.Text className={styles.textError}>
                    {errors.documentNumber}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <City getIdCity={getIdCity} idCity={idCity} idState={idState} />

            {saveAllowed && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit">SALVAR</Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
      <hr style={{ marginTop: 30, marginBottom: 50 }} />

      {id && (
        <>
          {savePersonDamAllowed && (
            <DamForm
              idCurrentParticipant={id}
              getCurrentParticipantDams={getCurrentParticipantDams}
            />
          )}
          {getPersonDamAllowed && (
            <ParticipantDamList
              participantDams={participantDams}
              handleModalDelete={handleModalDelete}
              deletePersonDamAllowed={deletePersonDamAllowed}
            />
          )}
        </>
      )}

      {modalDelete && (
        <ModalDelete
          handleModal={handleModalDelete}
          handleDelete={handleDelete}
          modal={modalDelete}
        />
      )}
    </Admin>
  );
};

export default ParticipantManagement;
