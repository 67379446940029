import { useFormik, FormikProvider } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

import {
  usePostReleaseItem,
  usePutReleaseItem,
  useGetReleaseItem,
} from "../../../store/hooks/releaseItemsHooks";
import {
  useGetRubrics,
  useGetLedgerByLevel,
} from "../../../store/hooks/rubricHooks";
import { useGetSubRubric } from "../../../store/hooks/subRubricHooks";
import styles from "./styles.module.scss";
import { stateProps } from "../../@types/general";
import { REQUIRED } from "../../../utils/formUtils";
import { ReleaseItem } from "../../@types/releaseItems";
// import { ProjectActivity } from "../../@types/projectActivity";
// import { useGetProjectActivity } from "../../../store/hooks/projectActivityHooks";

const releaseItemSchema = yup.object().shape({
  idRelease: yup.string().required(REQUIRED),
  idLedger: yup.string().required(REQUIRED),
  value: yup.string().required(REQUIRED),
  description: yup.string(),
});

interface Props {
  handleShowModal: (success: boolean) => void;
  idRelease: string;
  currentIdReleaseItem?: string;
}

const ModalReleaseItem = ({
  handleShowModal,
  idRelease,
  currentIdReleaseItem,
}: Props) => {
  const { access_token } = useSelector((state: stateProps) => state.auth);
  const postReleaseItem = usePostReleaseItem();
  const putReleaseItem = usePutReleaseItem();
  const getReleaseItem = useGetReleaseItem();
  const getRubrics = useGetRubrics();
  const getSubRubric = useGetSubRubric();
  const getLedgerLevel = useGetLedgerByLevel();

  const [idLedger, setIdLedger] = useState("");
  const [idSubRubric, setIdSubRubric] = useState("");
  const [rubricLevelOne, setRubricLevelOne] = useState<any>([]);
  const [rubricLevelTwo, setRubricLevelTwo] = useState<any>([]);
  const [rubricLevelThree, setRubricLevelThree] = useState<any>([]);

  const currencyToNumber = (currency: string) =>
    Number(
      currency.replaceAll(".", "").replaceAll(",", ".").replace(/\s/g, "")
    );

  const formik = useFormik({
    initialValues: {
      idRelease: idRelease,
      idLedger: "",
      value: 0,
      description: "",
    },
    validationSchema: releaseItemSchema,
    onSubmit: (values: ReleaseItem) => {
      const formatedValues = {
        idRelease: values.idRelease,
        idLedger: values.idLedger,
        value: currencyToNumber(values.value),
        description: values.description,
      };
      handleSubmit(formatedValues);
    },
  });

  const { handleChange, values, errors, touched, setFieldValue } = formik;

  const getRubricLevel = async (level: string, idRootLedger?: string) => {
    let responseRubric = await getLedgerLevel(
      access_token,
      level,
      idRootLedger
    );

    if (level === "L1") {
      setRubricLevelOne(responseRubric && responseRubric.data);
    }

    if (level === "L2") {
      setRubricLevelTwo(responseRubric && responseRubric.data);
    }

    if (level === "L3") {
      setRubricLevelThree(responseRubric && responseRubric.data);
    }

    return responseRubric;
  };

  useEffect(() => {
    getRubricLevel("L1");

    const getInfo = async () => {
      if (currentIdReleaseItem) {
        const result: any = await getReleaseItem(
          access_token,
          currentIdReleaseItem
        );
        if (result) {
          setFieldValue("value", result.value);
          setFieldValue("description", result.description);
          setFieldValue("idLedger", result.idLedger);

          if (result.idL1Ledger) {
            setIdLedger(result.idL1Ledger);
          }

          if (result.idL2Ledger) {
            setIdSubRubric(result.idL2Ledger);
          }
        }
      }
    };

    getInfo();
    // eslint-disable-next-line
  }, [
    getRubrics,
    getSubRubric,
    access_token,
    currentIdReleaseItem,
    getReleaseItem,
    setFieldValue,
    // getRubricLevel,
  ]);

  const handleSubmit = (values: ReleaseItem) => {
    let result: any;

    if (currentIdReleaseItem) {
      result = putReleaseItem(access_token, values, currentIdReleaseItem);
    } else {
      result = postReleaseItem(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando item de lançamento...",
      success: () => {
        handleShowModal(true);
        return "Item de lançamento cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  useEffect(() => {
    if (idLedger && idLedger !== "") {
      getRubricLevel("L2", idLedger);
    }
    // eslint-disable-next-line
  }, [idLedger, access_token]);

  useEffect(() => {
    if (idSubRubric && idSubRubric !== "") {
      getRubricLevel("L3", idSubRubric);
    }
    // eslint-disable-next-line
  }, [idSubRubric, access_token]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Itens de lançamento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className=" align-items-end">
              <Col md={6}>
                <Form.Label htmlFor="idLedger">Rubrica</Form.Label>
                <Select
                  placeholder="Selecione uma rubrica"
                  noOptionsMessage={() => "Não encontrado"}
                  name="idLedger"
                  onChange={(option: any) =>
                    setIdLedger(option.value ? option.value : "")
                  }
                  value={
                    rubricLevelOne &&
                    rubricLevelOne.length > 0 &&
                    rubricLevelOne
                      .map((rubric: any) => ({
                        label: rubric.ledgerName,
                        value: rubric.idLedger,
                      }))
                      .find((option: any) => option.value === idLedger)
                  }
                  options={
                    rubricLevelOne &&
                    rubricLevelOne.length > 0 &&
                    rubricLevelOne.map((rubric: any) => ({
                      label: rubric.ledgerName,
                      value: rubric.idLedger,
                    }))
                  }
                />
              </Col>
              <Col md={6}>
                <Form.Label htmlFor="idSubRubric">Rubrica - Nível 2</Form.Label>
                <Select
                  placeholder="Selecione uma opção"
                  noOptionsMessage={() => "Não encontrado"}
                  name="idSubRubric"
                  onChange={(option: any) =>
                    setIdSubRubric(option.value ? option.value : "")
                  }
                  value={
                    rubricLevelTwo &&
                    rubricLevelTwo.length > 0 &&
                    rubricLevelTwo
                      .map((rubric: any) => ({
                        label: rubric.ledgerName,
                        value: rubric.idLedger,
                      }))
                      .find((option: any) => option.value === idSubRubric)
                  }
                  options={
                    rubricLevelTwo &&
                    rubricLevelTwo.length > 0 &&
                    rubricLevelTwo.map((rubric: any) => ({
                      label: rubric.ledgerName,
                      value: rubric.idLedger,
                    }))
                  }
                />
              </Col>
              <Col md={6}>
                <Form.Label htmlFor="idLedger">Rubrica - Nível 3</Form.Label>
                <Select
                  placeholder="Selecione uma opção"
                  noOptionsMessage={() => "Não encontrado"}
                  name="idLedger"
                  onChange={(option: any) =>
                    setFieldValue("idLedger", option.value ? option.value : "")
                  }
                  value={
                    rubricLevelThree &&
                    rubricLevelThree.length > 0 &&
                    rubricLevelThree
                      .map((subrubric: any) => ({
                        label: subrubric.ledgerName,
                        value: subrubric.idLedger,
                      }))
                      .find((option: any) => option.value === values.idLedger)
                  }
                  options={
                    rubricLevelThree &&
                    rubricLevelThree.length > 0 &&
                    rubricLevelThree.map((subrubric: any) => ({
                      label: subrubric.ledgerName,
                      value: subrubric.idLedger,
                    }))
                  }
                />
                {touched.idLedger && errors.idLedger && (
                  <Form.Text className={styles.textError}>
                    {errors.idLedger}
                  </Form.Text>
                )}
              </Col>

              <Col md={12}>
                <Form.Group>
                  <Form.Label>Valor</Form.Label>
                  <CurrencyInput
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    className={styles.valueInput}
                    id="value"
                    name="value"
                    placeholder="Digite um número"
                    defaultValue={values.value}
                    value={values.value}
                    decimalsLimit={2}
                    onValueChange={(value: any, name: any) => {
                      setFieldValue("value", value ? value : "");
                    }}
                  />

                  {touched.value && errors.value && (
                    <Form.Text className={styles.textError}>
                      {errors.value}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group>
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    id="description"
                    name="description"
                    type="text"
                    onChange={handleChange}
                    value={values.description}
                  />
                  {touched.description && errors.description && (
                    <Form.Text className={styles.textError}>
                      {errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleShowModal}>CANCELAR</Button>
            <Button
              type="submit"
              style={{ backgroundColor: "#00897b", border: "#00897b" }}
            >
              SALVAR
            </Button>
          </Modal.Footer>
        </Form>
      </FormikProvider>
    </>
  );
};

export default ModalReleaseItem;
