import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  //   faPlus,
  //   faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
// import { Button } from "react-bootstrap";

import styles from "./styles.module.scss";
// import { formatter } from "../../../utils/formUtils";

interface Props {
  damCities: any;
  handleModalDelete: (id: string) => void;
  deleteDamRangeAllowed: boolean;
}

const Index = ({
  damCities = [],
  handleModalDelete,
  deleteDamRangeAllowed,
}: Props) => {
  return (
    <div className={styles.cities}>
      <div className={styles.citiesHeader}>
        <div>
          <h4>Cidades atingidas</h4>
        </div>
        {/* <div>
          <Button onClick={handleShowModal}>
            <FontAwesomeIcon icon={faPlus} size="1x" />
          </Button>
        </div> */}
      </div>
      <div className="cities--list">
        <div className={styles.container}>
          <ul className={styles.responsiveTable}>
            <li className={styles.tableHeader}>
              <div className={`${styles.col} ${styles.col1}`}>Estado</div>
              <div className={`${styles.col} ${styles.col2}`}>Cidade</div>
              <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
            </li>

            {damCities && damCities.length === 0 && (
              <h5>Sem cidades cadastradas</h5>
            )}

            {damCities.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {/* <div style={{ paddingLeft: 30, paddingTop: 5 }}>
                    {item.state}
                  </div> */}
                  <li className={styles.tableRow} key={item.idCity}>
                    <div
                      className={`${styles.col} ${styles.col1}`}
                      data-label="Estado"
                    >
                      {item.nameState}
                    </div>
                    <div
                      className={`${styles.col} ${styles.col2}`}
                      data-label="Cidade"
                    >
                      {item.nameCity}
                    </div>
                    <div
                      className={`${styles.col} ${styles.col4}`}
                      data-label="Ações"
                    >
                      <>
                        {/* <button
                          className="btn btn-primary"
                          onClick={() => handleCurrentItem(item.idItemsRelease)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                        </button> */}
                        {deleteDamRangeAllowed && (
                          <button
                            className="btn btn-danger"
                            onClick={() => handleModalDelete(item.idDamRange)}
                          >
                            <FontAwesomeIcon icon={faTrash} size="1x" />
                          </button>
                        )}
                      </>
                    </div>
                    {/* )} */}
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;
