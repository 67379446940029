import axios, { AxiosRequestConfig, Method } from "axios";
// import * as utils from "./index";

// const getErrorMessage = (error: AxiosError): string => {
//   // if (error.response?.data.error) {
//   //   return error.response?.data.error;
//   // }

//   // if (error.response && error.response?.data && error.response.data.messages) {
//   //   return error.response.data.messages;
//   // }

//   if (error.response && error.response.data && error.response.data.message) {
//     return error.response.data.message;
//   }

//   return error.message;
// };

// const getErrorMessageByResponseCode = (error: AxiosError): string => {
//   // switch (error.response?.status) {
//   //   case 403:
//   //     return 'Usuário não tem acesso para realizar esta requisição!';
//   //   case 424:
//   //     return 'Erro ao validar os dados!';
//   //   default:
//   //     return getErrorMessage(error);
//   // }
//   return 'erro';
// };

export const requestService = async (
  url: string,
  body: unknown,
  headers: unknown,
  method: Method = 'POST',
  pureHeaders = false,
) => {
  try {
    const config: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers,
      pureHeaders,
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error)) {
    //   if (
    //     error.response?.status === 401 &&
    //     url !== `${process.env.PUBLIC_URL}/auth` &&
    //     utils.isLogged()
    //   ) {
    //     // utils.logout(true);
    //   }
    //   error.message = getErrorMessageByResponseCode(error);
    //   throw error;
    // }
    throw error;
  }
};

export const logout = (awaitForLogout = false) => {
  localStorage.removeItem('teste');
  const rootUrl = `${process.env.PUBLIC_URL}/`;
  if (awaitForLogout) {
    setTimeout(() => {
      window.location.href = rootUrl;
    });
  } else {
    window.location.href = rootUrl;
  }
};
