import { Release, getReleaseData } from "../../shared/@types/release";
import { requestService } from "../../utils/requestService";

export const requestReleasesData = async (
  access_token: string,
  offset: number,
  page: number,
  full_list?: boolean
): Promise<getReleaseData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/release?page=${page}&idBound=1`;

  if (full_list === true) {
    url = url + "&per_page=500";
  }

  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
  const data = await requestService(url, {}, headers, "GET", false);
  return data;
};

export const requestReleasesDataByProject = async (
  access_token: string,
  offset: number,
  idProject: string
): Promise<getReleaseData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/release?idBound=1&per_page=500`;
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
  const data = await requestService(url, {}, headers, "GET", false);
  return data;
};

export const postRelease = async (
  access_token: string,
  release: Release
): Promise<getReleaseData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/release`;
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
  const response = await requestService(url, release, headers, "POST", false);
  return response;
};

export const postSearch = async (
  access_token: string,
  values: any,
  page: number
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/release?page=${page}&per_page=500`;

  console.log("postSearch values", values);

  const {
    idProject,
    idBound,
    releaseType,
    initialDate,
    finalDate,
    docInitialDate,
    docFinalDate,
    idProvider,
    value,
    L1,
    L2,
    L3,
  } = values;

  if (idProject && idProject !== "") {
    url = url + `&idProject=${idProject}`;
  }

  if (idBound && idBound !== "") {
    url = url + `&idBound=${idBound}`;
  }

  if (releaseType && releaseType !== "") {
    url = url + `&releaseType=${releaseType}`;
  }

  if (initialDate && initialDate !== "") {
    url = url + `&initialDate=${initialDate}`;
  }

  if (finalDate && finalDate !== "") {
    url = url + `&finalDate=${finalDate}`;
  }

  if (docInitialDate && docInitialDate !== "") {
    url = url + `&docInitialDate=${docInitialDate}`;
  }

  if (docFinalDate && docFinalDate !== "") {
    url = url + `&docFinalDate=${docFinalDate}`;
  }

  if (idProvider && idProvider !== "") {
    url = url + `&idProvider=${idProvider}`;
  }

  if (value && value !== "") {
    url = url + `&value=${value}`;
  }

  if (!L3 && !L2 && L1 && L1 !== "")
    url = url + `&ledgerLevel=1&idLedger=${L1}`;
  if (!L3 && L1 && L2 && L2 !== "") url = url + `&ledgerLevel=2&idLedger=${L2}`;
  if (L3 && L3 !== "") url = url + `&ledgerLevel=3&idLedger=${L3}`;

  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
  const response = await requestService(url, {}, headers, "GET", false);
  return response;
};

export const putRelease = async (
  access_token: string,
  release: Release,
  id: string
): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/release/${id}`;
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
  const response = await requestService(url, release, headers, "PUT", false);
  return response;
};

export const requestReleaseData = async (
  access_token: string,
  id: string
): Promise<Release> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/release?id=${id}`;
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
  const response = await requestService(url, {}, headers, "GET", false);
  return response;
};

export const requestDeleteRelease = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/release/${id}`;
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
  const response = await requestService(url, {}, headers, "DELETE", false);
  return response;
};
