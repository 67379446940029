import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import * as yup from "yup";
import toast from "react-hot-toast";

import Admin from "../../shared/components/Admin";
import { stateProps } from "../../shared/@types/general";
import { usePutPassword } from "../../store/hooks/userHooks";
import { UserUpdatePassword } from "../../shared/@types/user";

import styles from "./styles.module.scss";
import { REQUIRED } from "../../utils/formUtils";
import { Redirect } from "react-router";
import { useEffect, useState } from "react";
import { usePermission } from "../../store/hooks/permissionHooks";

const newPasswordSchema = yup.object().shape({
  oldPassword: yup.string().required(REQUIRED),
  newPassword: yup.string().required(REQUIRED),
  confirmNewPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      "A nova senha e a confirmação não são iguais."
    ),
});

const Index = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const updateUserPassword = usePutPassword();

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = ["users_updatePassword_put"];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (!access.includes("users_updatePassword_put")) {
        toast.error("Você não tem acesso a alteração de senha.");
        setRedirect(true);
      }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);


  const handleSubmit = (values: UserUpdatePassword) => {
    let result = updateUserPassword(access_token, values);

    toast.promise(result, {
      loading: "Salvando informações...",
      success: () => {
        return "Atualizado com sucesso!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: newPasswordSchema,
    onSubmit: (values: UserUpdatePassword) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, errors, touched } = formik;

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/home`,
        }}
      />
    );
  }

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>Atualizar senha</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Senha atual</Form.Label>
                <Form.Control
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  onChange={handleChange}
                  value={values.oldPassword}
                />
                {touched.oldPassword && errors.oldPassword && (
                  <Form.Text className={styles.textError}>
                    {errors.oldPassword}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <Form.Label>Nova senha</Form.Label>
                <Form.Control
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  onChange={handleChange}
                  value={values.newPassword}
                />
                {touched.newPassword && errors.newPassword && (
                  <Form.Text className={styles.textError}>
                    {errors.newPassword}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <Form.Label>Confirmação de nova senha</Form.Label>
                <Form.Control
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  onChange={handleChange}
                  value={values.confirmNewPassword}
                />
                {touched.confirmNewPassword && errors.confirmNewPassword && (
                  <Form.Text className={styles.textError}>
                    {errors.confirmNewPassword}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={12} className="button-save">
              <Form.Group>
                <Button type="submit" className={styles.buttonDefault}>
                  Salvar
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    </Admin>
  );
};

export default Index;
