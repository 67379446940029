import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

interface ActivityProps {
  idActivity: string;
  subject: string;
  lock: boolean;
}

interface Props {
  activities: ActivityProps[] | undefined;
  handleCurrentItem: (id: string) => void;
  handleCurrentItemLock: (id: string) => void;
  updateAllowed: boolean;
  deleteAllowed: boolean;
}

const ActivityList = ({
  activities = [],
  handleCurrentItem,
  handleCurrentItemLock,
  updateAllowed,
  deleteAllowed,
}: Props) => {
  if (activities.length === 0) return <h4>Não há atividades cadastradas.</h4>;

  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>Assunto</div>
          <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
        </li>

        {activities.map((item) => {
          const lock = item.lock === "0" ? false : true;
          return (
            <li className={styles.tableRow} key={item.idActivity}>
              <div className={`${styles.col} ${styles.col1}`} data-label="Id">
                {item.idActivity}
              </div>
              <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
                <a href={`/atividade/${item.idActivity}`}>{item.subject}</a>
              </div>
              <div
                className={`${styles.col} ${styles.col4}`}
                data-label="Ações"
              >
                {updateAllowed && (
                  <>
                    <button
                      className="btn btn-default"
                      onClick={() => handleCurrentItemLock(item.idActivity)}
                      disabled={lock}
                    >
                      <FontAwesomeIcon icon={faLock} size="1x" />
                    </button>

                    <a
                      href={`/atividade/${item.idActivity}`}
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                    </a>
                  </>
                )}
                {deleteAllowed && (
                  <button
                    className="btn btn-danger"
                    onClick={() => handleCurrentItem(item.idActivity)}
                  >
                    <FontAwesomeIcon icon={faTrash} size="1x" />
                  </button>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ActivityList;
