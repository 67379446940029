import { Rubric, getRubricData } from '../../shared/@types/rubric';
import { requestService } from '../../utils/requestService';

export const requestRubricsData = async (
  access_token: string, offset: number, page: number, full_list?: boolean
): Promise<getRubricData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/ledger?page=${page}`;

  if(full_list === true){
    url = url + '&per_page=500';
  }

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postRubric = async (access_token: string, rubric: Rubric): Promise<getRubricData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/ledger`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, rubric, headers, 'POST', false);
    return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/ledger?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putRubric = async (access_token: string, rubric: Rubric, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/ledger/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, rubric, headers, 'PUT', false);
    return response;
};

export const requestRubricData = async (
  access_token: string,
  id: string,
): Promise<Rubric> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/ledger?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestLedgerLevelData = async (
  access_token: string,
  ledgerLevel: string,
  idRootLedger?: string
): Promise<Rubric> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/ledger?ledgerLevel=${ledgerLevel}&per_page=500`;

  if(idRootLedger) {
    url = url + `&idRootLedger=${idRootLedger}`;
  }

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};



export const requestDeleteRubric = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/ledger/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

