import Modal from "react-bootstrap/Modal";

interface Props {
  showModal?: boolean;
  children: React.ReactNode;
}

const ModalManager = ({ children, showModal }: Props) => (
  <Modal show={showModal}>{children}</Modal>
);

export default ModalManager;
