import { Participant, getParticipantData, PaginationProps } from '../../shared/@types/participant';
import * as participantAction from './participantAction';

export interface State {
  participants?: getParticipantData;
  participant?: Participant;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  participant_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  participants: undefined,
  queryParam: undefined,
  participant: undefined,
  loading: true,
};

export type Actions =
  | participantAction.GetAllParticipants
  | participantAction.GetParticipantsSearch
  | participantAction.GetParticipant
  | participantAction.SetQueryParam
  | participantAction.UpdateParticipant;

export const participantReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case participantAction.GET_ALL_PARTICIPANTS: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        participants: data,
        participant_pagination: pagination,
      };
    }
    case participantAction.GET_PARTICIPANTS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        participants: data,
        participants_pagination: pagination,
      };
    }
    case participantAction.GET_PARTICIPANT: {
      const { participant } = action.payload;
      return {
        ...state,
        participant,
        loading: false,
      };
    }
    case participantAction.UPDATE_PARTICIPANT: {
      const { participant } = action.payload;
      return {
        ...state,
        participant,
      };
    }
    case participantAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
