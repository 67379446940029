import { Contract, getContractData, PaginationProps } from '../../shared/@types/contract';
import * as contractAction from './contractAction';

export interface State {
  contracts?: getContractData;
  contract?: Contract;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  contract_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  contracts: undefined,
  queryParam: undefined,
  contract: undefined,
  loading: true,
};

export type Actions =
  | contractAction.GetAllContracts
  | contractAction.GetContractsSearch
  | contractAction.GetContract
  | contractAction.SetQueryParam
  | contractAction.UpdateContract;

export const contractReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case contractAction.GET_ALL_CONTRACT: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        contracts: data,
        contract_pagination: pagination
      };
    }
    case contractAction.GET_CONTRACTS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        contracts: data,
        contract_pagination: pagination
      };
    }
    case contractAction.GET_CONTRACT: {
      const { contract } = action.payload;
      return {
        ...state,
        contract,
        loading: false,
      };
    }
    case contractAction.UPDATE_CONTRACT: {
      const { contract } = action.payload;
      return {
        ...state,
        contract,
      };
    }
    case contractAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
