import { File, getFileData, PaginationProps } from '../../shared/@types/file';
import * as fileAction from './fileAction';

export interface State {
  files?: getFileData;
  file?: File;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  file_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  files: undefined,
  file: undefined,
  queryParam: undefined,
  loading: true,
};

export type Actions =
  | fileAction.GetAllFiles
  | fileAction.GetFile
  | fileAction.UpdateFile;

export const fileReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case fileAction.GET_ALL_FILE: {
      const { data } = action.payload;
      return {
        ...state,
        files: data,
      };
    }
    case fileAction.GET_FILE: {
      const { file } = action.payload;
      return {
        ...state,
        file,
        loading: false,
      };
    }
    case fileAction.UPDATE_FILE: {
      const { file } = action.payload;
      return {
        ...state,
        file,
      };
    }
    default:
      return state;
  }
};
