import { getCashierData, Cashier } from '../../shared/@types/cashier';

export const GET_CASHIER = 'GET_CASHIER';
export const GET_ALL_CASHIERS = 'GET_ALL_CASHIERS';
export const UPDATE_CASHIER = 'UPDATE_CASHIER';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_CASHIERS_SEARCH = 'GET_CASHIERS_SEARCH';

export const getCashier = (cashier: Cashier) => ({
  type: GET_CASHIER,
  payload: {
    cashier,
  },
});

export interface GetCashier {
  type: typeof GET_CASHIER;
  payload: { cashier: Cashier };
}

export const getAllCashiers = (cashiers: getCashierData) => ({
  type: GET_ALL_CASHIERS,
  payload: {
    data: cashiers.data,
    pagination: cashiers.pagination,
  },
});

export interface GetAllCashiers {
  type: typeof GET_ALL_CASHIERS;
  payload: {
    data: getCashierData;
    pagination: Partial<getCashierData>
  };
}

export const getCashiersSearch = (cashiers: getCashierData) => ({
  type: GET_CASHIERS_SEARCH,
  payload: {
    data: cashiers.data,
    pagination: cashiers.pagination,
  },
});

export interface GetCashiersSearch {
  type: typeof GET_CASHIERS_SEARCH;
  payload: {
    data: getCashierData;
    pagination: Partial<getCashierData>
  };
}



export const updateCashier = (cashier: Cashier) => ({
  type: UPDATE_CASHIER,
  payload: {
    cashier,
  },
});

export interface UpdateCashier {
  type: typeof UPDATE_CASHIER;
  payload: {
    cashier: Cashier;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
