import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

interface ContractProps {
  idContract: string;
  name: string;
}

interface Props {
  contracts: ContractProps[] | undefined;
  handleCurrentItem: (id: string) => void;
  updateAllowed: boolean;
  deleteAllowed: boolean;
}

const ContractList = ({
  contracts = [],
  handleCurrentItem,
  updateAllowed,
  deleteAllowed,
}: Props) => {
  if (contracts.length === 0) return <h4>Não há contratos cadastrados.</h4>;

  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>Nome</div>
          <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
        </li>

        {contracts.map((item) => (
          <li className={styles.tableRow} key={item.idContract}>
            <div className={`${styles.col} ${styles.col1}`} data-label="Id">
              {item.idContract}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
              <a href={`/contrato/${item.idContract}`}>{item.name}</a>
            </div>
            <div className={`${styles.col} ${styles.col4}`} data-label="Ações">
              {updateAllowed && (
                <a
                  href={`/contrato/${item.idContract}`}
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                </a>
              )}
              {deleteAllowed && (
                <button
                  className="btn btn-danger"
                  onClick={() => handleCurrentItem(item.idContract)}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContractList;
