import { Cashier, getCashierData } from '../../shared/@types/cashier';
import { requestService } from '../../utils/requestService';

export const requestCashiersData = async (
  access_token: string, offset: number, page: number, full_list?: boolean
): Promise<getCashierData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/cashier?page=${page}`;

  if(full_list === true){
    url = url + '&per_page=500';
  }

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postCashier = async (access_token: string, cashier: Cashier): Promise<getCashierData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/cashier`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, cashier, headers, 'POST', false);
    return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/cashier?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putCashier = async (access_token: string, cashier: Cashier, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/cashier/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, cashier, headers, 'PUT', false);
    return response;
};

export const requestCashierData = async (
  access_token: string,
  id: string,
): Promise<Cashier> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/cashier?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteCashier = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/cashier/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

