import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../cashier/cashierReducer';
import { RootState } from '../reducers';
import * as cashierAction from '../cashier/cashierAction';
import { putCashier, postCashier, requestCashiersData, requestCashierData, requestDeleteCashier, postSearch } from '../../services/cashiers';
import { Cashier } from '../../shared/@types/cashier';

const useCashierState = (): UseState =>
  useSelector((rootState: RootState) => rootState.cashiers);

export const useCashiers = () => {
  const { cashiers, cashier_pagination } = useCashierState();
  const data = { cashiers, cashier_pagination }
  return data;
};

export const useGetCashiers = () => {
  const dispatch = useDispatch();
  
  return useCallback(async (access_token: string, page: number, full_list?: boolean) => {
    const data = await requestCashiersData(access_token, 0, page, full_list);
    dispatch(cashierAction.getAllCashiers(data));
  }, [dispatch]);
};

export const useGetCashier = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestCashierData(access_token, id);
    return data;
  }, []);
};

export const useDeleteCashier = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteCashier(access_token, id);
    return data;
  }, [])
};

export const usePostCashier = () => {
  return useCallback(
    async (access_token: string, formCashierBody: Cashier) => {
      const createdCashier = await postCashier(access_token, formCashierBody);
      return createdCashier;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(cashierAction.getCashiersSearch(searchResult));
    },
    [dispatch],
  );
};


export const usePutCashier = () => {
  return useCallback(
    async (access_token: string, formCashierBody: Cashier, id: string) => {
      const createdCashier = await putCashier(access_token, formCashierBody, id);
      return createdCashier;
    },
    [],
  );
};
