import { getReportData } from '../../shared/@types/report';
import { PaginationProps } from '../../shared/@types/report';
import * as reportAction from './reportAction';

export interface State {
  report?: getReportData;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  rubric_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  report: undefined,
  queryParam: undefined,
  loading: true,
};

export type Actions =
  | reportAction.GetAllReport

export const reportReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case reportAction.GET_ALL_REPORT: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        report: data,
        report_pagination: pagination,
      };
    }
    default:
      return state;
  }
};
