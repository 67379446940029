import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Container, Spinner, Form, Row, Col, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import Select from "react-select";
import * as yup from "yup";
// import Pagination from "react-js-pagination";

import Admin from "../../shared/components/Admin";
import {
  useHideLoading,
  useLoading,
  useShowLoading,
} from "../../store/hooks/loadingHooks";
import {
  useGetReleases,
  useReleases,
  useDeleteRelease,
  usePostSearch,
} from "../../store/hooks/releaseHooks";
import ReleaseList from "./List/ReleaseList";
import ModalDelete from "../../shared/components/ModalDelete";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import { useGetProjects, useProjects } from "../../store/hooks/projectHooks";
import { useGetProviders, useProviders } from "../../store/hooks/providerHooks";
import {
  useGetLedgerByLevel,
} from "../../store/hooks/rubricHooks";
import { releaseTypes } from "../../shared/constants";

import styles from "../styles.module.scss";
import { Release } from "../../shared/@types/release";
import { usePermission } from "../../store/hooks/permissionHooks";
import CurrencyInput from "react-currency-input-field";
import { currencyToNumber } from "../../utils/formUtils";

const releaseSchema = yup.object().shape({
  idProject: yup.string(),
  idSubLedger: yup.string(),
  initialDate: yup.string(),
  finalDate: yup.string(),
  releaseType: yup.string(),
});

const Index = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const getReleases = useGetReleases();
  const releasesData = useReleases();
  const deleteRelease = useDeleteRelease();
  const postSearch = usePostSearch();
  const isLoading = useLoading();
  const showLoading = useShowLoading();
  const hideLoading = useHideLoading();

  const [redirect, setRedirect] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const getProjects = useGetProjects();
  const projectsData = useProjects();
  const getProviders = useGetProviders();
  const providersData = useProviders();

  const getLedgerLevel = useGetLedgerByLevel();

  const [rubricLevelOne, setRubricLevelOne] = useState<any>([]);
  const [rubricLevelTwo, setRubricLevelTwo] = useState<any>([]);
  const [rubricLevelThree, setRubricLevelThree] = useState<any>([]);

  const [redirectHome, setRedirectHome] = useState(false);
  const [updateAllowed, setUpdateAllowed] = useState(false);
  const [deleteAllowed, setDeleteAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "release_index_get",
      "release_index_put",
      "release_index_delete",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (!access.includes("release_index_get")) {
        toast.error("Você não tem acesso a lista de lançamentos.");
        setRedirectHome(true);
      }
      if (access.includes("release_index_put")) setUpdateAllowed(true);
      if (access.includes("release_index_delete")) setDeleteAllowed(true);
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      idProject: "",
      releaseType: "",
      initialDate: "",
      finalDate: "",
      docInitialDate: "",
      docFinalDate: "",
      value: 0,
      idProvider: "",
      L1: "",
      L2: "",
      L3: "",
    },
    validationSchema: releaseSchema,
    onSubmit: (values: Partial<Release>) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  const handleSubmit = (values: any) => {
    // if (values.search === '') return;

    if (values.value) values.value = currencyToNumber(values.value);

    const result = postSearch(access_token, values, 1);

    toast.promise(result, {
      loading: "Buscando lançamento...",
      success: (result: any) => {
        return "Lançamentos encontrados!";
      },
      error: (error) => error.message,
    });
  };

  useEffect(() => {
    getCurrentItem("releases");
    const fetchReleases = async () => {
      try {
        let result: any = getReleases(access_token, 1);

        toast.promise(result, {
          loading: "Carregando lançamentos...",
          success: () => {
            return "Lista de lançamentos carregado!";
          },
          error: (error) => {
            if (
              error &&
              error.response &&
              error.response.status &&
              (error.response.status === 401 || error.response.status === 403)
            ) {
              setRedirect(true);
              return error.response.data.error;
            }
            return error.response.data.error;
          },
        });
      } catch (error: any) {
        if (
          error &&
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setRedirect(true);
          return error.response.data.error;
        }
      } finally {
        hideLoading();
      }
    };

    fetchReleases();
  }, [getReleases, access_token, showLoading, hideLoading, getCurrentItem]);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleCurrentItem = (id: string) => {
    setModal(!modal);
    setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteRelease(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo lançamento...",
      success: () => {
        getReleases(access_token, 1);
        setModal(!modal);
        return "Lançamento excluído.";
      },
      error: (error) => error.message,
    });
  };

  const getRubricLevel = async (level: string, idRootLedger?: string) => {
    let responseRubric = await getLedgerLevel(
      access_token,
      level,
      idRootLedger
    );

    if (level === "L1") {
      setRubricLevelOne(responseRubric && responseRubric.data);
    }

    if (level === "L2") {
      setRubricLevelTwo(responseRubric && responseRubric.data);
    }

    if (level === "L3") {
      setRubricLevelThree(responseRubric && responseRubric.data);
    }

    return responseRubric;
  };

  useEffect(() => {
    getCurrentItem("releases");
    const getInfo = async () => {
      getProjects(access_token, 1, true);
      getProviders(access_token, 1, true);
      getRubricLevel("L1");
    };

    getInfo();
    // eslint-disable-next-line
  }, [
    // setFieldValue,
    // access_token,
    // getCurrentItem,
    // getProjects,
    // getProviders,
    // values.idProject,
  ]);

  useEffect(() => {
    setFieldValue("L2", "");
    setFieldValue("L3", "");
    if (values.L1 && values.L1 !== "") {
      getRubricLevel("L2", values.L1);
    }
    // eslint-disable-next-line
  }, [values.L1, access_token]);

  useEffect(() => {
    setFieldValue("L3", "");
    if (values.L2 && values.L2 !== "") {
      getRubricLevel("L3", values.L2);
    }
    // eslint-disable-next-line
  }, [values.L2, access_token]);

  if (redirectHome) {
    return (
      <Redirect
        to={{
          pathname: `/home`,
        }}
      />
    );
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
        }}
      />
    );
  }

  return (
    <Admin>
      {isLoading && (
        <Container>
          <Spinner animation="border" variant="primary" />
        </Container>
      )}
      {modal && (
        <ModalDelete
          handleModal={handleModal}
          handleDelete={handleDelete}
          modal={modal}
        />
      )}

      <div className={styles.headerInfo}>
        <h2>Lançamentos</h2>
        <a
          href={`/adicionar-lancamento`}
          className="btn btn-primary default-button"
        >
          Adicionar
        </a>
      </div>
      <hr className={styles.hr} />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {/* <Search
            handleChange={handleChange}
            values={values}
            placeholder="Digite a descrição do lançamento..."
          /> */}
          <Row>
            <Col md={12}>
              <h4>Buscar</h4>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={6}>
              <Form.Label htmlFor="idProject">Projeto</Form.Label>
              <Select
                placeholder="Selecione um projeto"
                noOptionsMessage={() => "Não encontrado"}
                name="idProject"
                onChange={(option: any) =>
                  setFieldValue("idProject", option.value ? option.value : "")
                }
                value={
                  projectsData &&
                  projectsData.projects &&
                  projectsData.projects
                    .map((project: any) => ({
                      label: project.name,
                      value: project.idProject,
                    }))
                    .find((option: any) => option.value === values.idBound)
                }
                options={
                  projectsData &&
                  projectsData.projects &&
                  projectsData.projects.map((project: any) => ({
                    label: project.name,
                    value: project.idProject,
                  }))
                }
              />
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="releaseType">Tipo</Form.Label>
              <Select
                placeholder="Selecione um tipo"
                noOptionsMessage={() => "Não encontrado"}
                name="releaseType"
                onChange={(option: any) =>
                  setFieldValue("releaseType", option.value ? option.value : "")
                }
                value={
                  releaseTypes &&
                  releaseTypes
                    .map((type: any) => ({
                      label: type.label,
                      value: type.value,
                    }))
                    .find((option: any) => option.value === values.releaseType)
                }
                options={
                  releaseTypes &&
                  releaseTypes.map((type: any) => ({
                    label: type.label,
                    value: type.value,
                  }))
                }
              />
              {touched.releaseType && errors.releaseType && (
                <Form.Text className={styles.textError}>
                  {errors.releaseType}
                </Form.Text>
              )}
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data inicial</Form.Label>
                <Form.Control
                  id="initialDate"
                  name="initialDate"
                  type="date"
                  onChange={handleChange}
                  value={values.initialDate}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data final</Form.Label>
                <Form.Control
                  id="finalDate"
                  name="finalDate"
                  type="date"
                  onChange={handleChange}
                  value={values.finalDate}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data inicial do documento</Form.Label>
                <Form.Control
                  id="docInitialDate"
                  name="docInitialDate"
                  type="date"
                  onChange={handleChange}
                  value={values.docInitialDate}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data final do documento</Form.Label>
                <Form.Control
                  id="docFinalDate"
                  name="docFinalDate"
                  type="date"
                  onChange={handleChange}
                  value={values.docFinalDate}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Valor</Form.Label>
                <CurrencyInput
                  intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  className={styles.valueInput}
                  id="value"
                  name="value"
                  placeholder="Digite um número"
                  defaultValue={values.value}
                  value={values.value}
                  decimalsLimit={2}
                  onValueChange={(value: any, name: any) => {
                    setFieldValue("value", value ? value : "");
                  }}
                />

                {touched.value && errors.value && (
                  <Form.Text className={styles.textError}>
                    {errors.value}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Label htmlFor="idProvider">Fornecedor</Form.Label>
              <Select
                placeholder="Selecione um fornecedor"
                noOptionsMessage={() => "Não encontrado"}
                name="idProvider"
                onChange={(option: any) =>
                  setFieldValue("idProvider", option.value ? option.value : "")
                }
                value={
                  providersData &&
                  providersData.providers &&
                  providersData.providers
                    .map((provider: any) => ({
                      label: provider.name,
                      value: provider.idProvider,
                    }))
                    .find((option: any) => option.value === values.idProvider)
                }
                options={
                  providersData &&
                  providersData.providers &&
                  providersData.providers.map((provider: any) => ({
                    label: provider.name,
                    value: provider.idProvider,
                  }))
                }
              />
            </Col>

            <Col md={6}>
              <Form.Label htmlFor="L1">Rubrica</Form.Label>
              <Select
                placeholder="Selecione uma rubrica"
                noOptionsMessage={() => "Não encontrado"}
                name="L1"
                onChange={(option: any) =>
                  setFieldValue("L1", option.value ? option.value : "")
                }
                value={
                  rubricLevelOne &&
                  rubricLevelOne.length > 0 &&
                  rubricLevelOne
                    .map((rubric: any) => ({
                      label: rubric.ledgerName,
                      value: rubric.idLedger,
                    }))
                    .find((option: any) => option.value === values.L1)
                }
                options={
                  rubricLevelOne &&
                  rubricLevelOne.length > 0 &&
                  rubricLevelOne.map((rubric: any) => ({
                    label: rubric.ledgerName,
                    value: rubric.idLedger,
                  }))
                }
              />
            </Col>

            {values.L1 && values.L1 !== "" && (
              <Col md={6}>
                <Form.Label htmlFor="L2">Rubrica - Nível 2</Form.Label>
                <Select
                  placeholder="Selecione uma opção"
                  noOptionsMessage={() => "Não encontrado"}
                  name="L2"
                  onChange={(option: any) =>
                    setFieldValue("L2", option.value ? option.value : "")
                  }
                  value={
                    rubricLevelTwo &&
                    rubricLevelTwo.length > 0 &&
                    rubricLevelTwo
                      .map((rubric: any) => ({
                        label: rubric.ledgerName,
                        value: rubric.idLedger,
                      }))
                      .find((option: any) => option.value === values.L2)
                  }
                  options={
                    rubricLevelTwo &&
                    rubricLevelTwo.length > 0 &&
                    rubricLevelTwo.map((rubric: any) => ({
                      label: rubric.ledgerName,
                      value: rubric.idLedger,
                    }))
                  }
                />
              </Col>
            )}

            {values.L2 && values.L2 !== "" && (
              <Col md={6}>
                <Form.Label htmlFor="L3">Rubrica - Nível 3</Form.Label>
                <Select
                  placeholder="Selecione uma opção"
                  noOptionsMessage={() => "Não encontrado"}
                  name="L3"
                  onChange={(option: any) =>
                    setFieldValue("L3", option.value ? option.value : "")
                  }
                  value={
                    rubricLevelThree &&
                    rubricLevelThree.length > 0 &&
                    rubricLevelThree
                      .map((subrubric: any) => ({
                        label: subrubric.ledgerName,
                        value: subrubric.idLedger,
                      }))
                      .find((option: any) => option.value === values.L3)
                  }
                  options={
                    rubricLevelThree &&
                    rubricLevelThree.length > 0 &&
                    rubricLevelThree.map((subrubric: any) => ({
                      label: subrubric.ledgerName,
                      value: subrubric.idLedger,
                    }))
                  }
                />
              </Col>
            )}

            <Col md={12} className="button-save">
              <Form.Group>
                <Button type="submit">Buscar</Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </FormikProvider>
      <ReleaseList
        releases={releasesData.releases}
        handleCurrentItem={handleCurrentItem}
        updateAllowed={updateAllowed}
        deleteAllowed={deleteAllowed}
      />
    </Admin>
  );
};

export default Index;
