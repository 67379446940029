import { getProviderData, Provider } from '../../shared/@types/provider';

export const GET_PROVIDER = 'GET_PROVIDER';
export const GET_ALL_PROVIDERS = 'GET_ALL_PROVIDERS';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_PROVIDERS_SEARCH = 'GET_PROVIDERS_SEARCH';

export const getProvider = (provider: Provider) => ({
  type: GET_PROVIDER,
  payload: {
    provider,
  },
});

export interface GetProvider {
  type: typeof GET_PROVIDER;
  payload: { provider: Provider };
}

export const getAllProviders = (providers: getProviderData) => ({
  type: GET_ALL_PROVIDERS,
  payload: {
    data: providers.data,
    pagination: providers.pagination,
  },
});

export interface GetAllProviders {
  type: typeof GET_ALL_PROVIDERS;
  payload: {
    data: getProviderData;
    pagination: Partial<getProviderData>
  };
}

export const getProvidersSearch = (providers: getProviderData) => ({
  type: GET_PROVIDERS_SEARCH,
  payload: {
    data: providers.data,
    pagination: providers.pagination,
  },
});

export interface GetProvidersSearch {
  type: typeof GET_PROVIDERS_SEARCH;
  payload: {
    data: getProviderData;
    pagination: Partial<getProviderData>
  };
}

export const updateProvider = (provider: Provider) => ({
  type: UPDATE_PROVIDER,
  payload: {
    provider,
  },
});

export interface UpdateProvider {
  type: typeof UPDATE_PROVIDER;
  payload: {
    provider: Provider;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
