import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../../utils/formUtils";

import styles from "./styles.module.scss";

interface ReportProps {
  reportType: string;
  idReport: string;
  projectName: string;
  cashierName: string;
  generateDate: string;
  downloadPath: string;
}

interface Props {
  report: ReportProps[] | undefined;
  token: string;
  handleCurrentItem: (id: string) => void;
  reportOptions: any;
}

const ReportList = ({
  report = [],
  token,
  handleCurrentItem,
  reportOptions,
}: Props) => {
  const getType = (type: string) => {
    switch (type) {
      case "ACTIVITY":
        return "Atividade";
      case "PROJECT_PDF":
        return "Projeto";
      case "PROJECT_RESUME_PDF":
        return "Projeto - Resumo";
      case "CASHIER_PDF":
        return "Caixa";
      default:
        break;
    }

    return "-";
  };

  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>id</div>
          <div className={`${styles.col} ${styles.col1}`}>Tipo</div>
          <div className={`${styles.col} ${styles.col2}`}>Data</div>
          <div className={`${styles.col} ${styles.col3}`}>Relatório</div>
          <div className={`${styles.col} ${styles.col4}`}>Ações</div>
        </li>

        {report.map((item, index) => (
          <li className={styles.tableRow} key={index}>
          <div className={`${styles.col} ${styles.col1}`} data-label="id">
            {item.idReport}
          </div>
            <div className={`${styles.col} ${styles.col1}`} data-label="Tipo">
              {getType(item.reportType)}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Data">
              {formatDate(item.generateDate)}
            </div>
            <div
              className={`${styles.col} ${styles.col3}`}
              data-label="Relatório"
            >
              {item.projectName ? item.projectName : ""}
              {item.cashierName ? item.cashierName : ""}
            </div>
            <div className={`${styles.col} ${styles.col4}`} data-label="Ações">
              {reportOptions.report_cashierpdf_get && item.downloadPath && (
                <a
                  href={`${process.env.REACT_APP_ADPASE_BACK_URL}/report/viewer/${item.idReport}?auth=${token}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Abrir
                </a>
              )}
              {reportOptions.report_index_delete && (
                <button
                  className="btn btn-danger"
                  onClick={() => handleCurrentItem(item.idReport)}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>

      {/* <button onClick={() => downloadPdf()}>Teste</button> */}
      {/* <div>
        <PDFDownloadLink document={<MyDoc />} fileName="relatorio.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download now!"
          }
        </PDFDownloadLink>
      </div> */}
    </div>
  );
};

export default ReportList;
