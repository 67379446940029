import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../rubric/rubricReducer';
import { RootState } from '../reducers';
import * as rubricAction from '../rubric/rubricAction';
import { putRubric, postRubric, requestRubricsData, requestRubricData, requestLedgerLevelData, requestDeleteRubric, postSearch } from '../../services/rubrics';
import { Rubric } from '../../shared/@types/rubric';

const useRubricState = (): UseState =>
  useSelector((rootState: RootState) => rootState.rubrics);

export const useRubrics = () => {
  const { rubrics, rubric_pagination } = useRubricState();
  const data = { rubrics, rubric_pagination }
  return data;
};

export const useGetRubrics = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, page: number, full_list?: boolean) => {
    const data = await requestRubricsData(access_token, 0 , page, full_list);
    dispatch(rubricAction.getAllRubrics(data));
  }, [dispatch]);
};

export const useGetRubric = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestRubricData(access_token, id);
    return data;
  }, []);
};

// export const useGetLedgerLevel = () => {
//   return useCallback(async (access_token: string, ledgerLevel: string) => {
//     const data = await requestLedgerLevelData(access_token, ledgerLevel);
//     return data;
//   }, []);
// };

export const useGetLedgerLevel = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, ledgerLevel: string) => {
    const data = await requestLedgerLevelData(access_token, ledgerLevel);
    dispatch(rubricAction.getAllRubrics(data));
  }, [dispatch]);
};

export const useGetLedgerByLevel = () => {
  return useCallback(async (access_token: string, ledgerLevel: string, idRootLedger? : string) => {
    const data = await requestLedgerLevelData(access_token, ledgerLevel, idRootLedger);
    return data;
  }, []);
};


export const useDeleteRubric = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteRubric(access_token, id);
    return data;
  }, [])
};

export const usePostRubric = () => {
  return useCallback(
    async (access_token: string, formRubricBody: Rubric) => {
      const createdRubric = await postRubric(access_token, formRubricBody);
      return createdRubric;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(rubricAction.getRubricsSearch(searchResult));
    },
    [dispatch],
  );
};

export const usePutRubric = () => {
  return useCallback(
    async (access_token: string, formRubricBody: Rubric, id: string) => {
      const createdRubric = await putRubric(access_token, formRubricBody, id);
      return createdRubric;
    },
    [],
  );
};
