import { getReleaseData, Release } from '../../shared/@types/release';

export const GET_RELEASE = 'GET_RELEASE';
export const GET_ALL_RELEASE = 'GET_ALL_RELEASE';
export const UPDATE_RELEASE = 'UPDATE_RELEASE';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_RELEASES_SEARCH = 'GET_RELEASES_SEARCH';

export const getRelease = (release: Release) => ({
  type: GET_RELEASE,
  payload: {
    release,
  },
});

export interface GetRelease {
  type: typeof GET_RELEASE;
  payload: { release: Release };
}

export const getAllReleases = (releases: getReleaseData) => ({
  type: GET_ALL_RELEASE,
  payload: {
    data: releases.data,
    pagination: releases.pagination,
  },
});

export interface GetAllReleases {
  type: typeof GET_ALL_RELEASE;
  payload: {
    data: getReleaseData;
    pagination: Partial<getReleaseData>
  };
}

export const getReleasesSearch = (releases: getReleaseData) => ({
  type: GET_RELEASES_SEARCH,
  payload: {
    data: releases.data,
    pagination: releases.pagination,
  },
});

export interface GetReleasesSearch {
  type: typeof GET_RELEASES_SEARCH;
  payload: {
    data: getReleaseData;
    pagination: Partial<getReleaseData>
  };
}

export const updateRelease = (release: Release) => ({
  type: UPDATE_RELEASE,
  payload: {
    release,
  },
});

export interface UpdateRelease {
  type: typeof UPDATE_RELEASE;
  payload: {
    release: Release;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
