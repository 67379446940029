import { useEffect, useState } from "react";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";

import Admin from "../../shared/components/Admin";
import File from "../../shared/components/File";
import { Contract } from "../../shared/@types/contract";
import {
  usePostContract,
  usePutContract,
  useGetContract,
} from "../../store/hooks/contractHooks";
import { useGetProjects, useProjects } from "../../store/hooks/projectHooks";
import styles from "../styles.module.scss";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import { useSelector } from "react-redux";
import { stateProps } from "../../shared/@types/general";
import { REQUIRED } from "../../utils/formUtils";
import { Project } from "../../shared/@types/project";
import { usePermission } from "../../store/hooks/permissionHooks";

const contractSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
  description: yup.string(),
  initialDate: yup.string().required(REQUIRED),
  finalDate: yup.string(),
  type: yup.string().required(REQUIRED),
  idProject: yup.string().required(REQUIRED),
});

interface ParamTypes {
  id: string;
}

const ContractManagement = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const { id } = useParams<ParamTypes>();
  const postContract = usePostContract();
  const putContract = usePutContract();
  const getContract = useGetContract();
  const getProjects = useGetProjects();
  const projectsData = useProjects();

  const [redirect, setRedirect] = useState(false);

  const [saveAllowed, setSaveAllowed] = useState(false);
  const [saveContractFileAllowed, setSaveContractFileAllowed] = useState(false);
  const [deleteContractFileAllowed, setDeleteContractFileAllowed] =
    useState(false);
  const [listContractFileAllowed, setListContractFileAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "contract_index_post",
      "contract_index_put",
      "contract_index_get_one",
      "contractfile_index_post",
      "contractfile_index_get",
      "contractfile_index_delete",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (id && access.includes("contractfile_index_post"))
        setSaveContractFileAllowed(true);
      if (id && access.includes("contractfile_index_delete"))
        setDeleteContractFileAllowed(true);
      if (id && access.includes("contractfile_index_get"))
        setListContractFileAllowed(true);

      if (id && access.includes("contract_index_get_one")) setSaveAllowed(true);
      if (!id && access.includes("contract_index_post")) setSaveAllowed(true);
      if (
        id &&
        access.includes("contract_index_get_one") &&
        access.includes("contract_index_put")
      )
        setSaveAllowed(true);
      if (
        id &&
        access.includes("contract_index_get_one") &&
        !access.includes("contract_index_put")
      )
        setSaveAllowed(false);

      if (!access.includes("contract_index_get_one") && id) {
        toast.error("Você não tem acesso para visualizar/editar um contrato.");
        setRedirect(true);
      }
      if (!access.includes("contract_index_post") && !id) {
        toast.error("Você não tem acesso para cadastrar um contrato.");
        setRedirect(true);
      }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (values: Contract) => {
    let result: any;

    if (id) {
      result = putContract(access_token, values, id);
    } else {
      result = postContract(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando contrato...",
      success: () => {
        setRedirect(true);
        return "Contrato cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      initialDate: "",
      finalDate: "",
      type: "CONTRACT",
      idProject: "",
    },
    validationSchema: contractSchema,
    onSubmit: (values: Contract) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getProjects(access_token, 1, true);

    getCurrentItem("contracts");
    const getInfo = async () => {
      if (id) {
        const result: Contract = await getContract(access_token, id);
        if (result) {
          setFieldValue("name", result.name);
          setFieldValue("description", result.description);
          setFieldValue("initialDate", result.initialDate);
          setFieldValue("finalDate", result.finalDate);
          setFieldValue("type", result.type);
          setFieldValue("idProject", result.idProject);
        }
      }
    };

    getInfo();
  }, [
    id,
    getContract,
    setFieldValue,
    getCurrentItem,
    access_token,
    getProjects,
  ]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/contratos`,
        }}
      />
    );
  }

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar Contrato" : "Cadastrar Contrato"}</h3>
            </Col>
          </Row>
          <Row className=" align-items-end">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <Form.Text className={styles.textError}>
                    {errors.name}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  id="description"
                  name="description"
                  as="textarea"
                  rows={3}
                  onChange={handleChange}
                  value={values.description}
                />
                {touched.description && errors.description && (
                  <Form.Text className={styles.textError}>
                    {errors.description}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data inicial</Form.Label>
                <Form.Control
                  id="initialDate"
                  name="initialDate"
                  type="date"
                  onChange={handleChange}
                  value={values.initialDate}
                />

                {touched.initialDate && errors.initialDate && (
                  <Form.Text className={styles.textError}>
                    {errors.initialDate}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data final</Form.Label>
                <Form.Control
                  id="finalDate"
                  name="finalDate"
                  type="date"
                  onChange={handleChange}
                  value={values.finalDate}
                />

                {touched.finalDate && errors.finalDate && (
                  <Form.Text className={styles.textError}>
                    {errors.finalDate}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Label htmlFor="type">Tipo</Form.Label>
              <Form.Select
                name="type"
                id="type"
                value={values.type}
                onChange={handleChange}
                style={{ margin: 0 }}
              >
                <option value="">Escolha sua opção</option>
                <option value="ADDITIVE">Aditivo</option>
                <option value="CONTRACT">Contrato</option>
              </Form.Select>
              {touched.type && errors.type && (
                <Form.Text className={styles.textError}>
                  {errors.type}
                </Form.Text>
              )}
            </Col>

            <Col md={6}>
              <Form.Label htmlFor="idProject">Projeto</Form.Label>
              <Select
                placeholder="Selecione uma opção"
                noOptionsMessage={() => "Não encontrado"}
                name="idProject"
                onChange={(option: any) =>
                  setFieldValue("idProject", option.value ? option.value : "")
                }
                value={
                  projectsData &&
                  projectsData.projects &&
                  projectsData.projects
                    .map((project: Project) => ({
                      label: project.name,
                      value: project.idProject,
                    }))
                    .find(
                      (option: OptionValue) => option.value === values.idProject
                    )
                }
                options={
                  projectsData &&
                  projectsData.projects &&
                  projectsData.projects.map((project: Project) => ({
                    label: project.name,
                    value: project.idProject,
                  }))
                }
              />
            </Col>

            {saveAllowed && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit">SALVAR</Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
      <hr style={{ marginTop: 30, marginBottom: 50 }} />
      {id && (
        <File
          origin="Contract"
          id={id}
          showSaveButton={saveContractFileAllowed}
          showDeleteButton={deleteContractFileAllowed}
          showList={listContractFileAllowed}
        />
      )}
    </Admin>
  );
};

export default ContractManagement;
