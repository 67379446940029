import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";

import Admin from "../../shared/components/Admin";
import { Rubric } from "../../shared/@types/rubric";
import {
  usePostRubric,
  usePutRubric,
  useGetRubric,
  useGetLedgerLevel,
  useRubrics,
} from "../../store/hooks/rubricHooks";
import styles from "../styles.module.scss";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import { REQUIRED } from "../../utils/formUtils";
import { usePermission } from "../../store/hooks/permissionHooks";

const rubricSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
});

interface ParamTypes {
  id: string;
}

const RubricManagement = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const { id } = useParams<ParamTypes>();
  const postRubric = usePostRubric();
  const putRubric = usePutRubric();
  const getRubric = useGetRubric();
  const getLedgerLevel = useGetLedgerLevel();

  const rubricsData = useRubrics();

  const [redirect, setRedirect] = useState(false);

  const [saveAllowed, setSaveAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "ledger_index_post",
      "ledger_index_put",
      "ledger_index_get_one",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (id && access.includes("ledger_index_get_one")) setSaveAllowed(true);
      if (!id && access.includes("ledger_index_post")) setSaveAllowed(true);
      if (
        id &&
        access.includes("ledger_index_get_one") &&
        access.includes("ledger_index_put")
      )
        setSaveAllowed(true);
      if (
        id &&
        access.includes("ledger_index_get_one") &&
        !access.includes("ledger_index_put")
      )
        setSaveAllowed(false);

      if (!access.includes("ledger_index_get_one") && id) {
        toast.error("Você não tem acesso para visualizar/editar uma rúbrica.");
        setRedirect(true);
      }
      if (!access.includes("ledger_index_post") && !id) {
        toast.error("Você não tem acesso para cadastrar uma rúbrica.");
        setRedirect(true);
      }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (values: Rubric) => {
    let result: any;

    if (values.ledgerLevel === "L1") {
      delete values.rootLedger;
    }

    if (id) {
      result = putRubric(access_token, values, id);
    } else {
      result = postRubric(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando rubrica...",
      success: () => {
        setRedirect(true);
        return "Rubrica cadastrada!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      ledgerLevel: "L1",
      rootLedger: "",
    },
    validationSchema: rubricSchema,
    onSubmit: (values: Rubric) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getCurrentItem("rubrics");
    const getInfo = async () => {
      if (id) {
        const result: any = await getRubric(access_token, id);
        if (result) {
          setFieldValue("name", result.ledgerName);
          setFieldValue("ledgerLevel", result.ledgerLevel);
          setFieldValue("rootLedger", result.idRootLedger);
        }
      }
    };

    getInfo();
  }, [id, getRubric, setFieldValue, access_token, getCurrentItem]);

  useEffect(() => {
    if (values.ledgerLevel === "L2" || values.ledgerLevel === "L3") {
      let level = "";

      switch (values.ledgerLevel) {
        case "L2":
          level = "L1";
          break;
        case "L3":
          level = "L2";
          break;
        default:
          return;
      }

      getLedgerLevel(access_token, level);
    }
  }, [values.ledgerLevel, getLedgerLevel, access_token]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/rubricas`,
        }}
      />
    );
  }

  const subRubricLevel =
    values.ledgerLevel === "L2" || values.ledgerLevel === "L3" ? true : false;

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar Rubrica" : "Cadastrar Rubrica"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <Form.Text className={styles.textError}>
                    {errors.name}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="ledgerLevel">Nível de rúbrica</Form.Label>
              <Form.Select
                name="ledgerLevel"
                id="ledgerLevel"
                onChange={handleChange}
                value={values.ledgerLevel}
                style={{ margin: 0 }}
              >
                <option value="">Escolha sua opção</option>
                <option value="L1">Nivel 1</option>
                <option value="L2">Nivel 2</option>
                <option value="L3">Nivel 3</option>
              </Form.Select>
            </Col>

            {subRubricLevel && Array.isArray(rubricsData.rubrics) && (
              <Col md={6}>
                <Form.Label htmlFor="idLedger">Rubrica</Form.Label>
                <Select
                  placeholder="Selecione uma opção"
                  noOptionsMessage={() => "Não encontrado"}
                  name="idLedger"
                  onChange={(option: any) =>
                    setFieldValue(
                      "rootLedger",
                      option.value ? option.value : ""
                    )
                  }
                  value={
                    rubricsData &&
                    rubricsData.rubrics &&
                    rubricsData.rubrics
                      .map((rubric: any) => ({
                        label: rubric.ledgerName,
                        value: rubric.idLedger,
                      }))
                      .find((option: any) => option.value === values.rootLedger)
                  }
                  options={
                    rubricsData &&
                    rubricsData.rubrics &&
                    rubricsData.rubrics.map((rubric: any) => ({
                      label: rubric.ledgerName,
                      value: rubric.idLedger,
                    }))
                  }
                />
              </Col>
            )}

            {saveAllowed && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit">SALVAR</Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
    </Admin>
  );
};

export default RubricManagement;
