import {
    User,
    getUserData,
    UserReturnLogin,
  } from '../../shared/@types/user';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER = 'GET_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_USERS_SEARCH = 'GET_USERS_SEARCH';


export type GetUser = {
  type: typeof GET_USER;
  payload: { user: User };
};

export const getUser = (user: getUserData) => ({
    type: GET_USER,
    payload: {
      user,
    },
  });

export const getAllUsers = (users: getUserData) => ({
  type: GET_ALL_USERS,
  payload: {
    data: users.data,
    pagination: users.pagination,
  },
});

export const getUsersSearch = (users: getUserData) => ({
  type: GET_USERS_SEARCH,
  payload: {
    data: users.data,
    pagination: users.pagination,
  },
});

export interface GetUsersSearch {
  type: typeof GET_USERS_SEARCH;
  payload: {
    data: getUserData;
    pagination: Partial<getUserData>
  };
}



export interface GetAllUsers {
  type: typeof GET_ALL_USERS;
  payload: {
    data: getUserData;
    pagination: Partial<getUserData>
  };
}



export interface LoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: {
    access_token: string;
    user_data: UserReturnLogin;
  };
}

const clearCredentials = () => {
  localStorage.clear();
};

export const logout = () => {
  clearCredentials();
  return {
    type: LOGOUT_USER,
  };
}

export interface LogoutUser {
  type: typeof LOGOUT_USER;
  payload: {
    user: User;
  };
}