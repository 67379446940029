import React from "react";
import styles from "./styles.module.scss";

interface Props {
  handleActivityLock: () => void;
  handleModalLock: () => void;
  modalLock: boolean;
}

export default function Index({ handleActivityLock, handleModalLock, modalLock }: Props) {
  return (
    <>
      <div className={`${styles.modalMask}`}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <h1>Tem certeza que deseja bloquear esta atividade?</h1>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.btnContainer}>
              <button
                className={styles.globalGreyBtn}
                onClick={() => handleModalLock()}
              >
                Cancelar
              </button>
            </div>
            <div className={styles.btnContainer}>
              <button
                className={styles.globalBtn}
                onClick={handleActivityLock}
              >
                Sim
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
