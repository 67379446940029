import { getProjectData, Project } from '../../shared/@types/project';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_ALL_PROJECT = 'GET_ALL_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_PROJECTS_SEARCH = 'GET_PROJECTS_SEARCH';

export const getProject = (project: Project) => ({
  type: GET_PROJECT,
  payload: {
    project,
  },
});

export interface GetProject {
  type: typeof GET_PROJECT;
  payload: { project: Project };
}

export const getAllProjects = (projects: getProjectData) => ({
  type: GET_ALL_PROJECT,
  payload: {
    data: projects.data,
    pagination: projects.pagination,
  },
});

export interface GetAllProjects {
  type: typeof GET_ALL_PROJECT;
  payload: {
    data: getProjectData;
    pagination: Partial<getProjectData>
  };
}

export const getProjectsSearch = (projects: getProjectData) => ({
  type: GET_PROJECTS_SEARCH,
  payload: {
    data: projects.data,
    pagination: projects.pagination,
  },
});

export interface GetProjectsSearch {
  type: typeof GET_PROJECTS_SEARCH;
  payload: {
    data: getProjectData;
    pagination: Partial<getProjectData>
  };
}

export const updateProject = (project: Project) => ({
  type: UPDATE_PROJECT,
  payload: {
    project,
  },
});

export interface UpdateProject {
  type: typeof UPDATE_PROJECT;
  payload: {
    project: Project;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
