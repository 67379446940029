import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../participant/participantReducer';
import { RootState } from '../reducers';
import * as participantAction from '../participant/participantAction';
import { putParticipant, postParticipant, requestParticipantsData, requestParticipantData, requestDeleteParticipant, postSearch, requestParticipantDamsData, postParticipantDam, requestDeleteParticipantDam } from '../../services/participants';
import { Participant, ParticipantDam } from '../../shared/@types/participant';

const useParticipantState = (): UseState =>
  useSelector((rootState: RootState) => rootState.participants);

export const useParticipants = () => {
  const { participants, participant_pagination } = useParticipantState();
  const data = { participants, participant_pagination }
  return data;
};

export const useGetParticipants = () => {
  const dispatch = useDispatch();
  
  return useCallback(async (access_token: string, page: number) => {
    const data = await requestParticipantsData(access_token, 0, page);
    dispatch(participantAction.getAllParticipants(data));
  }, [dispatch]);
};

export const useGetParticipant = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestParticipantData(access_token, id);
    return data;
  }, []);
};

export const useGetParticipantDams = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestParticipantDamsData(access_token, id);
    return data;
  }, []);
};

export const useDeleteParticipantDam = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteParticipantDam(access_token, id);
    return data;
  }, [])
};


export const useDeleteParticipant = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteParticipant(access_token, id);
    return data;
  }, [])
};

export const usePostParticipant = () => {
  return useCallback(
    async (access_token: string, formParticipantBody: Participant) => {
      const createdParticipant = await postParticipant(access_token, formParticipantBody);
      return createdParticipant;
    },
    [],
  );
};

export const usePostParticipantDam = () => {
  return useCallback(
    async (access_token: string, formParticipantBody: ParticipantDam) => {
      const createdParticipant = await postParticipantDam(access_token, formParticipantBody);
      return createdParticipant;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(participantAction.getParticipantsSearch(searchResult));
    },
    [dispatch],
  );
};


export const usePutParticipant = () => {
  return useCallback(
    async (access_token: string, formParticipantBody: Participant, id: string) => {
      const createdParticipant = await putParticipant(access_token, formParticipantBody, id);
      return createdParticipant;
    },
    [],
  );
};
