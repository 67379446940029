import { Project, getProjectData } from '../../shared/@types/project';
import { requestService } from '../../utils/requestService';

export const requestProjectsData = async (
  access_token: string, offset: number, page: number, full_list?: boolean
): Promise<getProjectData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/project?page=${page}`;

  if(full_list === true){
    url = url + '&per_page=500';
  }

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postProject = async (access_token: string, project: Project): Promise<getProjectData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/project`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, project, headers, 'POST', false);
    return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/project?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putProject = async (access_token: string, project: Project, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/project/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, project, headers, 'PUT', false);
    return response;
};

export const requestProjectData = async (
  access_token: string, 
  id: string,
): Promise<Project> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/project?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteProject= async (
  access_token: string, 
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/project/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

