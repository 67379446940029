import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import jwt from 'jwt-decode' 

import { State as UseState } from '../user/userReducer';
import { RootState } from '../reducers';
import * as userAction from '../user/userAction';
import { loginUser, postUser, postSearch, putUser, requestUsersData, requestUserData, requestDeleteUser, putPassword, resetPassword, requestPermissions } from '../../services/users';
import { User, UserUpdatePassword } from '../../shared/@types/user';

const useUserState = (): UseState =>
  useSelector((rootState: RootState) => rootState.auth);

export const useUsers = () => {
  const { users, user_pagination } = useUserState();
  const data = { users, user_pagination }
  return data;
};

export const useGetUsers = () => {
  const dispatch = useDispatch();

  return useCallback(async (access_token: string, page: number) => {
    const data = await requestUsersData(access_token, page);
    dispatch(userAction.getAllUsers(data));
  }, [dispatch]);
};

export const useGetPermissions = () => {

  return useCallback(async (access_token: string, page: number) => {
    const data = await requestPermissions(access_token, page);
    return data;
  }, []);
};

export const usePostUser = () => {
  return useCallback(
    async (access_token: string, formUserBody: User) => {
      const createdUser = await postUser(access_token, formUserBody);
      return createdUser;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string, page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(userAction.getUsersSearch(searchResult));
    },
    [dispatch],
  );
};


// export const useNewPasswordUser = () => {
//   const dispatch = useDispatch();
//   return useCallback(
//     async (formUserBody: UserNewPassword) => {
//       const data = await newPasswordUser(formUserBody);
//       return data;
//     },
//     [dispatch],
//   );
// };

export const useLoginUser = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (formUserBody: Partial<User>) => {
      const data = await loginUser(formUserBody);

      if(data) {

        const { access_token } = data;

        // localStorage.setItem("user", JSON.stringify(user));

        if (access_token) {
          const user = jwt(access_token); // decode your token here
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("access_token", JSON.stringify(access_token));

          dispatch({
            type: userAction.LOGIN_SUCCESS,
            payload: {
              access_token,
              user_data: user,
            },
          });
        }

      }

     
      return data;
    },
    [dispatch],
  );
}

export const useGetUser = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestUserData(access_token, id);
    return data;
  }, []);
};


export const usePutUser = () => {
  return useCallback(
    async (access_token: string, formUserBody: User, id: string) => {
      const createdUser = await putUser(access_token, formUserBody, id);
      return createdUser;
    },
    [],
  );
};

export const usePutPassword = () => {
  return useCallback(
    async (access_token: string, formUserBody: UserUpdatePassword) => {
      const updatedUser = await putPassword(access_token, formUserBody);
      return updatedUser;
    },[],
  );
}

export const useResetUserPassword = () => {
  return useCallback(
    async (access_token: string, id: string) => {
      const updatedUser = await resetPassword(access_token, id);
      return updatedUser;
    },[],
  );
}

export const useDeleteUser = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteUser(access_token, id);
    return data;
  }, [])
};