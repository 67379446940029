import { SubRubric, getSubRubricData } from '../../shared/@types/subrubric';
import { PaginationProps } from '../../shared/@types/subrubric';
import * as subrubricAction from './subrubricAction';

export interface State {
  subrubrics?: getSubRubricData;
  subrubric?: SubRubric;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  subrubric_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  subrubrics: undefined,
  queryParam: undefined,
  subrubric: undefined,
  loading: true,
};

export type Actions =
  | subrubricAction.GetAllSubRubrics
  | subrubricAction.GetSubRubricsSearch
  | subrubricAction.GetSubRubric
  | subrubricAction.SetQueryParam
  | subrubricAction.UpdateSubRubric;

export const subrubricReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case subrubricAction.GET_ALL_SUBRUBRICS: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        subrubrics: data,
        subrubric_pagination: pagination,
      };
    }
    case subrubricAction.GET_SUBRUBRICS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        subrubrics: data,
        subrubric_pagination: pagination,
      };
    }
    case subrubricAction.GET_SUBRUBRIC: {
      const { subrubric } = action.payload;
      return {
        ...state,
        subrubric,
        loading: false,
      };
    }
    case subrubricAction.UPDATE_SUBRUBRIC: {
      const { subrubric } = action.payload;
      return {
        ...state,
        subrubric,
      };
    }
    case subrubricAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
