import { getStateData } from '../../shared/@types/state';

export const GET_ALL_STATES = 'GET_ALL_STATES';

export const getAllStates = (states: getStateData) => ({
  type: GET_ALL_STATES,
  payload: {
    data: states.data,
    pagination: states.pagination,
  },
});

export interface GetAllStates {
  type: typeof GET_ALL_STATES;
  payload: {
    data: getStateData;
    pagination: Partial<getStateData>
  };
}
