export const usePermission = (scopes: string[], userScopes: string[]) => {

    let validatedScopes: any = []
    scopes.map((scope) => {
        if (userScopes.includes(scope)){
            validatedScopes.push(scope)
        }
        return scope
    })
    return validatedScopes
}

