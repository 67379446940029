import * as sidebarAction from './sidebarAction';

export interface State {
    current?: string;
}

const INITIAL_STATE: State = {
    current: undefined
}

export type Actions = sidebarAction.GetCurrentItem;

export const sidebarReducer = (state = INITIAL_STATE, action: Actions) => {
    switch (action.type) {
        case sidebarAction.GET_CURRENT_ITEM: {
            const { current } = action.payload;
            return {
                ...state,
                current
            }
        }
        default: 
            return state;
    }
}