import { getContractData, Contract } from '../../shared/@types/contract';

export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_ALL_CONTRACT = 'GET_ALL_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_CONTRACTS_SEARCH = 'GET_CONTRACTS_SEARCH';

export const getContract = (contract: Contract) => ({
  type: GET_CONTRACT,
  payload: {
    contract,
  },
});

export interface GetContract {
  type: typeof GET_CONTRACT;
  payload: { contract: Contract };
}

export const getAllContracts = (contracts: getContractData) => ({
  type: GET_ALL_CONTRACT,
  payload: {
    data: contracts.data,
    pagination: contracts.pagination,
  },
});

export interface GetAllContracts {
  type: typeof GET_ALL_CONTRACT;
  payload: {
    data: getContractData;
    pagination: Partial<getContractData>
  };
}

export const getContractsSearch = (contracts: getContractData) => ({
  type: GET_CONTRACTS_SEARCH,
  payload: {
    data: contracts.data,
    pagination: contracts.pagination,
  },
});

export interface GetContractsSearch {
  type: typeof GET_CONTRACTS_SEARCH;
  payload: {
    data: getContractData;
    pagination: Partial<getContractData>
  };
}

export const updateContract = (contract: Contract) => ({
  type: UPDATE_CONTRACT,
  payload: {
    contract,
  },
});

export interface UpdateContract {
  type: typeof UPDATE_CONTRACT;
  payload: {
    contract: Contract;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
