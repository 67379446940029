import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  // faTrash,
  faKey,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

interface UserProps {
  idUser: string;
  name: string;
  email: string;
}

interface Props {
  users: UserProps[];
  handleCurrentItem: (id: string) => void;
  handleResetPassword: (id: string) => void;
  updateAllowed: boolean;
  resetPasswordAllowed: boolean;
}

const UserList = ({
  users = [],
  // handleCurrentItem,
  handleResetPassword,
  updateAllowed,
  resetPasswordAllowed,
}: Props) => {
  if (users.length === 0) return <h4>Não há usuários cadastrados.</h4>;
  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>Nome</div>
          <div className={`${styles.col} ${styles.col3}`}>E-mail</div>
          <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
        </li>

        {users.map((item) => (
          <li className={styles.tableRow} key={item.idUser}>
            <div className={`${styles.col} ${styles.col1}`} data-label="Id">
              {item.idUser}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
              <a href={`/usuario/${item.idUser}`}>{item.name}</a>
            </div>
            <div className={`${styles.col} ${styles.col3}`} data-label="E-mail">
              {item.email}
            </div>
            <div className={`${styles.col} ${styles.col4}`} data-label="Ações">
              {resetPasswordAllowed && (
                <button
                  className="btn btn-secondary"
                  onClick={() => handleResetPassword(item.idUser)}
                >
                  <FontAwesomeIcon icon={faKey} size="1x" />
                </button>
              )}
              {updateAllowed && (
                <a href={`/usuario/${item.idUser}`} className="btn btn-primary">
                  <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                </a>
              )}

              {/* <button
                className="btn btn-danger"
                onClick={() => handleCurrentItem(item.idUser)}
              >
                <FontAwesomeIcon icon={faTrash} size="1x" />
              </button> */}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;
