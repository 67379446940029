import { ReleaseItem, getReleaseItemsData, PaginationProps } from '../../shared/@types/releaseItems';
import * as releaseItemsAction from './releaseItemsAction';

export interface State {
  releaseItems?: getReleaseItemsData;
  releaseItem?: ReleaseItem;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  releaseItems_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  releaseItems: undefined,
  releaseItem: undefined,
  queryParam: undefined,
  loading: true,
};

export type Actions =
  | releaseItemsAction.GetAllReleaseItems
  | releaseItemsAction.GetReleaseItemsSearch
  | releaseItemsAction.GetReleaseItem
  | releaseItemsAction.SetQueryParam
  | releaseItemsAction.UpdateReleaseItem;

export const releaseItemsReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case releaseItemsAction.GET_ALL_RELEASE_ITEMS: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        releaseItems: data,
        releaseItems_pagination: pagination
      };
    }
    case releaseItemsAction.GET_RELEASE_ITEMS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        releaseItems: data,
        releaseItems_pagination: pagination
      };
    }
    case releaseItemsAction.GET_RELEASE_ITEM: {
      const { releaseItem } = action.payload;
      return {
        ...state,
        releaseItem,
        loading: false,
      };
    }
    case releaseItemsAction.UPDATE_RELEASE_ITEM: {
      const { releaseItem } = action.payload;
      return {
        ...state,
        releaseItem,
      };
    }
    case releaseItemsAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
