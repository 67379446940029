import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  //   faPlus,
  //   faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
// import { Button } from "react-bootstrap";

import styles from "./styles.module.scss";
// import { formatter } from "../../../utils/formUtils";

interface Props {
  participantDams: any;
  handleModalDelete: (id: string) => void;
  deletePersonDamAllowed: boolean;
}

const Index = ({
  participantDams = [],
  handleModalDelete,
  deletePersonDamAllowed,
}: Props) => {
  return (
    <div className={styles.dams}>
      <div className={styles.damsHeader}>
        <div>
          <h4>Barragens que atingem o participante</h4>
        </div>
      </div>
      <div className="dams--list">
        <div className={styles.container}>
          <ul className={styles.responsiveTable}>
            <li className={styles.tableHeader}>
              <div className={`${styles.col} ${styles.col1}`}>Barragem</div>
              <div className={`${styles.col} ${styles.col1}`}>Cidade</div>
              <div className={`${styles.col} ${styles.col1}`}>Estado</div>
              <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
            </li>

            {participantDams && participantDams.length === 0 && (
              <h5>Sem barragens cadastradas</h5>
            )}

            {participantDams.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <li className={styles.tableRow} key={item.idDam}>
                    <div
                      className={`${styles.col} ${styles.col1}`}
                      data-label="Barragem"
                    >
                      {item.name}
                    </div>
                    <div
                      className={`${styles.col} ${styles.col2}`}
                      data-label="Cidade"
                    >
                      {item.nameCity}
                    </div>
                    <div
                      className={`${styles.col} ${styles.col3}`}
                      data-label="Estado"
                    >
                      {item.nameState}
                    </div>
                    <div
                      className={`${styles.col} ${styles.col4}`}
                      data-label="Ações"
                    >
                      {deletePersonDamAllowed && (
                        <button
                          className="btn btn-danger"
                          onClick={() => handleModalDelete(item.idPersonDam)}
                        >
                          <FontAwesomeIcon icon={faTrash} size="1x" />
                        </button>
                      )}
                    </div>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;
