import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";

import { stateProps } from "../../shared/@types/general";
import { useGetProjects, useProjects } from "../../store/hooks/projectHooks";
import { useGetCashiers, useCashiers } from "../../store/hooks/cashierHooks";
import {
  useGetActivities,
  useActivities,
} from "../../store/hooks/activityHooks";
import {
  useExecuteProjectReport,
  useGenerateReport,
  useGenerateCashierReport,
  useGenerateActivityReport,
  useGetReports,
  useReports,
  useDeleteReport,
} from "../../store/hooks/reportHooks";
import Admin from "../../shared/components/Admin";
import styles from "../styles.module.scss";
import ReportList from "./List/ReportList";
import { Activity } from "../../shared/@types/activity";
import ModalDelete from "../../shared/components/ModalDelete";
import { Redirect } from "react-router";
import { usePermission } from "../../store/hooks/permissionHooks";

const Index = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getProjects = useGetProjects();
  const getCashiers = useGetCashiers();
  const getActivities = useGetActivities();
  const getReportList = useGetReports();
  const deleteReport = useDeleteReport();
  const generateProjectReport = useGenerateReport();
  const generateCashierReport = useGenerateCashierReport();
  const generateActivityReport = useGenerateActivityReport();

  const executeProjectReport = useExecuteProjectReport();
  const reportList = useReports();
  const projectsData = useProjects();
  const cashiersData = useCashiers();
  const activitiesData = useActivities();
  const [idProject, setIdProject] = useState("");
  const [idCashier, setIdCashier] = useState("");
  const [idActivity, setIdActivity] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [reportType, setReportType] = useState("");

  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [redirect, setRedirect] = useState(false);

  const [reportOptions, setReportOptions] = useState({
    report_index_delete: false,
    report_executor_get: false,
    report_index_get: false,
    report_cashierpdf_get: false,
    report_projectresumepdf_get: false,
    report_activitypdf_get: false,
    report_projectpdf_get: false,
  });

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "report_index_delete",
      "report_executor_get",
      "report_index_get",
      "report_cashierpdf_get",
      "report_projectresumepdf_get",
      "report_activitypdf_get",
      "report_projectpdf_get",
    ];

    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      let newAccess: any = {};

      if (access.includes("report_index_delete"))
        newAccess = { ...newAccess, report_index_delete: true };
      if (access.includes("report_executor_get"))
        newAccess = { ...newAccess, report_executor_get: true };
      if (access.includes("report_index_get"))
        newAccess = { ...newAccess, report_index_get: true };
      if (access.includes("report_cashierpdf_get"))
        newAccess = { ...newAccess, report_cashierpdf_get: true };
      if (access.includes("report_projectresumepdf_get"))
        newAccess = { ...newAccess, report_projectresumepdf_get: true };
      if (access.includes("report_activitypdf_get"))
        newAccess = { ...newAccess, report_activitypdf_get: true };
      if (access.includes("report_projectpdf_get"))
        newAccess = { ...newAccess, report_projectpdf_get: true };

      setReportOptions(newAccess);
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getInfo = async () => {
      getProjects(access_token, 1, true);
      getCashiers(access_token, 1, true);
      getActivities(access_token, 1, true);
    };

    getInfo();
  }, [access_token, getProjects, getCashiers, getActivities]);

  useEffect(() => {
    getReportList(access_token);
  }, [getReportList, access_token]);

  useEffect(() => {
    setIdCashier("");
    setIdProject("");
    setInitialDate("");
    setFinalDate("");
    getReportList(access_token);
    // eslint-disable-next-line
  }, [reportType]);

  const executeReport = async (res: any) => {
    if (res && res.idReport) {
      await executeProjectReport(access_token, res.idReport);
    }
    getReportList(access_token);
  };

  const generateReport = () => {
    let result;

    if (reportType === "project" || reportType === "project-resume") {
      result = generateProjectReport(
        access_token,
        idProject,
        reportType,
        initialDate,
        finalDate
      );
    } else if (reportType === "cashier") {
      result = generateCashierReport(
        access_token,
        idCashier,
        initialDate,
        finalDate
      );
    } else if (reportType === "activity") {
      result = generateActivityReport(access_token, idActivity);
    }

    toast.promise(result, {
      loading: "Gerando relatório...",
      success: (res: any) => {
        executeReport(res);
        return "Em breve seu relatório estará disponível na lista";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handleCurrentItem = (id: string) => {
    setModal(!modal);
    setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteReport(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo relatório...",
      success: () => {
        getReportList(access_token);
        setModal(!modal);
        return "Relatório excluído.";
      },
      error: (error) => error.message,
    });
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/home`,
        }}
      />
    );
  }

  let projectType =
    reportType === "project" || reportType === "project-resume" ? true : false;

  return (
    <>
      <Admin>
        {modal && (
          <ModalDelete
            handleModal={handleModal}
            handleDelete={handleDelete}
            modal={modal}
          />
        )}

        <div className={styles.headerInfo}>
          <h2>Relatório</h2>
        </div>

        {reportOptions.report_executor_get && (
          <Form style={{ marginBottom: 20 }}>
            <Row>
              <Col md={6}>
                <Form.Label htmlFor="type">Tipo de relatório</Form.Label>
                <Form.Select
                  name="reportType"
                  id="reportType"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                  style={{ margin: 0 }}
                >
                  <option value="">Escolha sua opção</option>
                  {
                    reportOptions.report_activitypdf_get && <option value="activity">Atividade</option>
                  }
                  {
                    reportOptions.report_cashierpdf_get && <option value="cashier">Caixa</option>
                  }
                  {
                    reportOptions.report_projectpdf_get && <option value="project">Projeto</option>
                  }
                  {
                    reportOptions.report_projectresumepdf_get && <option value="project-resume">Projeto Resumido</option>
                  }
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-items-end">
              {reportType === "cashier" && (
                <Col md={4}>
                  <Form.Label htmlFor="idCashier">Caixas</Form.Label>
                  <Select
                    placeholder="Selecione um caixa"
                    noOptionsMessage={() => "Não encontrado"}
                    name="idCashier"
                    onChange={(option: any) => setIdCashier(option.value)}
                    value={
                      cashiersData &&
                      cashiersData.cashiers &&
                      cashiersData.cashiers
                        .map((cashier: any) => ({
                          label: cashier.name,
                          value: cashier.idCashier,
                        }))
                        .find((option: any) => option.value === idCashier)
                    }
                    options={
                      cashiersData &&
                      cashiersData.cashiers &&
                      cashiersData.cashiers.map((cashier: any) => ({
                        label: cashier.name,
                        value: cashier.idCashier,
                      }))
                    }
                  />
                </Col>
              )}

              {projectType === true && (
                <Col md={4}>
                  <Form.Label htmlFor="idProject">Projeto</Form.Label>
                  <Select
                    placeholder="Selecione um projeto"
                    noOptionsMessage={() => "Não encontrado"}
                    name="idProject"
                    onChange={(option: any) => setIdProject(option.value)}
                    value={
                      projectsData &&
                      projectsData.projects &&
                      projectsData.projects
                        .map((project: any) => ({
                          label: project.name,
                          value: project.idProject,
                        }))
                        .find((option: any) => option.value === idProject)
                    }
                    options={
                      projectsData &&
                      projectsData.projects &&
                      projectsData.projects.map((project: any) => ({
                        label: project.name,
                        value: project.idProject,
                      }))
                    }
                  />
                </Col>
              )}
              {reportType === "activity" && (
                <Col md={4}>
                  <Form.Label htmlFor="idActivity">Atividade</Form.Label>
                  <Select
                    placeholder="Selecione uma atividade"
                    noOptionsMessage={() => "Não encontrado"}
                    name="idActivity"
                    onChange={(option: any) => setIdActivity(option.value)}
                    value={
                      activitiesData &&
                      activitiesData.activities &&
                      activitiesData.activities
                        .map((activity: Activity) => ({
                          label: activity.subject,
                          value: activity.idActivity,
                        }))
                        .find((option: any) => option.value === idProject)
                    }
                    options={
                      activitiesData &&
                      activitiesData.activities &&
                      activitiesData.activities.map((activity: any) => ({
                        label: activity.subject,
                        value: activity.idActivity,
                      }))
                    }
                  />
                </Col>
              )}
              {reportType && reportType !== "" && reportType !== "activity" && (
                <>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Data inicial</Form.Label>
                      <Form.Control
                        id="initialDate"
                        name="initialDate"
                        type="date"
                        onChange={(e) => setInitialDate(e.target.value)}
                        value={initialDate}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Data final</Form.Label>
                      <Form.Control
                        id="finalDate"
                        name="finalDate"
                        type="date"
                        onChange={(e) => setFinalDate(e.target.value)}
                        value={finalDate}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>

            {/* {reportType === "cashier" ||
            (reportType === "project" && ( */}
            <Row>
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button onClick={generateReport} style={{ marginTop: 20 }}>
                    Novo relatório
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}

        <hr style={{ marginBottom: 50 }} />
        {reportOptions.report_index_get && reportList && reportList.report && reportList.report.length > 0 && (
          <ReportList
            report={reportList.report}
            token={access_token}
            handleCurrentItem={handleCurrentItem}
            reportOptions={reportOptions}
          />
        )}
      </Admin>
    </>
  );
};

export default Index;
