import { getParticipantData, Participant } from '../../shared/@types/participant';

export const GET_PARTICIPANT = 'GET_PARTICIPANT';
export const GET_PARTICIPANTS_SEARCH = 'GET_PARTICIPANTS_SEARCH';
export const GET_ALL_PARTICIPANTS = 'GET_ALL_PARTICIPANTS';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_DAMS_SEARCH = 'GET_DAMS_SEARCH';

export const getParticipant = (participant: Participant) => ({
  type: GET_PARTICIPANT,
  payload: {
    participant,
  },
});

export interface GetParticipant{
  type: typeof GET_PARTICIPANT;
  payload: { participant: Participant };
}

export const getAllParticipants = (participants: getParticipantData) => ({
  type: GET_ALL_PARTICIPANTS,
  payload: {
    data: participants.data,
    pagination: participants.pagination,
  },
});

export interface GetAllParticipants {
  type: typeof GET_ALL_PARTICIPANTS;
  payload: {
    data: getParticipantData;
    pagination: Partial<getParticipantData>
  };
}

export const getParticipantsSearch = (participants: getParticipantData) => ({
  type: GET_DAMS_SEARCH,
  payload: {
    data: participants.data,
    pagination: participants.pagination,
  },
});

export interface GetParticipantsSearch {
  type: typeof GET_PARTICIPANTS_SEARCH;
  payload: {
    data: getParticipantData;
    pagination: Partial<getParticipantData>
  };
}



export const updateParticipant = (participant: Participant) => ({
  type: UPDATE_PARTICIPANT,
  payload: {
    participant,
  },
});

export interface UpdateParticipant {
  type: typeof UPDATE_PARTICIPANT;
  payload: {
    participant: Participant;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
