import { Activity, getActivityData, PaginationProps } from '../../shared/@types/activity';
import * as activityAction from './activityAction';

export interface State {
  activities?: getActivityData;
  activity?: Activity;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  activity_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  activities: undefined,
  queryParam: undefined,
  activity: undefined,
  loading: true,
};

export type Actions =
  | activityAction.GetAllActivities
  | activityAction.GetActivitiesSearch
  | activityAction.GetActivity
  | activityAction.SetQueryParam
  | activityAction.UpdateActivity;

export const activityReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case activityAction.GET_ALL_ACTIVITIES: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        activities: data,
        activity_pagination: pagination,
      };
    }
    case activityAction.GET_ACTIVITIES_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        activities: data,
        activity_pagination: pagination,
      };
    }
    case activityAction.GET_ACTIVITY: {
      const { activity } = action.payload;
      return {
        ...state,
        activity,
        loading: false,
      };
    }
    case activityAction.UPDATE_ACTIVITY: {
      const { activity } = action.payload;
      return {
        ...state,
        activity,
      };
    }
    case activityAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
