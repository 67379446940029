import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../project/projectReducer';
import { RootState } from '../reducers';
import * as projectAction from '../project/projectAction';
import { putProject, postProject, requestProjectsData, requestProjectData, requestDeleteProject, postSearch } from '../../services/projects';
import { Project } from '../../shared/@types/project';

const useProjectState = (): UseState =>
  useSelector((rootState: RootState) => rootState.projects);

export const useProjects = () => {
  const { projects, project_pagination } = useProjectState();
  const data = { projects, project_pagination }
  return data;
};

export const useGetProjects = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, page: number, full_list?: boolean) => {
    const data = await requestProjectsData(access_token, 0, page, full_list);
    dispatch(projectAction.getAllProjects(data));
  }, [dispatch]);
};

export const useGetProject = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestProjectData(access_token, id);
    return data;
  }, []);
};

export const useDeleteProject = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteProject(access_token, id);
    return data;
  }, [])
};

export const usePostProject = () => {
  return useCallback(
    async (access_token: string, formProjectBody: Project) => {
      const createdProject = await postProject(access_token, formProjectBody);
      return createdProject;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(projectAction.getProjectsSearch(searchResult));
    },
    [dispatch],
  );
};

export const usePutProject = () => {
  return useCallback(
    async (access_token: string, formProjectBody: Project, id: string) => {
      const createdProject = await putProject(access_token, formProjectBody, id);
      return createdProject;
    },
    [],
  );
};
