import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";

import { usePostActivityParticipant } from "../../../store/hooks/activityHooks";
import {
  useGetParticipants,
  useParticipants,
} from "../../../store/hooks/participantHooks";
import { REQUIRED } from "../../../utils/formUtils";
import { stateProps } from "../../../shared/@types/general";
import { useEffect } from "react";
import { Participant } from "../../../shared/@types/participant";
import { ActivityParticipant } from "../../../shared/@types/activity";

interface ParticipantFormProps {
  idPerson?: string;
  idActivity?: string;
  age?: string;
}

interface ParticipantProps {
  idCurrentActivity: string;
  getCurrentActivityParticipants: () => void;
  saveCheckinAllowed: boolean;
}

const activityParticipantSchema = yup.object().shape({
  idActivity: yup.string().required(REQUIRED),
  idPerson: yup.string().required(REQUIRED),
});

const ParticipantForm = ({
  idCurrentActivity,
  getCurrentActivityParticipants,
  saveCheckinAllowed,
}: ParticipantProps) => {
  const { access_token } = useSelector((state: stateProps) => state.auth);
  const postActivityParticipant = usePostActivityParticipant();

  const getParticipants = useGetParticipants();
  const participantsData = useParticipants();

  const handleSubmit = (values: ParticipantFormProps) => {
    let result = postActivityParticipant(access_token, values);

    toast.promise(result, {
      loading: "Salvando participante na atividade...",
      success: () => {
        getCurrentActivityParticipants();
        formik.resetForm();
        return "Participante cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 422)
        ) {
          return err.response.data.error;
        }
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idPerson: "",
      idActivity: idCurrentActivity,
      age: "",
    },
    validationSchema: activityParticipantSchema,
    onSubmit: (values: ParticipantFormProps) => {
      handleSubmit(values);
    },
  });

  const { handleChange, errors, touched, values, setFieldValue } = formik;

  useEffect(() => {
    getParticipants(access_token, 1);
  }, [getParticipants, access_token]);

  function getCurrentAge(birthdate: any) {
    const today = new Date();
    birthdate = new Date(birthdate);
    const age =
      today.getFullYear() -
      birthdate.getFullYear() -
      (today.getMonth() < birthdate.getMonth() ||
        (today.getMonth() === birthdate.getMonth() &&
          today.getDate() < birthdate.getDate()));
    return age;
  }

  useEffect(() => {
    if (values.idPerson && values.idPerson !== "") {
      if (
        !participantsData.participants ||
        participantsData.participants.length === 0
      )
        return;
      const result = participantsData.participants.filter(
        (item: ActivityParticipant) => item.idPerson === values.idPerson
      );

      if (result.length > 0) {
        const age = getCurrentAge(result[0].birthday);
        setFieldValue("age", age);
      }
    }
  }, [values, participantsData.participants, setFieldValue]);

  const mountParticipantList = (participantsList: any) => {
    const mountParticipants =
      participantsList &&
      participantsList.map((participant: Participant) => ({
        label: participant.name + " - " + participant.documentNumber,
        value: participant.idPerson,
      }));
    return participantsData.participants
      ? [
          { label: "Selecione um participante", value: "" },
          ...mountParticipants,
        ]
      : null;
  };

  const participants = mountParticipantList(participantsData.participants);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={12}>
            <h4>Adicionar participante na atividade</h4>
          </Col>
        </Row>
        <Row>
          {participants && Array.isArray(participants) && (
            <Col md={6}>
              <Form.Label htmlFor="idPerson">Participante</Form.Label>
              <Select
                placeholder="Selecione um participante"
                noOptionsMessage={() => "Não encontrado"}
                name="idPerson"
                onChange={(option: any) =>
                  setFieldValue("idPerson", option.value ? option.value : "")
                }
                value={participants.find(
                  (option: OptionValue) => option.value === values.idPerson
                )}
                options={participants}
              />
              {touched.idPerson &&
                errors.idPerson &&
                values.idPerson !== "" && (
                  <Form.Text>{errors.idPerson}</Form.Text>
                )}
            </Col>
          )}

          {values.age !== "" && (
            <Col md={6}>
              <Form.Group>
                <Form.Label>Idade</Form.Label>
                <Form.Control
                  id="age"
                  name="age"
                  type="number"
                  onChange={handleChange}
                  value={values.age}
                  disabled
                />
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row>
          {saveCheckinAllowed && (
            <Col md={12} className="button-save">
              <Form.Group>
                <Button type="submit">SALVAR</Button>
              </Form.Group>
            </Col>
          )}
        </Row>
      </Form>
    </FormikProvider>
  );
};

export default ParticipantForm;
