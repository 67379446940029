import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

interface SubRubricProps {
  ledgerName: string;
  idSubledger: string;
  name: string;
}

interface Props {
  subrubrics: SubRubricProps[] | undefined;
  handleCurrentItem: (id: string) => void;
}

const SubRubricList = ({ subrubrics = [], handleCurrentItem }: Props) => {
  if (subrubrics.length === 0) return <h4>Não há subrubricas cadastradas.</h4>;

  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>Rubrica</div>
          <div className={`${styles.col} ${styles.col3}`}>Nome</div>
          <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
        </li>

        {subrubrics.map((item, index) => (
          <li className={styles.tableRow} key={index}>
            <div className={`${styles.col} ${styles.col1}`} data-label="Id">
              {item.idSubledger}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
              {item.ledgerName}
            </div>
            <div className={`${styles.col} ${styles.col3}`} data-label="Nome">
              {item.name}
            </div>
            <div className={`${styles.col} ${styles.col4}`} data-label="Ações">
              <a
                href={`/subrubrica/${item.idSubledger}`}
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon={faPenToSquare} size="1x" />
              </a>
              <button
                className="btn btn-danger"
                onClick={() => handleCurrentItem(item.idSubledger)}
              >
                <FontAwesomeIcon icon={faTrash} size="1x" />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubRubricList;
