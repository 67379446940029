import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../state/stateReducer';
import { RootState } from '../reducers';
import * as stateAction from '../state/stateAction';
import { requestStatesData } from '../../services/states';
// import { Dam } from '../../shared/@types/dam';

const useStateState = (): UseState =>
  useSelector((rootState: RootState) => rootState.states);

export const useStates = () => {
  const { states, state_pagination } = useStateState();
  const data = { states, state_pagination }
  return data;
};

export const useGetStates = () => {
  const dispatch = useDispatch();
  
  return useCallback(async (access_token: string, page?: number) => {
    const data = await requestStatesData(access_token, 0, page);
    dispatch(stateAction.getAllStates(data));
  }, [dispatch]);
};

