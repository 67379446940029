import { Activity, ActivityParticipant, getActivityData } from '../../shared/@types/activity';
import { requestService } from '../../utils/requestService';

export const requestActivitiesData = async (
  access_token: string, offset: number, page: number, full_list?: boolean
): Promise<getActivityData> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/activity?page=${page}`;

  if(full_list === true){
    url = url + '&per_page=500';
  }

  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const data = await requestService(url, {}, headers, 'GET', false);
  return data;
};

export const postActivity = async (access_token: string, activity: Activity): Promise<getActivityData> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/activity`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, activity, headers, 'POST', false);
    return response;
};

export const postActivityParticipant = async (access_token: string, activityParticipant: ActivityParticipant): Promise<getActivityData> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/activity-checkin`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, activityParticipant, headers, 'POST', false);
  return response;
};

export const postSearch = async (access_token: string, search: string, page: number): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/acvitity?filter=${search}&page=${page}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const putActivity = async (access_token: string, activity: Activity, id: string): Promise<any> => {
    const url = `${process.env.REACT_APP_ADPASE_URL}/activity/${id}`;
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
    const response = await requestService(url, activity, headers, 'PUT', false);
    return response;
};

export const lockActivity = async (access_token: string, activity: Activity, id: string): Promise<any> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/activity/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, activity, headers, 'PUT', false);
  return response;
};

export const requestActivityData = async (
  access_token: string,
  id: string,
): Promise<Activity> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/activity?id=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestActivityParticipantsData = async (
  access_token: string,
  id: string,
): Promise<Activity> => {
  const url = `${process.env.REACT_APP_ADPASE_URL}/activity-checkin?idActivity=${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'GET', false);
  return response;
};

export const requestDeleteActivity = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/activity/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}

export const requestDeleteActivityParticipant = async (
  access_token: string,
  id: string
): Promise<any> => {
  let url = `${process.env.REACT_APP_ADPASE_URL}/activity-checkin/${id}`;
  const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${access_token}` };
  const response = await requestService(url, {}, headers, 'DELETE', false);
  return response;
}


