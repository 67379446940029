export const GET_CURRENT_ITEM = 'GET_CURRENT_ITEM';

export interface GetCurrentItem {
    type: typeof GET_CURRENT_ITEM,
    payload: { current: string };
    // current: string;
}

export const getCurrentItem = (current: string) => ({
    type: GET_CURRENT_ITEM,
    payload: {
        current,
    }
});