import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";

import Admin from "../../shared/components/Admin";
import { Cashier } from "../../shared/@types/cashier";
import {
  usePostCashier,
  usePutCashier,
  useGetCashier,
} from "../../store/hooks/cashierHooks";
import styles from "../styles.module.scss";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";

import { REQUIRED } from "../../utils/formUtils";
import { usePermission } from "../../store/hooks/permissionHooks";

const cashierSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
});

interface ParamTypes {
  id: string;
}

const CashierManagement = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const { id } = useParams<ParamTypes>();
  const postCashier = usePostCashier();
  const putCashier = usePutCashier();
  const getCashier = useGetCashier();

  const [redirect, setRedirect] = useState(false);

  const [saveAllowed, setSaveAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "cashier_index_post",
      "cashier_index_put",
      "cashier_index_get_one",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (id && access.includes("cashier_index_get_one")) setSaveAllowed(true);
      if (!id && access.includes("cashier_index_post")) setSaveAllowed(true);
      if (
        id &&
        access.includes("cashier_index_get_one") &&
        access.includes("cashier_index_put")
      )
        setSaveAllowed(true);
      if (
        id &&
        access.includes("cashier_index_get_one") &&
        !access.includes("cashier_index_put")
      )
        setSaveAllowed(false);

      if (!access.includes("cashier_index_get_one") && id) {
        toast.error("Você não tem acesso para visualizar/editar um caixa.");
        setRedirect(true);
      }

      if (!access.includes("cashier_index_post") && !id) {
        toast.error("Você não tem acesso para cadastrar um caixa.");
        setRedirect(true);
      }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (values: Cashier) => {
    let result: any;

    if (id) {
      result = putCashier(access_token, values, id);
    } else {
      result = postCashier(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando caixa...",
      success: () => {
        setRedirect(true);
        return "Caixa cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: cashierSchema,
    onSubmit: (values: Cashier) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getCurrentItem("cashiers");
    const getInfo = async () => {
      if (id) {
        const result: any = await getCashier(access_token, id);
        if (result) {
          setFieldValue("name", result.name);
        }
      }
    };

    getInfo();
  }, [id, getCashier, setFieldValue, access_token, getCurrentItem]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/caixas`,
        }}
      />
    );
  }

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar Caixa" : "Cadastrar Caixa"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <Form.Text className={styles.textError}>
                    {errors.name}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            {saveAllowed && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit">SALVAR</Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
    </Admin>
  );
};

export default CashierManagement;
