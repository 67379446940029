import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Container, Spinner, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import Pagination from "react-js-pagination";

import Admin from "../../shared/components/Admin";
import {
  useHideLoading,
  useLoading,
  useShowLoading,
} from "../../store/hooks/loadingHooks";
import {
  useGetUsers,
  useUsers,
  useDeleteUser,
  usePostSearch,
  useResetUserPassword,
} from "../../store/hooks/userHooks";
import UserList from "./List/UserList";
import ModalDelete from "../../shared/components/ModalDelete";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import Search from "../../shared/components/Search";

import styles from "../styles.module.scss";
import { usePermission } from "../../store/hooks/permissionHooks";

const Index = () => {
  const getCurrentItem = useGetCurrentItem();
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getUsers = useGetUsers();
  const usersData = useUsers();
  const deleteUser = useDeleteUser();
  const resetUserPassword = useResetUserPassword();
  const postSearch = usePostSearch();
  const isLoading = useLoading();
  const showLoading = useShowLoading();
  const hideLoading = useHideLoading();

  const [redirect, setRedirect] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const [activePage, setActivePage] = useState(1);

  const [redirectHome, setRedirectHome] = useState(false);
  const [updateAllowed, setUpdateAllowed] = useState(false);
  const [resetPasswordAllowed, setResetPasswordAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "users_resetPassword_put",
      "users_index_get",
      "users_index_put",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (!access.includes("users_index_get")) {
        toast.error("Você não tem acesso a lista de usuários.");
        setRedirectHome(true);
      }
      if (access.includes("users_index_put")) setUpdateAllowed(true);
      if (access.includes("users_resetPassword_put"))
        setResetPasswordAllowed(true);
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (values: any) => {
    // if (values.search === '') return;

    const result = postSearch(access_token, values.search, 1);

    toast.promise(result, {
      loading: "Buscando usuário...",
      success: (result: any) => {
        return "Usuários encontrados!";
      },
      error: (error) => error.message,
    });
  };

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values } = formik;

  useEffect(() => {
    showLoading();

    getCurrentItem("users");

    const fetchUsers = async () => {
      try {
        const result: any = getUsers(access_token, 1);

        toast.promise(result, {
          loading: "Carregando Usuários...",
          success: () => {
            return "Lista de Usuários carregada!";
          },
          error: (error) => {
            if (
              error &&
              error.response &&
              error.response.status &&
              (error.response.status === 401 || error.response.status === 403)
            ) {
              setRedirect(true);
              return error.response.data.error;
            }
            return error.response.data.error;
          },
        });
      } catch (error: any) {
        if (
          error &&
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setRedirect(true);
          return error.response.data.error;
        }
      } finally {
        hideLoading();
      }
    };

    fetchUsers();
  }, [getUsers, access_token, showLoading, hideLoading, getCurrentItem]);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleCurrentItem = (id: string) => {
    setModal(!modal);
    setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteUser(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo usuário...",
      success: () => {
        getUsers(access_token, 1);
        setModal(!modal);
        return "Usuário excluída.";
      },
      error: (error) => error.message,
    });
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);

    if (values.search && values.search !== "") {
      postSearch(access_token, values.search, pageNumber);
    } else {
      getUsers(access_token, pageNumber);
    }
  };

  const handleResetPassword = (id: string) => {
    const result = resetUserPassword(access_token, id);

    toast.promise(result, {
      loading: "Resetando senha do usuário...",
      success: () => {
        return "Senha resetada com sucesso.";
      },
      error: (error) => {
        return error.response.data.message;
      },
    });
  };

  if (redirectHome) {
    return (
      <Redirect
        to={{
          pathname: `/home`,
        }}
      />
    );
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
        }}
      />
    );
  }

  return (
    <>
      <Admin>
        {isLoading && (
          <Container>
            <Spinner animation="border" variant="primary" />
          </Container>
        )}
        {modal && (
          <ModalDelete
            handleModal={handleModal}
            handleDelete={handleDelete}
            modal={modal}
          />
        )}

        <div className={styles.headerInfo}>
          <h2>Usuários</h2>
          <a
            href={`/adicionar-usuario`}
            className="btn btn-primary default-button"
          >
            Adicionar
          </a>
        </div>
        <hr className={styles.hr} />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Search
              handleChange={handleChange}
              values={values}
              placeholder="Digite o nome do usuário..."
            />
          </Form>
        </FormikProvider>
        <UserList
          users={usersData.users}
          handleCurrentItem={handleCurrentItem}
          handleResetPassword={handleResetPassword}
          updateAllowed={updateAllowed}
          resetPasswordAllowed={resetPasswordAllowed}
        />
        <div className="pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={
              usersData &&
              usersData.user_pagination &&
              usersData.user_pagination.total_content
                ? usersData.user_pagination.total_content
                : 0
            }
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </Admin>
    </>
  );
};

export default Index;
