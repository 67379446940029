import { Provider, getProviderData, PaginationProps } from '../../shared/@types/provider';
import * as providerAction from './providerAction';

export interface State {
  providers?: getProviderData;
  provider?: Provider;
  loading: boolean;
  errorMessage?: string;
  editingGenericId?: string;
  queryParam?: string;
  provider_pagination?: PaginationProps;
}

const INITIAL_STATE: State = {
  providers: undefined,
  queryParam: undefined,
  provider: undefined,
  loading: true,
};

export type Actions =
  | providerAction.GetAllProviders
  | providerAction.GetProvidersSearch
  | providerAction.GetProvider
  | providerAction.SetQueryParam
  | providerAction.UpdateProvider;

export const providerReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case providerAction.GET_ALL_PROVIDERS: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        providers: data,
        provider_pagination: pagination
      };
    }
    case providerAction.GET_PROVIDERS_SEARCH: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        providers: data,
        provider_pagination: pagination
      };
    }
    case providerAction.GET_PROVIDER: {
      const { provider } = action.payload;
      return {
        ...state,
        provider,
        loading: false,
      };
    }
    case providerAction.UPDATE_PROVIDER: {
      const { provider } = action.payload;
      return {
        ...state,
        provider,
      };
    }
    case providerAction.SET_QUERY_PARAM: {
      const { param } = action.payload;
      return {
        ...state,
        queryParam: param,
      };
    }
    default:
      return state;
  }
};
