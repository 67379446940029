import {
  getUserData, PaginationProps,
  } from '../../shared/@types/user';
import * as userAction from './userAction';

export interface State {
    users?: getUserData;
    user?: string;
    access_token?: string;
    loading: boolean;
    user_pagination?: PaginationProps;
  }

const INITIAL_STATE: State = {
    access_token: JSON.parse(localStorage.getItem("access_token")) || "",
    user_data: JSON.parse(localStorage.getItem("user")) || "",
    loading: true,
}

export type Actions = userAction.GetUserInfo | userAction.GetAllUsers | userAction.GetUsersSearch | userAction.GetUser | userAction.LoginSuccess | userAction.LogoutUser;

export const userReducer = (state = INITIAL_STATE, action: Actions) => {
    switch (action.type) {
        case userAction.GET_ALL_USERS: {
          const { data, pagination } = action.payload;
          return {
            ...state,
            users: data,
            user_pagination: pagination
          };
        };
        case userAction.GET_USERS_SEARCH: {
          const { data, pagination } = action.payload;
          return {
            ...state,
            users: data,
            user_pagination: pagination
          };
        };
        case userAction.GET_USER: {
          const { user } = action.payload;
          return {
            ...state,
            loading: false,
            user,
          };
        };
        case userAction.LOGIN_SUCCESS: {
          const { access_token, user_data } = action.payload;
          return {
            ...state,
            isLoggedIn: true,
            access_token,
            user_data
          }
        };
        default:
          return state;
    }
}