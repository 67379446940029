import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../releaseItems/releaseItemsReducer';
import { RootState } from '../reducers';
import * as releaseItemsAction from '../releaseItems/releaseItemsAction';
import { putReleaseItem, postReleaseItem, requestReleaseItemData, requestReleaseItemsDataByRelease, requestReleaseItemsData, requestDeleteReleaseItem, postSearch } from '../../services/releaseItems';
import { ReleaseItem } from '../../shared/@types/releaseItem';

const useReleaseItemsState = (): UseState =>
  useSelector((rootState: RootState) => rootState.releaseItems);

export const useReleaseItems = () => {
  const { releaseItems, releaseItems_pagination } = useReleaseItemsState();
  const data = { releaseItems, releaseItems_pagination }
  return data;
};

export const useGetReleaseItems = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, page: number, full_list?: boolean) => {
    const data = await requestReleaseItemsData(access_token, 0, page, full_list);
    dispatch(releaseItemsAction.getAllReleaseItems(data));
  }, [dispatch]);
};

export const useGetReleaseItemsByRelease = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestReleaseItemsDataByRelease(access_token, 0, id);
    dispatch(releaseItemsAction.getAllReleaseItems(data));
  }, [dispatch]);
};

export const useGetReleaseItem = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestReleaseItemData(access_token, id);
    return data;
  }, []);
};

export const useDeleteReleaseItem = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteReleaseItem(access_token, id);
    return data;
  }, [])
};

export const usePostReleaseItem = () => {
  return useCallback(
    async (access_token: string, formReleaseItemBody: Release) => {
      const createdReleaseItem = await postReleaseItem(access_token, formReleaseItemBody);
      return createdReleaseItem;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string,  page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(releaseItemsAction.getReleaseItemsSearch(searchResult));
    },
    [dispatch],
  );
};

export const usePutReleaseItem = () => {
  return useCallback(
    async (access_token: string, formReleaseItemBody: ReleaseItem, id: string) => {
      const createdReleaseItem = await putReleaseItem(access_token, formReleaseItemBody, id);
      return createdReleaseItem;
    },
    [],
  );
};
