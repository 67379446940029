import * as React from "react";
import { Route } from "react-router";
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import jwt from 'jwt-decode' 

import { Switch } from "react-router-dom";

import NotFound from "./pages/notFound";
import Layout from "./shared/components/Layout";
import Activities from "./pages/activity";
import ActivityManagement from "./pages/activity/ActivityManagement";
import Dams from "./pages/dam";
import DamManagement from "./pages/dam/DamManagement";
import Cashiers from "./pages/cashier";
import CashierManagement from "./pages/cashier/CashierManagement";
import Contracts from "./pages/contract";
import ContractManagement from "./pages/contract/ContractManagement";
import Profile from "./pages/profile";
import Home from "./pages/home";
import Login from "./pages/login";
import Providers from "./pages/provider";
import ProviderManagement from "./pages/provider/ProviderManagement";
import Participants from "./pages/participant";
import ParticipantManagement from "./pages/participant/ParticipantManagement";
import Projects from "./pages/project";
import ProjectManagement from "./pages/project/ProjectManagement";
import Releases from "./pages/release";
import ReleaseCreate from "./pages/release/ReleaseCreate";
import ReleaseManagement from "./pages/release/ReleaseManagement";
import Rubrics from "./pages/rubric";
import RubricManagement from "./pages/rubric/RubricManagement";
import SubRubrics from "./pages/subrubric";
import SubRubricManagement from "./pages/subrubric/SubRubricManagement";
import Users from "./pages/user";
import UserManagement from "./pages/user/UserManagement";
import Report from "./pages/report";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { stateProps } from "./shared/@types/general";

function App() {
  const { access_token } = useSelector((state: stateProps) => state.auth);
  const history = useHistory();

  if (access_token) {
    let decodedToken: any = jwt(access_token);
    let currentDate = new Date();
    let tokenDate = new Date(decodedToken.API_TIME * 1000);
    tokenDate.setDate(tokenDate.getDate() + 1)

    if (tokenDate < currentDate) {
      toast.error('Token expirado. Por favor, faça o login novamente.')
      history.push("/login");
    } 
  }

  return (
    <Layout>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/perfil`}
          component={Profile}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/atividades`}
          component={Activities}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-atividade`}
          component={ActivityManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/atividade/:id`}
          component={ActivityManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/barragens`}
          component={Dams}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-barragem`}
          component={DamManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/barragem/:id`}
          component={DamManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/caixas`}
          component={Cashiers}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-caixa`}
          component={CashierManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/caixa/:id`}
          component={CashierManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contratos`}
          component={Contracts}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-contrato`}
          component={ContractManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/contrato/:id`}
          component={ContractManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/fornecedores`}
          component={Providers}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-fornecedor`}
          component={ProviderManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/fornecedor/:id`}
          component={ProviderManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/participantes`}
          component={Participants}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-participante`}
          component={ParticipantManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/participante/:id`}
          component={ParticipantManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/projetos`}
          component={Projects}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-projeto`}
          component={ProjectManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/projeto/:id`}
          component={ProjectManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/lancamentos`}
          component={Releases}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-lancamento`}
          component={ReleaseCreate}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/lancamento/:id`}
          component={ReleaseManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/rubricas`}
          component={Rubrics}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-rubrica`}
          component={RubricManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/rubrica/:id`}
          component={RubricManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/subrubricas`}
          component={SubRubrics}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-subrubrica`}
          component={SubRubricManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/subrubrica/:id`}
          component={SubRubricManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/usuarios`}
          component={Users}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/adicionar-usuario`}
          component={UserManagement}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/usuario/:id`}
          component={UserManagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/relatorios`}
          component={Report}
        />
        <Route path="*" component={NotFound} />
      </Switch>
      <Toaster
        toastOptions={{
          duration: 7000,
        }}
        position="bottom-left"
      />
    </Layout>
  );
}

export default App;
