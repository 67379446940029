import { getRubricData, Rubric } from '../../shared/@types/rubric';

export const GET_RUBRIC = 'GET_RUBRIC';
export const GET_ALL_RUBRICS = 'GET_ALL_RUBRICS';
export const UPDATE_RUBRIC = 'UPDATE_RUBRIC';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_RUBRICS_SEARCH = 'GET_RUBRICS_SEARCH';

export const getRubric = (rubric: Rubric) => ({
  type: GET_RUBRIC,
  payload: {
    rubric,
  },
});

export interface GetRubric {
  type: typeof GET_RUBRIC;
  payload: { rubric: Rubric };
}

export const getAllRubrics = (rubrics: any) => ({
  type: GET_ALL_RUBRICS,
  payload: {
    data: rubrics.data,
    pagination: rubrics.pagination,
  },
});

export interface GetAllRubrics {
  type: typeof GET_ALL_RUBRICS;
  payload: {
    data: getRubricData;
    pagination: Partial<getRubricData>
  };
}

export const getRubricsSearch = (rubrics: getRubricData) => ({
  type: GET_RUBRICS_SEARCH,
  payload: {
    data: rubrics.data,
    pagination: rubrics.pagination,
  },
});

export interface GetRubricsSearch {
  type: typeof GET_RUBRICS_SEARCH;
  payload: {
    data: getRubricData;
    pagination: Partial<getRubricData>
  };
}

export const updateRubric = (rubric: Rubric) => ({
  type: UPDATE_RUBRIC,
  payload: {
    rubric,
  },
});

export interface UpdateRubric {
  type: typeof UPDATE_RUBRIC;
  payload: {
    rubric: Rubric;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
