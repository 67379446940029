import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPlus,
  //   faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

import * as yup from "yup";
import toast from "react-hot-toast";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";

import {
  useFiles,
  useGetFiles,
  usePostFile,
  useDeleteFile,
} from "../../../store/hooks/fileHooks";
import { stateProps } from "../../../shared/@types/general";
import styles from "./styles.module.scss";
import ModalManager from "../ModalManager";
import ModalDelete from "../ModalDelete";
import { REQUIRED } from "../../../utils/formUtils";

interface Props {
  origin: string;
  id: string;
  // files: any;
  // name: string;
  // idRelease?: string;
  // idContract?: string;
  showSaveButton?: boolean;
  showDeleteButton?: boolean;
  showList?: boolean;
}

const fileSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
});

const Index = ({
  origin,
  id,
  showSaveButton,
  showDeleteButton,
  showList,
}: Props) => {
  const { access_token } = useSelector((state: stateProps) => state.auth);
  const getFiles = useGetFiles();
  const filesData = useFiles();
  const postFile = usePostFile();
  const deleteFile = useDeleteFile();

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      [`id${origin}`]: id,
      file: "",
    },
    validationSchema: fileSchema,
    onSubmit: (values: File) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    const getInfo = () => {
      getFiles(access_token, id, origin);
    };

    getInfo();
  }, [access_token, getFiles, id, origin]);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = (values: File) => {
    let result: any;

    // if (currentIdProjectActivity) {
    //   result = putProjectActivity(
    //     access_token,
    //     values,
    //     currentIdProjectActivity
    //   );
    // } else {
    result = postFile(access_token, values, origin);
    // }

    toast.promise(result, {
      loading: "Salvando arquivo...",
      success: () => {
        getFiles(access_token, id, origin);
        setShowModal(!showModal);
        return "Arquivo cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const handleChangeFile = async (event: any) => {
    console.log("event", event);
    const files = event.target.files ? event.target.files : [];

    if (files.length > 0) {
      console.log("files[0]", files[0]);
      const fileType = files[0].type.split("/");

      const toBase64 = (file: any) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      if (fileType.length > 0) {
        let finalFile = {
          type: `.${fileType[1]}`,
          blob: await toBase64(files[0]),
        };
        console.log("finalFile", finalFile);
        setFieldValue("file", finalFile);
      }
    }
  };

  const handleModal = () => {
    setModal(!modal);
  };

  //   const handleCurrentItem = (id: string) => {
  //     setModal(!modal);
  //     setCurrentId(id);
  //   };

  const handleModalDelete = (id: string) => {
    setModal(!modal);
    setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteFile(access_token, currentId, origin);

    toast.promise(result, {
      loading: "Excluindo arquivo...",
      success: () => {
        getFiles(access_token, id, origin);
        setModal(!modal);
        return "Arquivo excluído.";
      },
      error: (error) => error.message,
    });
  };

  return (
    <>
      {modal && (
        <ModalDelete
          handleModal={handleModal}
          handleDelete={handleDelete}
          modal={modal}
        />
      )}
      <div className={styles.files}>
        <div className={styles.filesHeader}>
          <div>
            <h4>Arquivos</h4>
          </div>
          <div>
            {showSaveButton && (
              <Button onClick={handleShowModal}>
                <FontAwesomeIcon icon={faPlus} size="1x" />
              </Button>
            )}
          </div>
        </div>
        <div className="files--list">
          <div className={styles.container}>
            <ul className={styles.responsiveTable}>
              <li className={styles.tableHeader}>
                <div className={`${styles.col} ${styles.col1}`}>Nome</div>
                <div className={`${styles.col} ${styles.col2}`}>Link</div>
                <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
              </li>

              {filesData && filesData.files && filesData.files.length === 0 && (
                <h5>Sem arquivos cadastrados</h5>
              )}

              {showList && filesData &&
                filesData.files &&
                filesData.files.map((item: any, index: number) => {
                  const currentFileId = item[`id${origin}File`];
                  console.log("currentFileId", currentFileId);
                  return (
                    <div key={index}>
                      <div style={{ paddingLeft: 30, paddingTop: 5 }}>
                        {item.description}
                      </div>
                      <li className={styles.tableRow} key={item.idItemsRelease}>
                        <div
                          className={`${styles.col} ${styles.col1}`}
                          data-label="Nome"
                        >
                          {item.name}
                        </div>
                        <div
                          className={`${styles.col} ${styles.col2}`}
                          data-label="Link"
                        >
                          <a
                            href={`${process.env.REACT_APP_ADPASE_IMAGE_URL}/${item.downloadPath}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download
                          </a>
                        </div>
                        <div
                          className={`${styles.col} ${styles.col4}`}
                          data-label="Ações"
                        >
                          <>
                            {/* <button
                              className="btn btn-primary"
                              onClick={() =>
                                handleCurrentItem(item.idItemsRelease)
                              }
                              style={{ marginRight: 5 }}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                            </button> */}
                            {showDeleteButton && (
                              <button
                                className="btn btn-danger"
                                onClick={() => handleModalDelete(currentFileId)}
                              >
                                <FontAwesomeIcon icon={faTrash} size="1x" />
                              </button>
                            )}
                          </>
                        </div>
                        {/* )} */}
                      </li>
                    </div>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <ModalManager showModal={showModal}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className={styles.form}>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Adicionar arquivo
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      id="name"
                      name="name"
                      type="text"
                      onChange={handleChange}
                      value={values.name}
                    />
                    {touched.name && errors.name && (
                      <Form.Text className={styles.textError}>
                        {errors.name}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Anexo</Form.Label>
                    <Form.Control
                      type="file"
                      id="file"
                      multiple
                      name="file"
                      onChange={handleChangeFile}
                      //   onChange={(event) => {
                      //     setFieldValue("file", event.currentTarget.files[0]);
                      //   }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleShowModal}>Cancelar</Button>
              <Button
                type="submit"
                style={{ backgroundColor: "#00897b", border: "#00897b" }}
              >
                Salvar
              </Button>
            </Modal.Footer>
          </Form>
        </FormikProvider>
      </ModalManager>
    </>
  );
};

export default Index;
