import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { State as UseState } from '../provider/providerReducer';
import { RootState } from '../reducers';
import * as providerAction from '../provider/providerAction';
import { putProvider, postProvider, postSearch, requestProvidersData, requestProviderData, requestDeleteProvider } from '../../services/providers';
import { Provider } from '../../shared/@types/provider';

const useProviderState = (): UseState =>
  useSelector((rootState: RootState) => rootState.providers);

export const useProviders = () => {
  const { providers, provider_pagination } = useProviderState();
  const data = { providers, provider_pagination }
  return data;
};

export const useGetProviders = () => {
  const dispatch = useDispatch();
  return useCallback(async (access_token: string, page: number, full_list: boolean) => {
    const data = await requestProvidersData(access_token, 0, page, full_list);
    dispatch(providerAction.getAllProviders(data));
  }, [dispatch]);
};

export const useGetProvider = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestProviderData(access_token, id);
    return data;
  }, []);
};

export const useDeleteProvider = () => {
  return useCallback(async (access_token: string, id: string) => {
    const data = await requestDeleteProvider(access_token, id);
    return data;
  }, [])
};

export const usePostProvider = () => {
  return useCallback(
    async (access_token: string, formProviderBody: Provider) => {
      const createdProvider = await postProvider(access_token, formProviderBody);
      return createdProvider;
    },
    [],
  );
};

export const usePostSearch = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (access_token: string, search: string, page: number) => {
      const searchResult = await postSearch(access_token, search, page);
      dispatch(providerAction.getProvidersSearch(searchResult));
    },
    [dispatch],
  );
};
export const usePutProvider = () => {
  return useCallback(
    async (access_token: string, formProviderBody: Provider, id: string) => {
      const createdProvider = await putProvider(access_token, formProviderBody, id);
      return createdProvider;
    },
    [],
  );
};
