import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";

import Admin from "../../shared/components/Admin";
import { Dam } from "../../shared/@types/dam";
import {
  usePostDam,
  usePutDam,
  useGetDam,
  useGetDamRanges,
  useDeleteDamRange,
} from "../../store/hooks/damHooks";
import styles from "../styles.module.scss";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";

import { REQUIRED } from "../../utils/formUtils";
import CityList from "./cityList";
import CityForm from "./Form/CityForm";
import City from "../../shared/components/City";
import ModalDelete from "../../shared/components/ModalDelete";
import { usePermission } from "../../store/hooks/permissionHooks";

const damSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
});

interface ParamTypes {
  id: string;
}

const DamManagement = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const { id } = useParams<ParamTypes>();
  const postDam = usePostDam();
  const putDam = usePutDam();
  const getDam = useGetDam();
  const getDamRanges = useGetDamRanges();
  const deleteDamRange = useDeleteDamRange();

  const [idCity, setIdCity] = useState("");
  const [idState, setIdState] = useState("");
  const [damRanges, setDamRanges] = useState<any>([]);

  const [redirect, setRedirect] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const [saveAllowed, setSaveAllowed] = useState(false);
  const [saveDamRangeAllowed, setSaveDamRangeAllowed] = useState(false);
  const [getDamRangeAllowed, setGetDamRangeAllowed] = useState(false);
  const [deleteDamRangeAllowed, setDeleteDamRangeAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "dam_index_post",
      "dam_index_put",
      "dam_index_get_one",
      "damrange_index_post",
      "damrange_index_get",
      "damrange_index_delete",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (id && access.includes("damrange_index_post"))
        setSaveDamRangeAllowed(true);
      if (id && access.includes("damrange_index_get"))
        setGetDamRangeAllowed(true);
      if (id && access.includes("damrange_index_delete"))
        setDeleteDamRangeAllowed(true);

      if (id && access.includes("dam_index_get_one")) setSaveAllowed(true);
      if (!id && access.includes("dam_index_post")) setSaveAllowed(true);
      if (
        id &&
        access.includes("dam_index_get_one") &&
        access.includes("dam_index_put")
      )
        setSaveAllowed(true);
      if (
        id &&
        access.includes("dam_index_get_one") &&
        !access.includes("dam_index_put")
      )
        setSaveAllowed(false);

      if (!access.includes("dam_index_get_one") && id) {
        toast.error("Você não tem acesso para visualizar/editar uma barragem.");
        setRedirect(true);
      }
      if (!access.includes("dam_index_post") && !id) {
        toast.error("Você não tem acesso para cadastrar uma barragem.");
        setRedirect(true);
      }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const getCurrentDamRanges = async () => {
    const resultDamRanges: any = await getDamRanges(access_token, id);
    if (resultDamRanges) {
      setDamRanges(resultDamRanges.data);
    }
  };

  const handleSubmit = (values: Dam) => {
    let result: any;

    if (id) {
      result = putDam(access_token, values, id);
    } else {
      result = postDam(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando barragem...",
      success: () => {
        setRedirect(true);
        return "Barragem cadastrada!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      idCity: "",
    },
    validationSchema: damSchema,
    onSubmit: (values: Dam) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getCurrentItem("dams");
    const getInfo = async () => {
      if (id) {
        getCurrentDamRanges();

        const result: any = await getDam(access_token, id);
        if (result) {
          setFieldValue("name", result.name);
          setIdCity(result.idCity);
          setIdState(result.idState);
        }
      }
    };

    getInfo();
    // eslint-disable-next-line
  }, [
    id,
    getDam,
    setFieldValue,
    access_token,
    getCurrentItem,
    getDamRanges,
    idCity,
    idState,
  ]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/barragens`,
        }}
      />
    );
  }

  const getIdCity = (idCity: string) => {
    setFieldValue("idCity", idCity);
  };

  const handleModalDelete = (id?: string) => {
    setModalDelete(!modalDelete);
    if (id) setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteDamRange(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo item de lançamento...",
      success: () => {
        getCurrentDamRanges();
        setModalDelete(!modalDelete);
        setCurrentId("");
        return "Item de lançamento excluído.";
      },
      error: (error) => error.message,
    });
  };

  return (
    <Admin data-testid="dam-management">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar Barragem" : "Cadastrar Barragem"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <Form.Text className={styles.textError}>
                    {errors.name}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <City getIdCity={getIdCity} idCity={idCity} idState={idState} />

            {saveAllowed && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit">SALVAR</Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
      <hr style={{ marginTop: 30, marginBottom: 50 }} />

      {id && (
        <>
          {saveDamRangeAllowed && (
            <CityForm
              idCurrentDam={id}
              getCurrentDamRanges={getCurrentDamRanges}
            />
          )}

          {getDamRangeAllowed && (
            <CityList
              damCities={damRanges}
              handleModalDelete={handleModalDelete}
              deleteDamRangeAllowed={deleteDamRangeAllowed}
            />
          )}
        </>
      )}
      {modalDelete && (
        <ModalDelete
          handleModal={handleModalDelete}
          handleDelete={handleDelete}
          modal={modalDelete}
        />
      )}
    </Admin>
  );
};

export default DamManagement;
