export const releaseTypes  = [
    {
        value: 'OUTLAY',
        label:  'Desembolso'
    },
    {
        value: 'DEVOLUTION',
        label:  'Devolução'
    },
    {
        value: 'EXECUTION',
        label:  'Execução'
    },
    {
        value: 'FORECAST',
        label:  'Previsão'
    }
];

export const paymentMethods = [
    {
        value: 'CHECK',
        label:  'Cheque'
    },
    {
        value: 'ACCOUNT_DEBIT',
        label:  'Débito em conta'
    },
    {
        value: 'CASH',
        label:  'Dinheiro'
    },
    {
        value: 'DOC_TRANSFER',
        label:  'DOC'
    },
    {
        value: 'PIX',
        label:  'PIX'
    },
    {
        value: 'TED_TRANSFER',
        label:  'TED'
    },
];


export const documentTypes = [
    {
        value: 'NONE',
        label:  'Nenhum'
    },
    {
        value: 'WAYBILL_INSS',
        label:  'Guia INSS'
    },
    {
        value: 'WAYBILL_COFINS',
        label:  'Guia COFINS'
    },
    {
        value: 'WAYBILL_PIS',
        label:  'Guia PIS'
    },
    {
        value: 'WAYBILL_IRRF',
        label:  'Guia IRRF'
    },
    {
        value: 'WAYBILL_IRPJ',
        label:  'Guia IRPJ'
    },
    {
        value: 'WAYBILL_FGTS',
        label:  'Guia FGTS'
    },
    {
        value: 'BANK_STATEMENT',
        label:  'Extrato bancário'
    },
    {
        value: 'INVOICE',
        label:  'Fatura bancária'
    },
    {
        value: 'TAX_COUPON',
        label:  'Cupom fiscal'
    },
    {
        value: 'PAYMENT_RECEIPT',
        label:  'Recibo de pagamento'
    },
    {
        value: 'REPAY',
        label:  'Reembolso'
    },
    {
        value: 'BILL_OF_SALE',
        label:  'Nota fiscal'
    },
    {
        value: 'DAILY_RECEIPT',
        label:  'Diária'
    },
]

export const states = [
    {
        "id": 1,
        "uf": "RS",
        "name": "Rio Grande do Sul"
    },
    {
        "id": 2,
        "uf": "SC",
        "name": "Santa Catarina"
    },
    {
        "id": 3,
        "uf": "SP",
        "name": "São Paulo"
    }
]

export const cities = [
    { 
        "id": 1,
        "name": "Porto Alegre",
        "idState": 1
    },
    { 
        "id": 2,
        "name": "Torres",
        "idState": 1
    },
    { 
        "id": 3,
        "name": "Criciúma",
        "idState": 2
    },
    { 
        "id": 4,
        "name": "Florianópolis",
        "idState": 2
    },
    { 
        "id": 5,
        "name": "São Paulo",
        "idState": 3
    },
    { 
        "id": 6,
        "name": "Santos",
        "idState": 3
    },
];

export const genders = [
    { key: 'FEMALE', value: 'FEMININO' },
    { key: 'MALE', value: 'MASCULINO' },
    { key: 'OTHER', value: 'OUTRO' },
];

export const ethnicities = [
    { key: 'ASIAN', value: 'ASIÁTICO' },
    { key: 'WHITE', value: 'BRANCO' },
    { key: 'INDIAN', value: 'INDÍGENA' },
    { key: 'BROWN', value: 'PARDO' },
    { key: 'BLACK', value: 'PRETO' },
    { key: 'OTHER', value: 'OUTRO' },
];

export const documentTypesPerson = [
    { key: 'CPF', value: 'CPF' },
    { key: 'RG', value: 'RG' },
];

export const modelActivities = [
    { key: 'FULL', value: 'Completa' },
    { key: 'SIMPLE', value: 'Simples' },
];

export const typeActivities = [
    { key: 'STATE', value: 'Reunião Estadual' },
    { key: 'MANAGEMENT', value: 'Reunião de Coordenação' },
    { key: 'INTERNAL', value: 'Reunião Interna' },
    { key: 'EXTERNAL', value: 'Reunião Externa' },
    { key: 'FORMATION', value: 'Atividade de formação' },
    { key: 'OTHER', value: 'Outros' },
];
