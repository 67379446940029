import { useEffect, useState } from "react";
import { Form, Col } from "react-bootstrap";
// import { states, cities } from "../../../shared/constants";
import Select from "react-select";
import { useCities, useGetCities } from "../../../store/hooks/cityHooks";
import { useGetStates, useStates } from "../../../store/hooks/stateHooks";
import { useSelector } from "react-redux";

import { stateProps } from "../../../shared/@types/general";

// interface State {
//     id: string;
//     name: string;
//     uf: string;
// }

interface Props {
  getIdCity: (id: string) => void;
  idCity?: string;
  idState?: string;
}

const City = ({ getIdCity, idCity, idState }: Props) => {
   const { access_token } = useSelector((state: stateProps) => state.auth);
    const [uf, setUf] = useState<any>("");
    const [city, setCity] = useState<any>("");
    const getCities = useGetCities();
    const citiesData = useCities();
    const getStates = useGetStates();
    const statesData = useStates();

    useEffect(() => {
        const getCity = () => {
            getCities(access_token, 1, uf)
        }

        getCity();
    },[uf, getCities, access_token])

    useEffect(() => {
      if(city && city !== ""){
        getIdCity(city);
      }
      // eslint-disable-next-line
    },[city])

    useEffect(() => {
      getStates(access_token);

      if(idState && idState !== ""){
        setUf(idState);
      }

      if(idCity && idCity !== ""){
        setCity(idCity);
      }
    },[getStates, idCity, idState, access_token])


    return (
        <>
            {statesData.states && Array.isArray(statesData.states) && (
              <Col md={6}>
                <Form.Label htmlFor="uf">Estado</Form.Label>
                <div data-testid="select-state">
                <Select
                  aria-label="select-state" 
                  placeholder="Selecione uma opção"
                  noOptionsMessage={() => "Não encontrado"}
                  name="uf"
                  onChange={(option: any) => setUf(option.value)}
                  value={
                    statesData.states &&
                    statesData.states
                      .map((state: any) => ({
                        label: state.NAME,
                        value: state.idState,
                      }))
                      .find((option: any) => option.value === uf)
                  }
                  options={
                    statesData.states &&
                    statesData.states.map((state: any) => ({
                      label: state.NAME,
                      value: state.idState,
                    }))
                  }
                /></div>
              </Col>
            )}
            {citiesData.cities && Array.isArray(citiesData.cities) && (
              <Col md={6}>
                <Form.Label htmlFor="city">Cidade</Form.Label>
                <Select
                  aria-label="select-city" 
                  placeholder="Selecione uma opção"
                  noOptionsMessage={() => "Não encontrado"}
                  name="city"
                  onChange={(option: any) => setCity(option.value)}
                  value={
                    citiesData.cities &&
                    citiesData.cities
                      .map((city: any) => ({
                        label: city.nameCity,
                        value: city.idCity,
                      }))
                      .find((option: any) => option.value === city)
                  }
                  options={
                    citiesData.cities &&
                    citiesData.cities.map((city: any) => ({
                      label: city.nameCity,
                      value: city.idCity,
                    }))
                  }
                />
              </Col>
            )}
            {/* <Button disabled={city === "" ? true : false}>SALVAR CIDADE</Button> */}
        </>
    )
}

export default City