import { getSubRubricData, SubRubric } from '../../shared/@types/subrubric';

export const GET_SUBRUBRIC = 'GET_SUBRUBRIC';
export const GET_ALL_SUBRUBRICS = 'GET_ALL_SUBRUBRICS';
export const UPDATE_SUBRUBRIC = 'UPDATE_SUBRUBRIC';
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM';
export const GET_SUBRUBRICS_SEARCH = 'GET_SUBRUBRICS_SEARCH';

export const getSubRubric = (subrubric: SubRubric) => ({
  type: GET_SUBRUBRIC,
  payload: {
    subrubric,
  },
});

export interface GetSubRubric {
  type: typeof GET_SUBRUBRIC;
  payload: { subrubric: SubRubric };
}

export const getAllSubRubrics = (subrubrics: getSubRubricData) => ({
  type: GET_ALL_SUBRUBRICS,
  payload: {
    data: subrubrics.data,
    pagination: subrubrics.pagination,
  },
});

export interface GetAllSubRubrics {
  type: typeof GET_ALL_SUBRUBRICS;
  payload: {
    data: getSubRubricData;
    pagination: Partial<getSubRubricData>
  };
}

export const getSubRubricsSearch = (subrubrics: getSubRubricData) => ({
  type: GET_SUBRUBRICS_SEARCH,
  payload: {
    data: subrubrics.data,
    pagination: subrubrics.pagination,
  },
});

export interface GetSubRubricsSearch {
  type: typeof GET_SUBRUBRICS_SEARCH;
  payload: {
    data: getSubRubricData;
    pagination: Partial<getSubRubricData>
  };
}

export const updateSubRubric = (subrubric: SubRubric) => ({
  type: UPDATE_SUBRUBRIC,
  payload: {
    subrubric,
  },
});

export interface UpdateSubRubric {
  type: typeof UPDATE_SUBRUBRIC;
  payload: {
    subrubric: SubRubric;
  };
}

export const setQueryParam = (param: string) => ({
  type: SET_QUERY_PARAM,
  payload: {
    param,
  },
});

export interface SetQueryParam {
  type: typeof SET_QUERY_PARAM;
  payload: {
    param: string;
  };
}
