import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
// import { RootState } from '../reducers';
import * as sidebarAction from "../sidebar/sidebarAction";
// import { dispatch } from 'react-hot-toast/dist/core/store';

// const useSidebarState = () =>
//     useSelector((rootState: RootState) => rootState.sidebarState);

export const useGetCurrentItem = () => {
    const dispatch = useDispatch();

    return useCallback(
        (current: string) => {

            localStorage.setItem("currentItem", current);

            // dispatch({
            //     type: sidebarAction.GET_CURRENT_ITEM,
            //     payload: {
            //     current
            //     },
            // });


            dispatch(sidebarAction.getCurrentItem(current));
        },
        [dispatch],
    )
}