import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { stateProps } from "../../shared/@types/general";
import { Container, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Admin from "../../shared/components/Admin";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import {
  useHideLoading,
  useLoading,
  useShowLoading,
} from "../../store/hooks/loadingHooks";
import { useGetProjects, useProjects } from "../../store/hooks/projectHooks";

import styles from "../styles.module.scss";
import ProjectList from "../project/List/ProjectList";

const Home = () => {
  const { access_token } = useSelector((state: stateProps) => state.auth);
  const getCurrentItem = useGetCurrentItem();
  const getProjects = useGetProjects();
  const projectsData = useProjects();
  const isLoading = useLoading();
  const showLoading = useShowLoading();
  const hideLoading = useHideLoading();

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    getCurrentItem("home");

  }, [getCurrentItem]);

  useEffect(() => {
    showLoading();
    getCurrentItem("home");
    const fetchProjects = async () => {
      try {
        let result: any = getProjects(access_token, 1);
        toast.promise(result, {
          loading: "Carregando Projetos...",
          success: () => {
            return "Lista de projetos carregada!";
          },
          error: (error) => {
            if (
              error &&
              error.response &&
              error.response.status &&
              (error.response.status === 401 || error.response.status === 403)
            ) {
              setRedirect(true);
              return error.response.data.error;
            }
            return error.response.data.error;
          },
        });
      } catch (error: any) {
        if (
          error &&
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setRedirect(true);
          return error.response.data.error;
        }
      } finally {
        hideLoading();
      }
    };

    fetchProjects();
  }, [getProjects, access_token, showLoading, hideLoading, getCurrentItem]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
        }}
      />
    );
  }

  return (
    <Admin>
      {isLoading && (
        <Container>
          <Spinner animation="border" variant="primary" />
        </Container>
      )}
      <div className={styles.headerInfo}>
        <h2>Projetos</h2>
      </div>
      <hr className={styles.hr} />
      <ProjectList projects={projectsData.projects} />
      {/* <div className="pagination">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            projectsData &&
            projectsData.project_pagination &&
            projectsData.project_pagination.total_content
              ? projectsData.project_pagination.total_content
              : 0
          }
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div> */}
    </Admin>
  );
};

export default Home;
