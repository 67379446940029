import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

interface ProjectProps {
  idProject: string;
  name: string;
}

interface Props {
  projects: ProjectProps[] | undefined;
  handleCurrentItem?: (id: string) => void;
  showButton?: boolean;
  updateAllowed: boolean;
  deleteAllowed: boolean;
}

const ProjectList = ({
  projects = [],
  handleCurrentItem,
  showButton,
  updateAllowed,
  deleteAllowed,
}: Props) => {
  if (projects.length === 0) return <h4>Não há projetos cadastrados.</h4>;

  return (
    <div className={styles.container}>
      <ul className={styles.responsiveTable}>
        <li className={styles.tableHeader}>
          <div className={`${styles.col} ${styles.col1}`}>Id</div>
          <div className={`${styles.col} ${styles.col2}`}>Nome</div>
          {showButton && (
            <div className={`${styles.col} ${styles.col4}`}>AÇÕES</div>
          )}
        </li>

        {projects.map((item) => (
          <li className={styles.tableRow} key={item.idProject}>
            <div className={`${styles.col} ${styles.col1}`} data-label="Id">
              {item.idProject}
            </div>
            <div className={`${styles.col} ${styles.col2}`} data-label="Nome">
              <a href={`/projeto/${item.idProject}`}>{item.name}</a>
            </div>
            {showButton && (
              <div
                className={`${styles.col} ${styles.col4}`}
                data-label="Ações"
              >
                <>
                  {updateAllowed && (
                    <a
                      href={`/projeto/${item.idProject}`}
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faPenToSquare} size="1x" />
                    </a>
                  )}
                  {deleteAllowed && (
                    <button
                      className="btn btn-danger"
                      onClick={() => handleCurrentItem(item.idProject)}
                    >
                      <FontAwesomeIcon icon={faTrash} size="1x" />
                    </button>
                  )}
                </>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProjectList;
