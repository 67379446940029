import { Row, Form, Col, Button } from "react-bootstrap";

import styles from "./styles.module.scss";

interface SearchProps {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: any;
  placeholder: string;
}

const Search = ({ handleChange, values, placeholder }: SearchProps) => {
  return (
    <>
      <Row className={`d-flex  ${styles.search}`}>
        <Col style={{ paddingRight: 0 }}>
          <Form.Group as={Col}>
            <Form.Control
              id="search"
              name="search"
              type="text"
              placeholder={placeholder}
              onChange={handleChange}
              value={values.search}
            />
          </Form.Group>
        </Col>
        <Col style={{ paddingRight: 0 }}>
          <Form.Group as={Col}>
            <Button
              type="submit"
              style={{ backgroundColor: "gray", borderColor: "gray" }}
            >
              Buscar
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default Search;
