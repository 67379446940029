import * as React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { stateProps } from "../@types/general";

import styles from "./styles.module.scss";
import { usePermission } from "../../store/hooks/permissionHooks";

interface LayoutProps {
  children?: React.ReactNode;
}

const Admin = ({ children }: LayoutProps) => {
  // public render() {
  const { user_data } = useSelector((state: stateProps) => state.auth);
  const { current } = useSelector((state: stateProps) => state.sidebarState);
  const user = JSON.parse(localStorage.getItem("user"));
  // const current = "home";

  const [accessMenu, setAccessMenu] = React.useState({
    activity: false,
    dam: false,
    cashier: false,
    contract: false,
    provider: false,
    release: false,
    participant: false,
    project: false,
    ledger: false,
    report: false,
    user: false,
  })

  React.useEffect(() => {
    if (!user_data.scopes) return;
    const scopes = [
      "activity_index_get",
      "dam_index_get",
      "cashier_index_get",
      "contract_index_get",
      "provider_index_get",
      "release_index_get",
      "person_index_get",
      "project_index_get",
      "ledger_index_get_all",
      "users_index_get",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      const newObj = {
        activity: false,
    dam: false,
    cashier: false,
    contract: false,
    provider: false,
    release: false,
    participant: false,
    project: false,
    ledger: false,
    report: false,
    user: false,
      }

      if (access.includes("activity_index_get")) newObj.activity = true
      if (access.includes("dam_index_get")) newObj.dam = true
      if (access.includes("cashier_index_get")) newObj.cashier = true
      if (access.includes("contract_index_get")) newObj.contract = true
      if (access.includes("provider_index_get")) newObj.provider = true
      if (access.includes("release_index_get")) newObj.release = true
      if (access.includes("person_index_get")) newObj.participant = true
      if (access.includes("project_index_get")) newObj.project = true
      if (access.includes("ledger_index_get_all")) newObj.ledger = true
      if (access.includes("users_index_get")) newObj.user = true

      setAccessMenu(newObj)
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className={styles.main}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          className={styles.mobileNavbar}
        >
          <Container>
            {/* <Navbar.Brand href="#home"> */}
            <div className={styles.profile}>
              {/* <img src={Logo} alt="profile_picture" /> */}
              <h3>{user && user.name}</h3>
              <p className={styles.editProfile}>
                <a href="/perfil">Editar</a>
              </p>
            </div>
            {/* </Navbar.Brand> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  href="/"
                  className={current === "home" ? styles.active : ""}
                >
                  Home
                </Nav.Link>
                {accessMenu.activity === true && (
                  <Nav.Link
                    href="/atividades"
                    className={current === "activities" ? styles.active : ""}
                  >
                    Atividades
                  </Nav.Link>
                )}
                {accessMenu.dam && (
                  <Nav.Link
                    href="/barragens"
                    className={current === "dams" ? styles.active : ""}
                  >
                    Barragens
                  </Nav.Link>
                )}
                {accessMenu.cashier && (
                  <Nav.Link
                    href="/caixas"
                    className={current === "cashiers" ? styles.active : ""}
                  >
                    Caixas
                  </Nav.Link>
                )}
                {accessMenu.contract && (
                  <Nav.Link
                    href="/contratos"
                    className={current === "contracts" ? styles.active : ""}
                  >
                    Contratos
                  </Nav.Link>
                )}
                {accessMenu.provider && (
                  <Nav.Link
                    href="/fornecedores"
                    className={current === "providers" ? styles.active : ""}
                  >
                    Fornecedores
                  </Nav.Link>
                )}
                {accessMenu.release && (
                  <Nav.Link
                    href="/lancamentos"
                    className={current === "releases" ? styles.active : ""}
                  >
                    Lançamentos
                  </Nav.Link>
                )}
                {accessMenu.participant && (
                  <Nav.Link
                    href="/participantes"
                    className={current === "participants" ? styles.active : ""}
                  >
                    Participantes
                  </Nav.Link>
                )}
                {accessMenu.project && (
                  <Nav.Link
                    href="/projetos"
                    className={current === "projects" ? styles.active : ""}
                  >
                    Projetos
                  </Nav.Link>
                )}
                {accessMenu.ledger && (
                  <Nav.Link
                    href="/rubricas"
                    className={current === "rubric" ? styles.active : ""}
                  >
                    Rubricas
                  </Nav.Link>
                )}
                {accessMenu.report && (
                  <Nav.Link
                    href="/relatorios"
                    className={current === "report" ? styles.active : ""}
                  >
                    Relatórios
                  </Nav.Link>
                )}
                {accessMenu.user && (
                  <Nav.Link
                    href="/usuarios"
                    className={current === "users" ? styles.active : ""}
                  >
                    Usuários
                  </Nav.Link>
                )}

                <Nav.Link
                  href="/login"
                  style={{
                    backgroundColor: "gray",
                    color: "#fff",
                    paddingLeft: "10px",
                  }}
                >
                  Sair
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className={styles.section}>
          <div className={styles.top_navbar}>
            <div className={styles.hamburger}>
              <a href="#/">
                <i className="fas fa-bars"></i>
              </a>
            </div>
          </div>
          <div className={styles.container}>{children}</div>
        </div>
        <div className={styles.sidebar}>
          <div className={styles.profile}>
            {/* <img src={Logo} alt="profile_picture" /> */}
            <h3>{user && user.name}</h3>
            <p className={styles.editProfile}>
              <a href="/perfil">Editar</a>
            </p>
          </div>
          <ul>
            <li>
              <a
                href="/home"
                className={current === "home" ? styles.active : ""}
              >
                <span className="icon">
                  <i className="fas fa-home"></i>
                </span>
                <span className="item">Home</span>
              </a>
            </li>
            {accessMenu.activity && (
              <li>
                <a
                  href="/atividades"
                  className={current === "activities" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Atividades</span>
                </a>
              </li>
            )}
            {accessMenu.dam && (
              <li>
                <a
                  href="/barragens"
                  className={current === "dams" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Barragens</span>
                </a>
              </li>
            )}
            {accessMenu.cashier && (
              <li>
                <a
                  href="/caixas"
                  className={current === "cashiers" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Caixas</span>
                </a>
              </li>
            )}
            {accessMenu.contract && (
              <li>
                <a
                  href="/contratos"
                  className={current === "contracts" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Contratos</span>
                </a>
              </li>
            )}
            {accessMenu.provider && (
              <li>
                <a
                  href="/fornecedores"
                  className={current === "providers" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Fornecedores</span>
                </a>
              </li>
            )}

            {accessMenu.release && (
              <li>
                <a
                  href="/lancamentos"
                  className={current === "releases" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Lançamentos</span>
                </a>
              </li>
            )}
            {accessMenu.participant && (
              <li>
                <a
                  href="/participantes"
                  className={current === "participants" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Participantes</span>
                </a>
              </li>
            )}
            {accessMenu.project && (
              <li>
                <a
                  href="/projetos"
                  className={current === "projects" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Projetos</span>
                </a>
              </li>
            )}
            {accessMenu.ledger && (
              <li>
                <a
                  href="/rubricas"
                  className={current === "rubrics" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Rubricas</span>
                </a>
              </li>
            )}
              <li>
                <a
                  href="/relatorios"
                  className={current === "report" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Relatórios</span>
                </a>
              </li>
            
            {accessMenu.user && (
              <li>
                <a
                  href="/usuarios"
                  className={current === "users" ? styles.active : ""}
                >
                  <span className="icon">
                    <i className="fas fa-desktop"></i>
                  </span>
                  <span className="item">Usuários</span>
                </a>
              </li>
            )}

            <li>
              <a
                href="/login"
                style={{ backgroundColor: "gray", color: "#fff" }}
              >
                <span className="icon">
                  <i className="fas fa-desktop"></i>
                </span>
                <span className="item">Sair</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
  // }
};

export default Admin;
